import React, {Fragment} from 'react';

import {Link} from "react-router-dom";
import {
    Button,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from '@material-ui/core'
import {useTranslation} from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";

function TableComponentCourierRecord({
                                         columns,
                                         filteredData,
                                         urlEdit,
                                         handleChangePage,
                                         handleChangeRowsPerPage,
                                         page,
                                         rowsPerPage,
                                         count,
                                         canDelete,
                                         handlerDeleteModal
                                     }) {

    const fieldByString = function (o, s) {
        s = s.replace(/\[(\w+)]/g, '.$1')
        s = s.replace(/^\./, '')
        const a = s.split('.')
        for (let i = 0, n = a.length; i < n; ++i) {
            let k = a[i]
            if (k in o) {
                o = o[k]
            } else {
                return
            }
        }
        return o
    }

    const useStyles = makeStyles({
        container: {
            maxHeight: 800,
        },
    });

    const [t] = useTranslation('common');


    const classes = useStyles();

    return <Fragment>
        <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{minWidth: column.minWidth, maxWidth: column.maxWidth}}
                            >
                                {t(column.id.split(".")[0])}
                            </TableCell>
                        ))}
                        <TableCell>
                            {t('button.edit')}
                        </TableCell>
                        {canDelete && <TableCell>
                            {t('button.delete')}
                        </TableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                {columns.map((column) => {
                                    try {
                                        if (column.id === 'status') {
                                            const value = fieldByString(row, 'arrivalDate');
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {value ? 'Entregado ' : 'En viaje '}
                                                </TableCell>
                                            )
                                        } else {
                                            const value = fieldByString(row, column.id);
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.type === 'boolean' ? t(value) : column.format ? column.format(value) : value}
                                                </TableCell>
                                            )
                                        }
                                    } catch (e) {
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                            </TableCell>
                                        )
                                    }

                                })}
                                {
                                    fieldByString(row, 'type') === 'DOCUMENT_COURIER' ?
                                        <TableCell>
                                            <Link to={'/documentsCourierRecord/edit/' + row.id}>
                                                <Button variant="outlined" color="primary"><EditIcon/></Button>
                                            </Link>
                                        </TableCell> : urlEdit !== undefined ?
                                            <TableCell>
                                                <Link to={'/specialCourierRecord/edit/' + row.id}>
                                                    <Button variant="outlined" color="primary"><EditIcon/></Button>
                                                </Link>
                                            </TableCell> : <></>

                                }
                                {canDelete && <TableCell>
                                    <Button variant="outlined" color="primary"
                                            onClick={() => handlerDeleteModal(row.id)}><DeleteOutlineRoundedIcon/></Button>
                                </TableCell>
                                }
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[50, 100, 200, 500]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    </Fragment>

}

export default TableComponentCourierRecord;
