import {Box, Button, Modal} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

export const DeleteModal = ({deleteModal, setDeleteModal, onHandlerDeleteElement}) => {

    return <Modal open={deleteModal.open}
                  onClose={() => setDeleteModal({open: false, row: 0})}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"><Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4
    }}>
        <Typography id="modal-modal-description" sx={{mt: 2}}>
            Estas seguro que desea elimininarlo?
        </Typography>
        <Box sx={{display: 'flex', flexDirection: 'row', flex: 1, marginTop: '16px'}}>
            <Button variant="contained" onClick={async () => {
                await onHandlerDeleteElement()
            }} style={{flex: 1, display: 'flex'}} color="secondary">
                Borrar
            </Button>
            <Button variant="text" onClick={() => {
                setDeleteModal({open: false, row: 0})
            }} style={{flex: 1, display: 'flex'}}>
                Cancelar
            </Button>
        </Box>

    </Box>
    </Modal>


}
