import InputField from '../../../components/inputField/InputField.js';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import {Button, FormGroup} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import React, {Fragment, useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import {useHistory} from "react-router-dom";
import {fetchSecure} from "../../../modules/fetchSecure";
import CheckboxField from "../../../components/checkboxField/CheckboxField";
import SelectField from "../../../components/selectField/SelectField";


function PositionArancelItem(props) {

    const [positionArancel, setPositionArancel] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [savedStatus, setSavedStatus] = useState({saved: false, error: false});
    const [errors, setErrors] = useState({})
    const [show, setShow] = useState(props.match.params.objectId === null)
    const handleShow = e => {
        setShow(e.target.checked)
    }
    const [t] = useTranslation('common');
    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {},
    });
    const classes = useStyles();

    const handleListChange = (listName, listValue) => {
        let newPositionArancel = {...positionArancel}
        newPositionArancel[listName] = listValue;
        setPositionArancel(newPositionArancel);
    }

    const handleChange = e => {
        let newPositionArancel = {...positionArancel}
        newPositionArancel[e.target.name] = e.target.value;
        setPositionArancel(newPositionArancel);
    }


    const handleSave = () => {
        let method = 'POST';
        let url = window.REACT_APP_BASE_API_URL + '/positionArancel';
        if (props.match.params.objectId != null) {
            method = 'PUT';
            url = url + "/" + props.match.params.objectId
        } else {
            positionArancel.version = 0;
        }
        let positionArancelInJson = JSON.stringify(positionArancel);
        const requestOptions = {
            method: method,
            headers: {'Content-Type': 'application/json'},
            body: positionArancelInJson
        };


        fetchSecure(url, requestOptions)
            .then(async response => {
                    const data = await response.json();
                    if (!response.ok) {

                        setErrors(data.fields == null ? {} : data.fields)
                        const errorMessage = (data && data.message) || response.status;
                        return Promise.reject(errorMessage);
                    } else {
                        setErrors({})
                        props.match.params.objectId = data.id
                        setSavedStatus({saved: true, error: false})
                    }
                }
            ).catch(errorMessage => {
            setSavedStatus({saved: false, error: true, errorMessage: errorMessage})
        });

    }


    useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (props.match.params.objectId != null) {
                fetchSecure(window.REACT_APP_BASE_API_URL + '/positionArancel/' + props.match.params.objectId)
                    .then(response => response.json())
                    .then(data => setPositionArancel(data))
                    .then(() => setLoaded(true));
            }
        }
    }, [errors, loaded, props.match.params.objectId, savedStatus]);

    let history = useHistory()
    const goToPreviousPath = () => {
        history.goBack()
    }

    return (
        <Paper className={classes.root}>
            <Box m={2}>
                <Button variant="contained" color="primary" onClick={goToPreviousPath}>Listado</Button>
            </Box>
            {savedStatus.error && <Alert severity="error">
                Ha ocurrido un error inesperado al intentar grabar la posicion de arancel
                {Object.keys(errors).map((it) =>
                    <Fragment>
                        <li>
                            {errors[it]}
                        </li>
                    </Fragment>
                )}
            </Alert>
            }

            {savedStatus.saved && <Alert severity="success">La posicion de arancel se ha grabado con éxito</Alert>
            }

            {props.match.params.objectId != null &&
            <Box m={2}>
                <Grid container spacing={3}>
                    <Grid item xs={9}>
                    </Grid>
                    <Grid item xs={2}>
                        {t('button.show')} <Switch defaultChecked={show} color="primary" onClick={handleShow}/>
                    </Grid>
                </Grid>
            </Box>
            }

            <Box m={2}>
                <form className={classes.container} noValidate>

                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h6">Atributos</Typography>
                            <FormGroup row={true}>
                                <InputField disabled={show} error={!!errors.positionArancelId} name="positionArancelId"
                                            label="Id posicion arancel" value={positionArancel.positionArancelId}
                                            type="text" onChange={handleChange} className={classes.textField}/>
                            </FormGroup>
                        </CardContent>
                    </Card>

                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h6">Licencias</Typography>
                            <FormGroup row={true}>
                                <CheckboxField name="automaticLicense" label="Licencia automatica"
                                               value={positionArancel.automaticLicense} onChange={handleListChange}
                                               className={classes.textField}/>
                                <CheckboxField name="manualLicense" label="Licencia manual"
                                               value={positionArancel.manualLicense} onChange={handleListChange}
                                               className={classes.textField}/>
                            </FormGroup>
                        </CardContent>
                    </Card>

                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h6">Valores</Typography>
                            <FormGroup row={true}>
                                <InputField disabled={show} error={!!errors.rights} name="rights" label="Derechos"
                                            value={positionArancel.rights} type="number" onChange={handleChange}
                                            className={classes.textField}/>
                                <InputField disabled={show} error={!!errors.criterionValue} name="criterionValue"
                                            label="Valor criterio" value={positionArancel.criterionValue} type="number"
                                            onChange={handleChange} className={classes.textField}/>
                                <SelectField disabled={show} error={!!errors.typeOfPresentation}
                                             name="typeOfPresentation" label="typeOfPresentation"
                                             value={positionArancel.typeOfPresentation} changeHandler={handleListChange}
                                             className={classes.textField}
                                             url={window.REACT_APP_BASE_API_URL + '/typeOfPresentation'}
                                             optionValue="id" optionShow="description"/>
                                <InputField disabled={show} error={!!errors.statisticalFee} name="statisticalFee"
                                            value={positionArancel.statisticalFee} type="number"
                                            onChange={handleChange} className={classes.textField}/>
                            </FormGroup>
                        </CardContent>
                    </Card>


                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h6">Impuestos</Typography>
                            <FormGroup row={true}>
                                <InputField disabled={show} error={!!errors.ivaPercentage} name="ivaPercentage"
                                            value={positionArancel.ivaPercentage} type="number" onChange={handleChange}
                                            className={classes.textField}/>
                                <InputField disabled={show} error={!!errors.ivaPerceptionPercentage}
                                            name="ivaPerceptionPercentage"
                                            value={positionArancel.ivaPerceptionPercentage} type="number"
                                            onChange={handleChange}
                                            className={classes.textField}/>
                                <InputField disabled={show} error={!!errors.earningsAdvancePercentage}
                                            name="earningsAdvancePercentage"
                                            value={positionArancel.earningsAdvancePercentage} type="number"
                                            onChange={handleChange}
                                            className={classes.textField}/>
                                <InputField disabled={show} error={!!errors.iibbPerceptionPercentage}
                                            name="iibbPerceptionPercentage"
                                            value={positionArancel.iibbPerceptionPercentage} type="number"
                                            onChange={handleChange}
                                            className={classes.textField}/>
                            </FormGroup>
                        </CardContent>
                    </Card>
                    {(props.match.params.readonly == null || props.match.params.readonly === 'false') && <CardActions>
                        <FormGroup row={true}>
                            <Box m={2}>
                                <Button variant="contained" color="primary" onClick={handleSave}>Guardar</Button>
                            </Box>
                        </FormGroup>
                    </CardActions>
                    }
                </form>
            </Box>

        </Paper>
    )
}

export default PositionArancelItem;
