import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import React from 'react';

import {useTranslation} from "react-i18next";


function DateField({name, label, value, onChange, error, disabled, onChangeField, style, forceLabel = null}) {

    let currentValue = null;
    if (value != null) {
        currentValue = value;
    }

    const [t] = useTranslation('common');

    const changeHandler = newValue => {
        const newDate = newValue;
        if (newValue) {
            newDate.setUTCHours(13,0,0,0);
        }
        if (onChangeField) {
            onChangeField(name, newDate);
        }
        onChange(name, newDate);
    };


    return <MuiPickersUtilsProvider utils={DateFnsUtils}>

        <KeyboardDatePicker inputVariant="outlined" style={style} disabled={disabled} error={error} format="dd/MM/yyyy"
                            InputLabelProps={{shrink: true}}
                            label={forceLabel ?? t(name) ?? label} name={name} id={name} value={currentValue}
                            onChange={changeHandler} clearable={true}/>
    </MuiPickersUtilsProvider>

}

export default DateField
