import React, {useCallback, useEffect, useState} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import {Box, Button, Paper, TextField} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import TableComponent from "../../../components/table/TableComponent.js";
import Grid from '@material-ui/core/Grid';
import {Link} from "react-router-dom";
import {fetchSecure} from "../../../modules/fetchSecure";
import SelectField from "../../../components/selectField/SelectField";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from '@material-ui/icons/Close';
import LoadingScreen from '../../../components/loading/LoadingScreen.js';

function Product() {


    const [customerCodeFilter, setCustomerCodeFilter] = useState('')
    const [client, setClient] = useState(undefined);
    const [clientBySupplier, setClientBySupplier] = useState(undefined);

    const [product, setProduct] = useState(undefined);
    const [supplier, setSupplier] = useState(undefined);
    const [supplier2, setSupplier2] = useState(0);
    const [selectedFile, setSelectedFile] = useState(undefined);
    const [selectedSupplierFile, setSelectedSupplierFile] = useState(undefined);
    const [selectedNewProductFile, setSelectedNewProductFile] = useState(undefined);

    const [savedFileStatus, setSavedFileStatus] = useState({
        saved: false,
        processing: false,
        error: false,
        message: ''
    });

    const uploadFile = (e) => {
        setSelectedFile(e.target.files[0])
    }

    const uploadSupplierFile = (e) => {
        setSelectedSupplierFile(e.target.files[0])
    }

    const uploadNewProductFile = (e) => {
        setSelectedNewProductFile(e.target.files[0])
    }

    const setMessageUploadFile = (status) => {
        setSavedFileStatus(status)
    }
    const sendFile = async () => {
        try {
            setSavedFileStatus({saved: false, processing: true, error: false})
            const data = new FormData()
            data.append('file', selectedFile)
            const requestOptions = {
                method: 'PUT',
                body: data
            };
            const response = await fetchSecure(window.REACT_APP_BASE_API_URL + '/product/upload', requestOptions)
            if (!response.ok) {
                const error = JSON.parse(await response.text());
                setMessageUploadFile({
                    saved: false,
                    processing: false,
                    error: true,
                    message: `Los siguientes productos han dado error: ${error?.message?.substring(0, error.message.length - 1)}`
                })
            } else {
                setMessageUploadFile({saved: true, processing: false, error: false})
            }
        } catch (e) {
            setMessageUploadFile({saved: false, processing: false, error: true})
        }
    }

    const sendSupplierFile = async () => {
        try {
            setSavedFileStatus({saved: false, processing: true, error: false})
            const data = new FormData()
            data.append('file', selectedSupplierFile)
            const requestOptions = {
                method: 'PUT',
                body: data
            }
            const response = await fetchSecure(window.REACT_APP_BASE_API_URL + '/productSuuplier/upload', requestOptions)
            if (!response.ok) {
                const error = JSON.parse(await response.text());
                setMessageUploadFile({
                    saved: false,
                    processing: false,
                    error: true,
                    message: `Los siguientes productos han dado error:\n ${error?.message?.substring(0, error.message.length - 1)}`
                })
            } else {
                setMessageUploadFile({saved: true, processing: false, error: false})
            }
        } catch (e) {
            setMessageUploadFile({saved: false, processing: false, error: true})
        }
    }

    const sendNewProductFile = async () => {
        try {
            setSavedFileStatus({saved: false, processing: true, error: false})
            const data = new FormData()
            data.append('file', selectedNewProductFile)
            const requestOptions = {
                method: 'PUT',
                body: data
            };

            const response = await fetchSecure(window.REACT_APP_BASE_API_URL + '/product/newUpload', requestOptions)
            if(!response.ok) {
                const error = JSON.parse(await response.text());
                setMessageUploadFile({
                    saved: false,
                    processing: false,
                    error: true,
                    message: `Los siguientes productos han dado error:\n ${error?.message?.substring(0, error.message.length - 1)}`
                })
            } else {
                setMessageUploadFile({saved: true, processing: false, error: false})
            }
        } catch (e) {
            setMessageUploadFile({saved: false, processing: false, error: true})
        }
    }

    const handleListChange = (listName, listValue) => {
        if (listName === 'clientBySupplier') {
            setClientBySupplier(listValue.id)
            setSupplier2(0)
        } else {
            if (listName === 'product') {
                setProduct(listValue.id)
            } else {
                if (listName === 'customer') {
                    setClient(listValue.id)
                } else if (listName === 'supplier2') {
                    setSupplier2(listValue.id)
                } else  {
                    setSupplier(listValue.id)
                }
            }
        }
    }

    const [productList, setProductList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingQuantityOfProducts, setLoadingQuantityOfProducts] = useState(false);
    const [quantityOfProducts, setQuantityOfProducts] = useState(0);
    const [t] = useTranslation('common');


    const createTo = {
        pathname: "/product/create",
    };

    const columns = [
        {id: 'id', label: 'ID', minWidth: 170},
        {id: 'positionArancel.positionArancelId', label: 'Posicion arancelaria', minWidth: 100},
        {id: 'descriptionSP', label: 'Descripción SP', minWidth: 100},
        {id: 'descriptionEN', label: 'Descripción EN', minWidth: 100},
        {id: 'family.description', label: 'Familia', minWidth: 100}
    ];
    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {
            maxHeight: 440,
        },
    });

    const download = async () => {
        if (supplier && client) {
            const response = await fetchSecure(window.REACT_APP_BASE_API_URL + '/product/download/customer/' + client + '/supplier/' + supplier)
            const blob = await response.blob()
            const file = window.URL.createObjectURL(blob)
            window.location.assign(file)
        } else {
            const response = await fetchSecure(window.REACT_APP_BASE_API_URL + '/product/download/customer/' + client)
            const blob = await response.blob()
            const file = window.URL.createObjectURL(blob)
            window.location.assign(file)
        }
    }

    const downloadSupplier = async () => {
        if (clientBySupplier) {
            const supplierData = supplier2 ? supplier2 : 0
            const response = await fetchSecure(window.REACT_APP_BASE_API_URL + '/product/supplier/download/customer/' + clientBySupplier + '/supplier/' + supplierData)
            const blob = await response.blob()
            const file = window.URL.createObjectURL(blob)
            window.location.assign(file)
        }
    }

    const downloadModelProduct = async () => {
        const response = await fetchSecure(window.REACT_APP_BASE_API_URL + '/product/download/modelNewProduct')
        const blob = await response.blob()
        const file = window.URL.createObjectURL(blob)
        window.location.assign(file)
    }

    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(200);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const fetchAndSetList = useCallback(async (currentCustomerCode, currentPage) => {
        setLoading(true)
        try {
            const url = window.REACT_APP_BASE_API_URL + `/product?page=${currentPage ?? 0}&size=${rowsPerPage}&customerCode=${encodeURIComponent(currentCustomerCode)}`
            const response = await fetchSecure(url)
            const data = await response.json()
            setProductList(data)
        } finally {
            setLoading(false)
        }
    }, [rowsPerPage])

    const fetchAndSetTotalQuantity = async (currentCustomerCode) => {
        setLoadingQuantityOfProducts(true)
        try {
            const response = await fetchSecure(window.REACT_APP_BASE_API_URL + `/quantityOfProducts?customerCode=${encodeURIComponent(currentCustomerCode)}`)
            const data = await response.json()
            setQuantityOfProducts(data)
        } finally {
            setLoadingQuantityOfProducts(false)
        }
    }

    useEffect(() => {
        fetchAndSetList('', page).then(() => {
        })
    }, [page, fetchAndSetList])

    useEffect(() => {
        fetchAndSetTotalQuantity('').then(() => {
        })
    }, [])

    if (loading || savedFileStatus.processing || loadingQuantityOfProducts) {
        return <Box display="flex" justifyContent="center" alignItems="center" style={{height: '100vh'}}><LoadingScreen
            backgroundColor={'white'}/></Box>
    } else {
        return <div>
            <Paper className={classes.root}>
                {savedFileStatus.saved && <Alert severity="success">El producto se ha actualizado con exito</Alert>}
                {savedFileStatus.error &&
                <Box style={{backgroundColor: '#eb9f99', display: 'flex', flex: 1, flexDirection: 'column'}}>
                    <Box style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        marginRight: '24px',
                        paddingTop: '24px'
                    }}>
                        <CloseIcon onClick={() => setSavedFileStatus({saved: false, processing: false, error: false})}
                                   style={{cursor: 'pointer'}}/>
                    </Box>
                    <ul>
                        {savedFileStatus.message.split("\n").map((i, key) => {
                            return <li><p key={key}>{i}</p></li>;
                        })}
                    </ul>
                </Box>
                }
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <Box m={2}>
                            <TextField
                                onChange={e => setCustomerCodeFilter(e.target.value)}
                                value={customerCodeFilter}
                                placeholder={t('customerCode')}
                                enterButton
                                style={{position: "sticky", top: "0", left: "0"}}
                            />
                            <Button style={{position: "sticky", top: "0", marginLeft: '8px'}}
                                    onClick={async () => {
                                        await fetchAndSetList(customerCodeFilter, page)
                                        await fetchAndSetTotalQuantity(customerCodeFilter)

                                    }}>Filter</Button>
                            <Link to={createTo}>
                                <Button style={{marginTop: '50px'}} variant="contained" color="primary">{t('button.create')}</Button>
                            </Link>
                        </Box>
                    </Grid>

                    <Grid item xs={3} alignItems="right">
                        <Box m={3}>
                            <SelectField disabled={false} name="customer" label="customer" value={{id: client}}
                                         changeHandler={handleListChange} className={classes.textField}
                                         url={window.REACT_APP_BASE_API_URL + '/customer/withProducts'} optionValue="id"
                                         optionShow="name" />
                            <SelectField disabled={false} name="supplier" label="supplier" value={{id: supplier}}
                                         changeHandler={handleListChange} className={classes.textField}
                                         url={client ? window.REACT_APP_BASE_API_URL + '/supplier/byCustomer/' + client : null}
                                         optionValue="id" optionShow="name"/>

                            <Button onClick={download} disabled={!product && !client} style={{marginLeft: 8, marginBottom: '24px'}}
                                    color="primary">Descargar Clientes</Button>
                            <input type="file" name="file" onChange={(e) => uploadFile(e)}/>
                            <Button onClick={sendFile} disabled={!selectedFile || savedFileStatus.processing}
                                    style={{marginLeft: 8}} color="primary">Cargar clientes</Button>
                        </Box>

                    </Grid>
                    <Grid item xs={3} alignItems="right">

                        <Box m={2}>
                            <SelectField disabled={false} name="clientBySupplier" label="customer"
                                         value={{id: clientBySupplier}} changeHandler={handleListChange}
                                         className={classes.textField}
                                         url={window.REACT_APP_BASE_API_URL + '/customer/withProducts'} optionValue="id"
                                         optionShow="name"/>
                            <SelectField disabled={false} name="supplier2" label="supplier" value={{id: supplier2}}
                                         changeHandler={handleListChange} className={classes.textField}
                                         url={clientBySupplier ? window.REACT_APP_BASE_API_URL + '/supplier/byCustomer/' + clientBySupplier : null}
                                         optionValue="id" optionShow="name"/>


                            <Button onClick={downloadSupplier} disabled={!clientBySupplier} style={{marginLeft: 8, marginBottom: '24px'}}
                                    color="primary">Descargar proveedores</Button>
                            <input type="file" name="file" onChange={(e) => uploadSupplierFile(e)}/>

                            <Button onClick={sendSupplierFile}
                                    disabled={!selectedSupplierFile || savedFileStatus.processing}
                                    style={{marginLeft: 8}} color="primary">Cargar Proveedores</Button>
                        </Box>

                    </Grid>
                    <Grid item xs={3} alignItems="right">

                        <Box m={2}>
                            <Button onClick={downloadModelProduct} style={{marginLeft: 8}} color="primary">Descargar modelo de producto</Button>
                            <input type="file" name="file" onChange={(e) => uploadNewProductFile(e)}/>
                            <Button onClick={sendNewProductFile}
                                    disabled={!selectedNewProductFile || savedFileStatus.processing}
                                    style={{marginLeft: 8}} color="primary">Cargar nuevo producto</Button>
                        </Box>

                    </Grid>

                </Grid>


                <TableComponent
                    columns={columns}
                    filteredData={productList}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    urlEdit={'product/edit/'}
                    count={quantityOfProducts}
                    usingPagination={true}
                />
            </Paper>


        </div>
    }

}

export default Product;
