import InputField from '../../../components/inputField/InputField.js';
import SelectField from '../../../components/selectField/SelectField.js';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import {Button, FormGroup} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import React, {Fragment, useEffect, useState} from 'react';
import ListComponent from '../../../components/listComponent/ListComponent.js';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import {useTranslation} from "react-i18next";
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import {useHistory} from "react-router-dom";
import {fetchSecure} from "../../../modules/fetchSecure";
import {StakeholderReportDownload} from "../components/StakeholderReportDownload";
import DateField from "../../../components/dateField/DateField";


function CustomerItem(props) {
    const [customer, setCustomer] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [savedStatus, setSavedStatus] = useState({saved: false, error: false});
    const [errors, setErrors] = useState({})
    const [t] = useTranslation('common');
    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {},
    });
    const classes = useStyles();

    const handleChange = e => {
        let newCustomer = {...customer}
        newCustomer[e.target.name] = e.target.value;
        setCustomer(newCustomer);
    }


    const handleListChange = (listName, listValue) => {
        let newCustomer = {...customer}
        newCustomer[listName] = listValue;
        setCustomer(newCustomer);
    }

    const [show, setShow] = useState(props.match.params.objectId === null)
    const handleShow = e => {
        setShow(e.target.checked)
    }

    const handleSave = () => {
        let method = 'POST';
        let url = window.REACT_APP_BASE_API_URL + '/customer';
        if (props.match.params.objectId != null) {
            method = 'PUT';
            url = url + "/" + props.match.params.objectId
        } else {
            customer.version = 0;
        }
        let customerInJson = JSON.stringify(customer);
        const requestOptions = {
            method: method,
            headers: {'Content-Type': 'application/json'},
            body: customerInJson
        };

        fetchSecure(url, requestOptions)
            .then(async response => {
                    const data = await response.json();
                    if (!response.ok) {

                        setErrors(data.fields == null ? {} : data.fields)
                        const errorMessage = (data && data.message) || response.status;
                        return Promise.reject(errorMessage);
                    } else {
                        setErrors({})
                        props.match.params.objectId = data.id
                        setSavedStatus({saved: true, error: false})
                    }
                }
            ).catch(errorMessage => {
            setSavedStatus({saved: false, error: true, errorMessage: errorMessage})
        });

    }


    useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (props.match.params.objectId != null) {
                fetchSecure(window.REACT_APP_BASE_API_URL + '/customer/' + props.match.params.objectId)
                    .then(response => response.json())
                    .then(data => setCustomer(data))
                    .then(() => setLoaded(true));
            }
        }
    }, [errors, loaded, props.match.params.objectId, savedStatus]);

    const fieldsToRender =
        {
            "elements": [
                {"type": "textField", "typeTextField": "text", "id": "name", "label": "Nombre", "disabled": show},
                {"type": "textField", "typeTextField": "text", "id": "email", "label": "Email", "disabled": show},
                {
                    "type": "textField",
                    "typeTextField": "text",
                    "id": "cellPhone",
                    "label": "Cell Phone",
                    "disabled": show
                },
                {"type": "textField", "typeTextField": "text", "id": "position", "label": "Posición", "disabled": show},
                {
                    "type": "checkBox",
                    "typeTextField": "checkBox",
                    "id": "sendAlerts",
                    "label": "Enviar alertas?",
                    "disabled": show
                }
            ]
        }

    let history = useHistory()
    const goToPreviousPath = () => {
        history.goBack()
    }
    return (
        <Paper className={classes.root}>
            <Box m={2}>
                <Button variant="contained" color="primary" onClick={goToPreviousPath}>Listado</Button>
            </Box>
            {savedStatus.error && <Alert severity="error">
                Ha ocurrido un error inesperado al intentar grabar el cliente
                {Object.keys(errors).map((it) =>
                    <Fragment>
                        <li>
                            {errors[it]}
                        </li>
                    </Fragment>
                )}
            </Alert>
            }

            {savedStatus.saved && <Alert severity="success">El cliente se ha grabado con éxito</Alert>
            }

            {props.match.params.objectId != null &&
            <Box m={2}>
                <Grid container spacing={3}>
                    <Grid item xs={9}>
                    </Grid>
                    <Grid item xs={2}>
                        {t('button.show')} <Switch defaultChecked={show} color="primary" onClick={handleShow}/>
                    </Grid>
                </Grid>
            </Box>
            }

            <Box m={2}>
                <form className={classes.container} noValidate>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h6">{t('companyInformation')}</Typography>
                            <FormGroup row={true}>
                                <InputField disabled={show} error={!!errors.name} name="name" label="Nombre"
                                            value={customer.name} type="text" onChange={handleChange}
                                            className={classes.textField}/>
                                <InputField disabled={show} error={!!errors.telephone} name="telephone" label="Teléfono"
                                            value={customer.telephone} type="number" onChange={handleChange}
                                            className={classes.textField}/>
                                <InputField disabled={show} error={!!errors.email} name="email" label="Email"
                                            value={customer.email} type="text" onChange={handleChange}
                                            className={classes.textField}/>
                                <InputField disabled={show} error={!!errors.notes} name="notes" label="Notas"
                                            value={customer.notes} type="text" onChange={handleChange}
                                            className={classes.textField}/>
                                <InputField disabled={show} error={!!errors.address} name="address" label="Dirección"
                                            value={customer.address} type="text" onChange={handleChange}
                                            className={classes.textField}/>
                                <InputField disabled={show} error={!!errors.addressDelivery} name="addressDelivery"
                                            label="Dirección de entrega" value={customer.addressDelivery} type="text"
                                            onChange={handleChange} className={classes.textField}/>
                                <DateField disabled={show} error={!!errors.dateCloseBalance} name="dateCloseBalance"
                                           label="dateCloseBalance" value={customer.dateCloseBalance}
                                           onChange={handleListChange} className={classes.textField}/>
                                <InputField disabled={show} error={!!errors.phone} name="phone" label="Celular"
                                            value={customer.phone} type="text" onChange={handleChange}
                                            className={classes.textField}/>

                            </FormGroup>
                        </CardContent>
                        <CardContent>
                            <FormGroup row={true}>
                                <InputField disabled={show} error={!!errors.cuit} name="cuit" label="Cuit"
                                            value={customer.cuit} type="text" onChange={handleChange}
                                            className={classes.textField}/>
                                <InputField disabled={show} error={!!errors.province} name="province" label="Provincia"
                                            value={customer.province} type="text" onChange={handleChange}
                                            className={classes.textField}/>
                                <InputField disabled={show} error={!!errors.locality} name="locality" label="Localidad"
                                            value={customer.locality} type="text" onChange={handleChange}
                                            className={classes.textField}/>
                                <SelectField disabled={show} error={!!errors.country} name="country" label="country"
                                             value={customer.country} changeHandler={handleListChange}
                                             className={classes.country}
                                             url={window.REACT_APP_BASE_API_URL + '/country'} optionValue="id"
                                             optionShow="name"/>
                                <SelectField disabled={show} error={!!errors.defaultLocale} name="defaultLocale"
                                             label="defaultLocale" value={customer.defaultLocale}
                                             changeHandler={handleListChange} className={classes.defaultLocale}
                                             url={window.REACT_APP_BASE_API_URL + '/availableLocale'} optionValue="id"
                                             optionShow="language"/>
                                <SelectField disabled={show} error={!!errors.defaultReport} name="defaultReport"
                                             label="defaultReport" value={customer.defaultReport}
                                             changeHandler={handleListChange} className={classes.defaultReport}
                                             url={window.REACT_APP_BASE_API_URL + '/report'} optionValue="id"
                                             optionShow="name"/>
                            </FormGroup>
                        </CardContent>
                    </Card>

                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h6">{t('informationContacts')}</Typography>
                            <FormGroup row={true}>
                                <ListComponent disabled={show} errorsList={errors} onChangeList={handleListChange}
                                               elementToRender={fieldsToRender} elementToUpload={customer.contacts}
                                               listName={"contacts"}/>
                            </FormGroup>
                        </CardContent>
                    </Card>

                    {show === false && <CardActions>
                        <FormGroup row={true}>
                            <Box m={2}>
                                <Button variant="contained" color="primary" onClick={handleSave}>Guardar</Button>
                            </Box>
                        </FormGroup>
                    </CardActions>
                    }
                </form>

                <StakeholderReportDownload stakeholderId={customer.id}/>
            </Box>

        </Paper>
    )
}

export default CustomerItem;
