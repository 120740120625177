import clsx from 'clsx';

import {Fragment, useState} from 'react'
import {Link} from "react-router-dom";


import Drawer from '@material-ui/core/Drawer';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import DirectionsBoat from '@material-ui/icons/DirectionsBoat';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LocalAirportIcon from '@material-ui/icons/LocalAirport';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import {useTranslation} from "react-i18next";
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import PublicIcon from '@material-ui/icons/Public';
import LanguageIcon from '@material-ui/icons/Language';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import PaymentIcon from '@material-ui/icons/Payment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import TransformIcon from '@material-ui/icons/Transform';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import {hasAccess} from "../../lib/hasAccess";
import {useAuthorization} from "../../lib/useAuthorization";


const MenuItem = ({screenName, username}) => {
    const [t] = useTranslation('common');

    if (hasAccess(username, screenName)) {
        return <ListItem component={Link} to={`/${screenName}`} button>
            <ListItemIcon><AssignmentIndIcon/></ListItemIcon>
            <ListItemText primary={t(`menu.${screenName}`)}/>
        </ListItem>
    } else {
        return null
    }

}

function Menu({classes, username}) {

    const anchor = 'left'
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => () => {

        setState({...state, [anchor]: open});
    };

    const [t] = useTranslation('common');

    const {canSeeReports} = useAuthorization()

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >

            <List>
                <ListItem component={Link} to="/" button>
                    <ListItemIcon><HomeIcon/></ListItemIcon>
                    <ListItemText primary={"Home"}/>
                </ListItem>
            </List>
            <Divider/>
            <List>
                <ListItem component={Link} to="/wish" button>
                    <ListItemIcon><AssignmentTurnedInIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.orders')}/>
                </ListItem>
                <ListItem component={Link} to="/preOrder" button>
                    <ListItemIcon><AssignmentTurnedInIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.preOrder')}/>
                </ListItem>
            </List>
            <Divider/>
            {canSeeReports && <><List>
                <ListItem component={Link} to="/report" button>
                    <ListItemIcon><MailIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.reports')}/>
                </ListItem>
            </List>
                <Divider/></>}
            <List>
                <ListItem component={Link} to="/agent" button>
                    <ListItemIcon><AssignmentIndIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.agents')}/>
                </ListItem>
                <ListItem component={Link} to="/supplier" button>
                    <ListItemIcon><SupervisedUserCircleIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.supplier')}/>
                </ListItem>
                <MenuItem screenName="customsBroker" username={username}/>
                <ListItem component={Link} to="/customer" button>
                    <ListItemIcon><PeopleIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.customer')}/>
                </ListItem>
                <MenuItem screenName="forwarder" username={username}/>
                <ListItem component={Link} to="/shipper" button>
                    <ListItemIcon><AccountCircle/></ListItemIcon>
                    <ListItemText primary={t('menu.shipper')}/>
                </ListItem>
            </List>
            <Divider/>
            <ListItem component={Link} to="/simi" button>
                <ListItemIcon><EmojiTransportationIcon/></ListItemIcon>
                <ListItemText primary={t('menu.simi')}/>
            </ListItem>

            <Divider/>

            <List>
                <ListItem component={Link} to="/ship" button>
                    <ListItemIcon><DirectionsBoat/></ListItemIcon>
                    <ListItemText primary={t('menu.ship')}/>
                </ListItem>
                <ListItem component={Link} to="/port" button>
                    <ListItemIcon><LocalAirportIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.port')}/>
                </ListItem>

                <ListItem component={Link} to="/maritimeLine" button>
                    <ListItemIcon><DirectionsBoat/></ListItemIcon>
                    <ListItemText primary={t('menu.maritimeLine')}/>
                </ListItem>

                <ListItem component={Link} to="/courier" button>
                    <ListItemIcon><MarkunreadMailboxIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.courier')}/>
                </ListItem>
                <ListItem component={Link} to="/letterOfGuarantee" button>
                    <ListItemIcon><MarkunreadMailboxIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.letterOfGuarantee')}/>
                </ListItem>
                <ListItem component={Link} to="/courierRecord" button>
                    <ListItemIcon><MarkunreadMailboxIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.courierRecord')}/>
                </ListItem>
                <ListItem component={Link} to="/contact" button>
                    <ListItemIcon><PermContactCalendarIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.contact')}/>
                </ListItem>
            </List>
            <Divider/>

            <List>
                <ListItem component={Link} to="/reportSendConfiguration" button>
                    <ListItemIcon><NotificationsActiveIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.reportSendConfiguration')}/>
                </ListItem>
                <ListItem component={Link} to="/currency" button>
                    <ListItemIcon><AttachMoneyIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.currency')}/>
                </ListItem>
                <ListItem component={Link} to="/productType" button>
                    <ListItemIcon><AttachMoneyIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.productType')}/>
                </ListItem>
                <ListItem component={Link} to="/family" button>
                    <ListItemIcon><SupervisedUserCircleIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.family')}/>
                </ListItem>
                <ListItem component={Link} to="/itemUnit" button>
                    <ListItemIcon><AcUnitIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.itemUnit')}/>
                </ListItem>
                <ListItem component={Link} to="/portTerminal" button>
                    <ListItemIcon><SupervisedUserCircleIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.portTerminal')}/>
                </ListItem>
                <ListItem component={Link} to="/country" button>
                    <ListItemIcon><PublicIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.country')}/>
                </ListItem>
                <ListItem component={Link} to="/container" button>
                    <ListItemIcon><PublicIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.container')}/>
                </ListItem>
                <ListItem component={Link} to="/typeOfPresentation" button>
                    <ListItemIcon><LocalAirportIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.typeOfPresentation')}/>
                </ListItem>
                <ListItem component={Link} to="/color" button>
                    <ListItemIcon><ColorLensIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.color')}/>
                </ListItem>
                <ListItem component={Link} to="/bank" button>
                    <ListItemIcon><AccountBalanceIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.bank')}/>
                </ListItem>
                <ListItem component={Link} to="/otherStakeholder" button>
                    <ListItemIcon><LocationCityIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.otherStakeholder')}/>
                </ListItem>
            </List>

            <Divider/>

            <List>
                <ListItem component={Link} to="/criterionValue" button>
                    <ListItemIcon><TransformIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.criterionValue')}/>
                </ListItem>
                <ListItem component={Link} to="/product" button>
                    <ListItemIcon><TransformIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.product')}/>
                </ListItem>
                <ListItem component={Link} to="/positionArancel" button>
                    <ListItemIcon><TransformIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.positionArancel')}/>
                </ListItem>
                <ListItem component={Link} to="/paymentStatus" button>
                    <ListItemIcon><PaymentIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.paymentStatus')}/>
                </ListItem>
                <ListItem component={Link} to="/shippingMark" button>
                    <ListItemIcon><LocalShippingIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.shippingMark')}/>
                </ListItem>
                <ListItem component={Link} to="/wishStatus" button>
                    <ListItemIcon><LoyaltyIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.wishStatus')}/>
                </ListItem>
                <ListItem component={Link} to="/availableLocale" button>
                    <ListItemIcon><LanguageIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.availableLocale')}/>
                </ListItem>
                <ListItem component={Link} to="/documentType" button>
                    <ListItemIcon><AssignmentIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.documentType')}/>
                </ListItem>
                <ListItem component={Link} to="/paymentTerm" button>
                    <ListItemIcon><ReceiptIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.paymentTerm')}/>
                </ListItem>
                <ListItem component={Link} to="/priceCondition" button>
                    <ListItemIcon><LocalAtmIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.priceCondition')}/>
                </ListItem>
                <ListItem component={Link} to="/typeOfFreight" button>
                    <ListItemIcon><EmojiTransportationIcon/></ListItemIcon>
                    <ListItemText primary={t('menu.typeOfFreight')}/>
                </ListItem>

            </List>

        </div>

    );


    return (
        <Fragment>
            <IconButton onClick={toggleDrawer(anchor, true)} edge="start" className={classes.menuButton} color="inherit"
                        aria-label="menu">
                <MenuIcon/>
            </IconButton>

            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                {list(anchor)}
            </Drawer>
        </Fragment>

    )

}


export default Menu;
