import React, {useEffect, useState} from 'react';
import {Box, Button, Paper, TextField} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import Grid from '@material-ui/core/Grid';
import {Link} from "react-router-dom";
import {fetchSecure} from "../../modules/fetchSecure";
import TableComponent from "../../components/table/TableComponent";
import {useTableSearch} from "../../components/search/UseTableSearch";
import LoadingScreen from '../../components/loading/LoadingScreen';


function MaritimeLine() {

    const [maritimeLineList, setMaritimeLineList] = useState([]);

    const [t] = useTranslation('common');
    const [searchVal, setSearchVal] = useState(null);

    const {filteredData} = useTableSearch({
        searchVal,
        retrieve: maritimeLineList
    });

    const createTo = {
        pathname: "/maritimeLine/create",
    };

    const columns = [
        {id: 'id', label: 'ID', minWidth: 170},
        {id: 'name', label: 'Nombre', minWidth: 100},
    ];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };
    const [loading, setLoading] = useState(false);


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        setLoading(true)
        fetchSecure(window.REACT_APP_BASE_API_URL + '/maritimeLine')
            .then(response => response.json())
            .then(data => setMaritimeLineList(data))
            .then(() => setLoading(false))
            .catch(() => setLoading(false));

    }, [page]);

    if (loading) {
        return <Box display="flex" justifyContent="center" alignItems="center" style={{height: '100vh'}}><LoadingScreen
            backgroundColor={'white'}/></Box>
    } else {
        return <div>
            <Paper>
                <Grid container spacing={3}>
                    <Grid item xs={9}>
                        <Box m={2}>
                            <TextField
                                onChange={e => setSearchVal(e.target.value)}
                                placeholder="Search"
                                enterButton
                                style={{position: "sticky", top: "0", left: "0"}}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={3} alignItems="left">

                        <Box m={2}>
                            <Link to={createTo}>
                                <Button variant="contained" color="primary">{t('button.create')}</Button>
                            </Link>
                        </Box>

                    </Grid>
                </Grid>

                <TableComponent
                    columns={columns}
                    filteredData={filteredData}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    count={maritimeLineList.length}
                    urlEdit={"/maritimeLine/edit/"}
                />
            </Paper>


        </div>
    }

}

export default MaritimeLine;
