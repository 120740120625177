import React, {useCallback, useEffect, useState} from 'react';

import {Link} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import TableComponent from "../../../components/table/TableComponent.js";
import {useTranslation} from "react-i18next";
import {Box, Button, makeStyles, Modal, Paper, TextField} from '@material-ui/core'
import {fetchSecure} from "../../../modules/fetchSecure";
import Typography from "@material-ui/core/Typography";
import {SnackbarMessage} from "../../../components/atoms/SnackbarMessage";
import SelectField from "../../../components/selectField/SelectField";
import {useAuthorization} from "../../../lib/useAuthorization";
import LoadingScreen from '../../../components/loading/LoadingScreen.js';


function WishAll() {
    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {
            maxHeight: 440,
        },
    });
    const [t] = useTranslation('common');
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(200);
    const [customer, setCustomer] = useState(undefined);
    const [wishList, setWishList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [supplierOrderFilter, setSupplierOrderFilter] = useState('')
    const [productDescriptionFilter, setProductDescriptionFilter] = useState('')

    const {loadingUser, canDelete, canSeeConfidentialData} = useAuthorization()

    const fetchAndSetWishList = useCallback(async (currentSupplierOrder, currentProductDescription, currentPage) => {
        setLoading(true)
        try {
            const url = window.REACT_APP_BASE_API_URL + `/wish?page=${currentPage ?? 0}&size=${rowsPerPage}&supplierOrder=${currentSupplierOrder}&productDescription=${currentProductDescription}&customerId=${customer}`
            const response = await fetchSecure(url)
            const data = await response.json()
            setWishList(data)
        } finally {
            setLoading(false)
        }
    }, [customer, rowsPerPage])

    const fetchAndSetTotalWishQuantity = useCallback(async (currentSupplierOrder, currentProductDescription) => {
        setLoadingQuantityOfWish(true)
        try {
            const response = await fetchSecure(window.REACT_APP_BASE_API_URL + `/quantityOfWish?supplierOrder=${currentSupplierOrder}&productDescription=${currentProductDescription}&customerId=${customer}`)
            const data = await response.json()
            setQuantityOfWish(data)
        } finally {
            setLoadingQuantityOfWish(false)
        }
    }, [customer])

    useEffect(() => {
        fetchAndSetWishList('', '', page).then(() => {
        })
    }, [page, fetchAndSetWishList])

    useEffect(() => {
        fetchAndSetTotalWishQuantity('', '').then(() => {
        })
    }, [fetchAndSetTotalWishQuantity])

    const columns = [
        {id: 'supplier.name', label: 'Proveedor', minWidth: 100},
        {id: 'customer.name', label: 'Cliente', minWidth: 100},
        {id: 'productDescription', label: 'Descripcion del producto', minWidth: 100},
        {id: 'opNumber', label: 'Nro OP', minWidth: 100},
        {id: 'customerOpNumber', label: 'Nro OP cliente', minWidth: 100},
        {id: 'supplierOrder', label: 'Orden', minWidth: 100},
    ];


    const handleChangePage = (event, page) => {
        setPage(page);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const createTo = {
        pathname: "/wish/edit/"
    }


    const [loadingQuantityOfWish, setLoadingQuantityOfWish] = useState(false);
    const [quantityOfWish, setQuantityOfWish] = useState(0);

    const [deleteModal, setDeleteModal] = useState({open: false, row: 0})
    const [isInError, setIsInError] = useState({open: false, message: ''})


    const handlerDeleteModal = (id) => {
        setDeleteModal({open: true, row: id})
    }

    const onHandlerDeleteElement = async () => {
        const response = await fetchSecure(window.REACT_APP_BASE_API_URL + '/wish/' + deleteModal.row, {
            method: 'DELETE',
        })
        if (!response.ok) {
            setIsInError({open: true, message: 'Ocurrió un error al eliminar el objeto'})
        }
        setDeleteModal({open: false, row: 0})
    }

    const handleListChange = (listName, listValue) => {
        setCustomer(listValue.id)
    }

    const downloadWishPrices = async () => {
        const response = await fetchSecure(window.REACT_APP_BASE_API_URL + '/wish/downloadListPrices')
        const blob = await response.blob()
        const file = window.URL.createObjectURL(blob)
        window.location.assign(file)
    }

    if (loading || loadingQuantityOfWish || loadingUser) {
        return <Box display="flex" justifyContent="center" alignItems="center" style={{height: '100vh'}}><LoadingScreen
            backgroundColor={'white'}/></Box>
    } else {
        return <div>
            <Paper className={classes.root}>
                <Grid container spacing={9}>
                    <Grid item xs={6}>
                        <Box m={2}>
                            <h1>Pedidos - Todos </h1>
                            <TextField
                                onChange={e => setProductDescriptionFilter(e.target.value)}
                                value={productDescriptionFilter}
                                placeholder={t('productDescription')}
                                enterButton
                                style={{position: "sticky", top: "0", left: "0"}}
                            />
                            <TextField

                                onChange={e => setSupplierOrderFilter(e.target.value)}
                                value={supplierOrderFilter}
                                placeholder={t('supplierOrder')}
                                enterButton
                                style={{position: "sticky", top: "0", marginLeft: '8px'}}
                            />
                            <SelectField disabled={false} name="customer" label="customer"
                                         value={{id: customer}} changeHandler={handleListChange}
                                         className={classes.textField}
                                         url={window.REACT_APP_BASE_API_URL + '/customer'} optionValue="id"
                                         optionShow="name"/>
                            <Button style={{position: "sticky", top: "0", marginLeft: '8px'}}
                                    onClick={async () => {
                                        await fetchAndSetWishList(supplierOrderFilter, productDescriptionFilter, page)
                                        await fetchAndSetTotalWishQuantity(supplierOrderFilter, productDescriptionFilter)

                                    }}>Filter</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box m={2}>
                            <Link to="/wish/create">
                                <Button variant="contained" color="primary" align="right">Crear</Button>
                            </Link>

                            <Link to="/wish">
                                <Button variant="contained" color="primary">{t('button.pending')}</Button>
                            </Link>
                            <Link to="/wish/finished">
                                <Button variant="contained" color="primary">{t('button.finished')}</Button>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={3} alignItems="right">
                        {canSeeConfidentialData &&
                        <Button onClick={downloadWishPrices} style={{marginLeft: 8}} color="primary">Descargar Lista de
                            pagos</Button>}
                    </Grid>
                </Grid>

                <TableComponent
                    columns={columns}
                    filteredData={wishList}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    count={quantityOfWish}
                    urlEdit={createTo}
                    usingPagination={true}
                    wish
                    shouldShowIconToDelete={() => canDelete}
                    handlerDeleteModal={handlerDeleteModal}
                />
                <Modal
                    open={deleteModal.open}
                    onClose={() => setDeleteModal({open: false, row: 0})}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4
                    }}>
                        <Typography id="modal-modal-description" sx={{mt: 2}}>
                            Estas seguro que desea elimininarlo?
                        </Typography>
                        <Box sx={{display: 'flex', flexDirection: 'row', flex: 1, marginTop: '16px'}}>
                            <Button variant="contained" onClick={async () => {
                                await onHandlerDeleteElement()
                            }} style={{flex: 1, display: 'flex'}} color="secondary">
                                Borrar
                            </Button>
                            <Button variant="text" onClick={() => {
                                setDeleteModal({open: false, row: 0})
                            }} style={{flex: 1, display: 'flex'}}>
                                Cancelar
                            </Button>
                        </Box>

                    </Box>
                </Modal>

                <SnackbarMessage open={isInError.open} handleClose={() => {
                    setIsInError({open: false, message: ''})
                }} severity={'error'} message={'Ocurrió un error al borrar el pedido'}/>

            </Paper>


        </div>
    }

}

export default WishAll;
