import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Paper, Box, Button, TextField, useTheme} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import { useTableSearch } from "../../../components/search/UseTableSearch";
import TableComponent from "../../../components/table/TableComponent.js";
import Grid from '@material-ui/core/Grid';
import { Link } from "react-router-dom";
import {fetchSecure} from "../../../modules/fetchSecure";
import Alert from "@material-ui/lab/Alert";
import LoadingScreen from '../../../components/loading/LoadingScreen.js';

function PositionArancel() {

	const [positionArancelList, setPositionArancelList] = useState([]);
	const [selectedFile, setSelectedFile] = useState(undefined);
	const [savedFileStatus, setSavedFileStatus] = useState({ saved: false, processing: false, error: false });
	const [loading, setLoading] = useState(false);

	const uploadFile = (e) => {
		setSelectedFile(e.target.files[0])
	}

	const setMessageUploadFile = (status) => {
		setSavedFileStatus(status)
		setTimeout(() => setSavedFileStatus({saved: false, processing: false, error: false}), 6000)
	}

	const [t] = useTranslation('common');
	const [searchVal, setSearchVal] = useState(null);

	const { filteredData } = useTableSearch({
		searchVal,
		retrieve: positionArancelList
	});

	const createTo = {
		pathname: "/positionArancel/create",
	};

	const columns = [
		{ id: 'id', label: 'ID', minWidth: 170 },
		{ id: 'positionArancelId', minWidth: 170 },
		{ id: 'rights', label: 'Derechos', minWidth: 100 },
		{ id: 'criterionValue', label: 'Valor criterio', minWidth: 100 },
	];
	const useStyles = makeStyles({
		root: {
			width: '100%',
		},
		container: {
			maxHeight: 440,
		},
	});

	const theme = useTheme()

	const classes = useStyles();
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(100);
	const handleChangePage = (_, newPage) => {
		setPage(newPage);
	};

	const sendFile = async (e) => {
		try {
			setSavedFileStatus({saved: false, processing: true, error: false})
			const data = new FormData()
			data.append('file', selectedFile)
			const requestOptions = {
				method: 'PUT',
				body: data
			};

			await fetchSecure(window.REACT_APP_BASE_API_URL+'/positionArancel/upload', requestOptions)
				.then(async response => {
						if (!response.ok) {
							setMessageUploadFile({saved: false, processing: false, error: true})
						} else {
							setMessageUploadFile({saved: true, processing: false, error: false})
						}
					}
				).catch(() => {
					setMessageUploadFile({saved: true, processing: false, error: false})
				})
		} catch (e) {
			setMessageUploadFile({saved: true, processing: false, error: false})
		}
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	}

	const download = async () => {
		const response = await fetchSecure(window.REACT_APP_BASE_API_URL+'/positionArancel/download')
		const blob = await response.blob()
		const file = window.URL.createObjectURL(blob)
		window.location.assign(file)
	}

	useEffect(() => {
		setLoading(true)
		fetchSecure(window.REACT_APP_BASE_API_URL+'/positionArancel')
			.then(response => response.json())
			.then(data => setPositionArancelList(data))
			.then(() => setLoading(false))
			.catch(() => setLoading(false));

	}, [page]);

	if (loading || savedFileStatus.processing) {
		return <Box display="flex" justifyContent="center" alignItems="center" style={{height: '100vh'}}><LoadingScreen backgroundColor={'white'}/></Box>
	} else {
		return <div>
			<Paper className={classes.root}>
				{savedFileStatus.saved && <Alert severity="success">La posicion arancelaria se ha actualizado con exito</Alert>}
				{savedFileStatus.error && <Alert severity="error">La posicion arancelaria no se ha podido actualizar</Alert>}
				<Grid container spacing={3}>
					<Grid item xs={6}>
						<Box m={2}>
							<TextField
								onChange={e => setSearchVal(e.target.value)}
								placeholder="Search"
								enterButton
								style={{ position: "sticky", top: "0", left: "0" }}
							/>
						</Box>
					</Grid>
					<Grid item xs={3} alignItems="flex-end">
						<Box m={2} style={{display: 'flex', justifyContent: 'flex-end'}}>
							<Link to={createTo}>
								<Button variant="contained" color="primary">{t('button.create')}</Button>
							</Link>

							<Button onClick={download} style={{marginLeft: theme.spacing(1)}} color="primary">{t('download')}</Button>
						</Box>
					</Grid>
					<Grid item xs={3} alignItems="left">
						<Box m={2} >
							<input type="file" name="file" onChange={(e) => uploadFile(e)}/>
						</Box>
						<Box m={2} >
							<Button onClick={sendFile} disabled={!selectedFile || savedFileStatus.processing} style={{marginLeft: 8}} color="primary">{t('upload')}</Button>
						</Box>
					</Grid>
				</Grid>

				<TableComponent
	columns={columns}
	filteredData={filteredData}
	handleChangePage={handleChangePage}
	handleChangeRowsPerPage={handleChangeRowsPerPage}
	page={page}
	rowsPerPage={rowsPerPage}
	urlEdit={'positionArancel/edit/'}
	count={positionArancelList.length}
	/>
			</Paper>


		</div>
	}

}

export default PositionArancel;
