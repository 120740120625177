import React, {useEffect, useState} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {useTranslation} from "react-i18next";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Grid from "@material-ui/core/Grid";
import {Box, Button, Modal, TextField} from "@material-ui/core";
import {Link} from "react-router-dom";
import {useTableSearch} from "../../../components/search/UseTableSearch";
import {Auth} from "@aws-amplify/auth";
import DeleteIcon from "@material-ui/icons/Delete";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {fetchSecure} from "../../../modules/fetchSecure";
import {fullAccessUsers} from "../../../lib/fullAccessUsers";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Typography from "@material-ui/core/Typography";
import LoadingScreen from '../../../components/loading/LoadingScreen';
import { ReportLinkRow } from './ReportLinkRow';

function Report() {

	const [reportList, setReportList] = useState([]);
	const [open, setOpenModal] = useState({open: false, row: 0});
	const [t] = useTranslation('common');
	const [searchVal, setSearchVal] = useState(null);
	const [loading, setLoading] = useState(false);


	const [showConfidential, setShowConfidential] = useState(false)
	const [showConfidentialLoaded, setShowConfidentialLoaded] = useState(false)

	const [deleteStatus, setDeleteStatus] = useState({error: false, deleted: false})
	const [currentUser, setCurrentUser] = useState('')

	useEffect(() => {
		Auth.currentAuthenticatedUser()
			.then(async (user) => {
				setCurrentUser(user.username)
				setShowConfidential(fullAccessUsers.includes(user.username) )
				setShowConfidentialLoaded(true)
			})
			.catch((e) => {
				setShowConfidential(false)
				setShowConfidentialLoaded(true)
				console.error(JSON.stringify(e))
			})
	}, []);
	
	Object.byString = function (o, s) {
		s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
		s = s.replace(/^\./, '');           // strip a leading dot
		var a = s.split('.');
		for (var i = 0, n = a.length; i < n; ++i) {
			var k = a[i];

			if (o != null && k in o) {
				o = o[k];
			} else {
				return;
			}
		}
		return o;
	}
	const { filteredData } = useTableSearch({
		searchVal,
		retrieve: reportList
	});

	const columns = [
		{ id: 'id', label: 'ID', minWidth: 170 },
		{ id: 'name', label: 'Nombre', minWidth: 100 },
	];
	const useStyles = makeStyles({
		root: {
			width: '100%',
		},
		container: {
			maxHeight: 1000,
		},
	});

	const classes = useStyles();
	useEffect(() => {
		if(showConfidentialLoaded) {
			setLoading(true)
			fetchSecure(window.REACT_APP_BASE_API_URL+'/report')
			.then(response => response.json())
			.then(data => setReportList(data.filter(report => showConfidential || (!report.confidential && (report.usersPermitted ===null || report.usersPermitted.isEmpty || report.usersPermitted.split(',').includes(currentUser))))))
			.then(() => setLoading(false))
			.catch(() => setLoading(false));

		}
	}, [showConfidentialLoaded, showConfidential, deleteStatus.deleted, currentUser]);

	if (loading) {
		return <Box display="flex" justifyContent="center" alignItems="center" style={{height: '100vh'}}><LoadingScreen backgroundColor={'white'}/></Box>
	} else {
		return <div>
			<Paper className={classes.root}>
				<Modal
					open={open.open}
					onClose={() => setOpenModal({open: false, row: 0})}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={{position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: 400,
						bgcolor: 'background.paper',
						border: '2px solid #000',
						boxShadow: 24,
						p: 4}}>
						<Typography id="modal-modal-description" sx={{ mt: 2 }}>
							Estas seguro que desea eliminar el reporte?
						</Typography>
						<Box sx={{display: 'flex', flexDirection: 'row', flex: 1, marginTop: '16px'}}>
						<Button variant="contained" onClick={() => {
							fetchSecure(window.REACT_APP_BASE_API_URL+'/report/'+open.row, {
								method: 'DELETE',
							})
								.then(async (response) => {
									if (!response.ok) {
										setDeleteStatus({error: true, deleted: false})
										setOpenModal({open: false, row: 0})
									} else {
										const data = await response.json()
										setDeleteStatus({error: !data.deleted, deleted: data.deleted})
										setOpenModal({open: false, row: 0})
									}
								})

						}} style={{flex: 1, display: 'flex'}} color="secondary">
							Borrar
						</Button>
							<Button variant="text" onClick={() => {
								setOpenModal({open: false, row: 0})
							}} style={{flex: 1, display: 'flex'}}>
								Cancelar
							</Button>
						</Box>

					</Box>
				</Modal>
				<Grid container spacing={3}>
					<Grid item xs={9}>
						<Box m={2}>
							<TextField
								onChange={e => setSearchVal(e.target.value)}
								placeholder="Search"
								enterButton
								style={{ position: "sticky", top: "0", left: "0" }}
							/>
						</Box>
					</Grid>
						<Grid item xs={3} alignItems="right">
							<Box m={3}>
								<Link to={{pathname: '/report/create', state: {prevPath: '/report/create'}}}>
									<Button variant="contained" color="primary">{t('button.create')}</Button>
								</Link>
							</Box>
						</Grid>
				</Grid>
				<TableContainer className={classes.container}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}
									>
										{t(column.id)}
									</TableCell>

								))}
								<TableCell>{t('button.edit')} </TableCell>
								<TableCell>{t('download')} </TableCell>
								<TableCell>{t('button.delete')} </TableCell>
								<TableCell>{t('button.clone')} </TableCell>
							</TableRow>

						</TableHead>
						<TableBody>
							<ReportLinkRow linkName={t('button.downloadPayments')} linkUrl={window.REACT_APP_BASE_API_URL + '/wishPayment/download'}/>

							<ReportLinkRow linkName={t('button.downloadSubWish')} linkUrl={window.REACT_APP_BASE_API_URL + '/subWish/download'}/>

							{filteredData.map((row) => {
								return (
									<TableRow hover role="checkbox" tabIndex={-1} key={row.id}>


										{columns.map((column) => {
											const value = Object.byString(row, column.id);


											return (
												<TableCell key={column.id} align={column.align}>
													{column.format && typeof value === 'number' ? column.format(value) : value}
												</TableCell>
											);
										})}
										<TableCell>
											<Link to={'report/edit/' + row.id}>
												<Button color="primary">
													<EditIcon />

												</Button>
											</Link>
										</TableCell>
										<TableCell >
											<Button onClick={() => {
												fetchSecure(window.REACT_APP_BASE_API_URL+'/report/download/'+row.id).then(response => response.blob())
													.then( blob => {
														const file = window.URL.createObjectURL(blob)
														window.location.assign(file)
													})

											}

											}>
												<CloudDownloadIcon />
											</Button>
										</TableCell>
										<TableCell >
											<Button onClick={() => {setOpenModal({open: true, row: row.id})}}>
												<DeleteIcon />
											</Button>
										</TableCell>
										<TableCell >
											<Link to={{pathname: 'report/edit/' + row.id, state: {clone: true}}}>
												<Button >
												<ContentCopyIcon />

												</Button>
											</Link>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>

			<Snackbar open={deleteStatus.error || deleteStatus.deleted} onClose={() => setDeleteStatus({error: false, deleted: false})}>
				<Alert onClose={() => setDeleteStatus({error: false, deleted: false})} variant="filled" severity={deleteStatus.error?'error':'success'}>
					{deleteStatus.error?'El reporte no se puede borrar':'Reporte borrado con éxito'}
				</Alert>
			</Snackbar>


		</div>
	}

}

export default Report;
