import {TextField} from '@material-ui/core'
import {useEffect, useState} from 'react'

import {useTranslation} from "react-i18next"
import {fetchSecure} from "../../modules/fetchSecure"
import {Autocomplete, Box} from "@mui/material"

export const SimpleSelectField = ({name, value, onChange, url, optionValue, optionShow, disabled, style}) => {

    const [optionsList, setOptionsList] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const [t] = useTranslation('common');


    useEffect(() => {

        const fetchData = async () => {
            const response = await fetchSecure(url)
            const data = await response.json()
            setOptionsList(data)
            setLoaded(true)
        }

        if (url !== undefined) {
            fetchData()
        }
    }, [loaded, url]);


    const labels = Object.fromEntries(
        optionsList.map(option => [option[optionValue], option[optionShow]])
    )
    const values = optionsList.map(option => option[optionValue])


    return <Box style={style}><Autocomplete
        disabled={disabled}
        disablePortal
        id={name}
        value={value}
        defaultValue={null}
        autoHighlight
        onChange={onChange}
        options={values}
        getOptionLabel={(option) => labels[option] ?? ''}
        renderInput={(params) => <TextField {...params} label={t(name)}
                                            variant="outlined"
                                            inputProps={{
                                                ...params.inputProps
                                            }}

        />}/></Box>


}
