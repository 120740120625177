import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Paper, Box, Button, TextField } from '@material-ui/core';
import {useTranslation} from "react-i18next";
import { useTableSearch } from "../../../components/search/UseTableSearch";
import TableComponent from "../../../components/table/TableComponent.js";
import Grid from '@material-ui/core/Grid';
import { Link } from "react-router-dom";
import {fetchSecure} from "../../../modules/fetchSecure";


function WishStatus() {

	Object.byString = function (o, s) {
		s = s.replace(/\[(\w+)]/g, '.$1'); // convert indexes to properties
		s = s.replace(/^\./, '');           // strip a leading dot
		var a = s.split('.');
		for (var i = 0, n = a.length; i < n; ++i) {
			var k = a[i];

			if (o != null && k in o) {
				o = o[k];
			} else {
				return;
			}
		}
		return o;
	}
	const [wishStatusList, setWishStatusList] = useState([]);

	const [t] = useTranslation('common');
	const [searchVal, setSearchVal] = useState(null);

	const { filteredData } = useTableSearch({
		searchVal,
		retrieve: wishStatusList
	});

	const createTo = {
		pathname: "/wishStatus/create",
	};

	const columns = [
		{ id: 'id', label: 'ID', minWidth: 170 },
		{ id: 'name', label: 'Nombre', minWidth: 100 },
		{ id: 'description', label: 'Tipo', minWidth: 100 },
	];
	const useStyles = makeStyles({
		root: {
			width: '100%',
		},
		container: {
			maxHeight: 440,
		},
	});

	const classes = useStyles();
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(100);
	const handleChangePage = (_, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	useEffect(() => {
		fetchSecure(window.REACT_APP_BASE_API_URL+'/wishStatus')
			.then(response => response.json())
			.then(data => setWishStatusList(data));

	}, [page]);

	if (!wishStatusList) {
		return <div>Cargando</div>
	} else {
		return <div>
			<Paper className={classes.root}>
				<Grid container spacing={3}>
					<Grid item xs={9}>
						<Box m={2}>
							<TextField
								onChange={e => setSearchVal(e.target.value)}
								placeholder="Search"
								enterButton
								style={{ position: "sticky", top: "0", left: "0" }}
							/>
						</Box>
					</Grid>
					<Grid item xs={3} alignItems="left">

						<Box m={2} >
							<Link to={createTo}>
								<Button variant="contained" color="primary">{t('button.create')}</Button>
							</Link>
						</Box>

					</Grid>
				</Grid>

				<TableComponent
	columns={columns}
	filteredData={filteredData}
	handleChangePage={handleChangePage}
	handleChangeRowsPerPage={handleChangeRowsPerPage}
	page={page}
	rowsPerPage={rowsPerPage}
	count={wishStatusList.length}
	urlEdit={"/wishStatus/edit/"}
	/>
			</Paper>


		</div>
	}

}

export default WishStatus;
