import React, {useEffect, useState} from 'react';
import {Box, FormControl, InputLabel, MenuItem, Select} from '@material-ui/core';
import Input from '@material-ui/core/Input';

import {useTranslation} from "react-i18next";
import {fetchSecure} from "../../modules/fetchSecure";

function SelectFieldMultiple({
                                 name,
                                 value,
                                 changeHandler,
                                 className,
                                 url,
                                 optionValue,
                                 optionShow,
                                 error,
                                 disabled,
                                 data,
                                 optionShowWithI18n = true
                             }) {

    const [optionsList, setOptionsList] = useState([]);

    const [t] = useTranslation('common');

    useEffect(() => {
        if (!!url) {
            fetchSecure(url)
                .then(response => response.json())
                .then(responseJson => setOptionsList(responseJson))
        } else {
            setOptionsList(data ?? [])
        }

    }, [url,data]);

    const handleChange = e => {
        let options = e.target.value;
        const currentValue = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            let element = {};
            element[optionValue] = options[i];
            element.version = optionsList.find(element => element[optionValue] === options[i])?.version;
            currentValue.push(element)
        }
        changeHandler(name, currentValue)
    };

    let labelId = name + '-label';

    const currentValue = [];
    if (value != null && optionsList.length > 0) {
        for (let i = 0, l = value.length; i < l; i += 1) {
            currentValue.push(value[i][optionValue]);
        }
    }

    return <FormControl><Box ml={1} mr={1}>
        <InputLabel shrink id={labelId}>
            {t(name)}
        </InputLabel>

        <Select input={<Input/>} multiple disabled={disabled} error={error} labelId={labelId} displayEmpty={true}
                id={name} name={name} value={currentValue} className={className} onChange={handleChange}>
            <MenuItem key={-1} value="">Por favor elija una opción</MenuItem>

            {optionsList.map((it, index) => (
                <MenuItem key={index}
                          value={it[optionValue]}>{optionShowWithI18n ? t(it[optionShow]) : it[optionShow]}</MenuItem>

            ))}
        </Select>
    </Box>
    </FormControl>

}

export default SelectFieldMultiple
