import React, {useEffect, useState} from 'react';

import {Link, useHistory} from "react-router-dom";
import Grid from '@material-ui/core/Grid';

import {Box, Button, makeStyles, Paper, TextField} from '@material-ui/core'

import TableComponent from "../../../components/table/TableComponent.js";
import {useTranslation} from "react-i18next";
import {fetchSecure} from "../../../modules/fetchSecure";
import {format as formatDate} from "date-fns";
import {useTableSearch} from "../../../components/search/UseTableSearch";
import {SnackbarMessage} from "../../../components/atoms/SnackbarMessage";
import {useAuthorization} from "../../../lib/useAuthorization";
import {DeleteModal} from "./DeleteModal";
import LoadingScreen from '../../../components/loading/LoadingScreen.js';

function Wish() {
    const history = useHistory()

    const [t] = useTranslation('common')

    const [wishList, setWishList] = useState([])


    const [searchVal, setSearchVal] = useState(null)
    const [loading, setLoading] = useState(false);
    const [descendingOrder, setDescendingOrder] = useState(true)


    const {filteredData} = useTableSearch({
        searchVal,
        retrieve: wishList
    });

    useEffect(() => {
        if (history.location.state !== undefined && history.location.state.search !== 'undefined' && history.location.state.search !== 'null') {
            setSearchVal(history.location.state.search)
        }
    }, [history.location.state]);

    const columns = [
        {
            id: 'estimatedTimeOfArrival', label: 'Fecha de arribo',
            minWidth: 100, format: (date) => {
                return !date ? '' : formatDate(new Date(date), 'dd/MM/yyyy')
            },
            onHeaderClick: (list) => {
                const newDescendingOrder = !descendingOrder
                const sortedList = list.sort(function (a, b) {
                    const distantFuture = new Date(newDescendingOrder ? -8640000000000000 : 8640000000000000)
                    let dateA = a.estimatedTimeOfArrival ? new Date(a.estimatedTimeOfArrival) : distantFuture
                    let dateB = b.estimatedTimeOfArrival ? new Date(b.estimatedTimeOfArrival) : distantFuture
                    return (dateA.getTime() - dateB.getTime()) !== 0 ? dateA.getTime() - dateB.getTime() : a.id - b.id
                })
                if (newDescendingOrder) {
                    sortedList.reverse()
                }
                setDescendingOrder(newDescendingOrder)
                setWishList(sortedList)

            }
        },
        {id: 'shipper.name', minWidth: 100},
        {id: 'customer.name', minWidth: 100},
        {id: 'productDescription', label: 'Descripcion del producto', minWidth: 100},
        {id: 'calculatedPaymentStatus', minWidth: 100},
        {
            id: 'dateCreated', minWidth: 100, format: (date) => {
                return !date ? '' : formatDate(new Date(date), 'dd/MM/yyyy')
            },
            onHeaderClick: (list) => {
                const newDescendingOrder = !descendingOrder
                const sortedList = list.sort(function (a, b) {
                    let dateA = new Date(a.dateCreated)
                    let dateB = new Date(b.dateCreated)
                    return (dateA.getTime() - dateB.getTime()) !== 0 ? dateA.getTime() - dateB.getTime() : a.id - b.id
                })
                if (newDescendingOrder) {
                    sortedList.reverse()
                }
                setDescendingOrder(newDescendingOrder)
                setWishList(sortedList)

            }
        },
        {id: 'supplierOrder', minWidth: 100},

    ];
    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {
            maxHeight: 440,
        },
    });

    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(200);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const createTo = {
        pathname: "/wish/edit/",
        search: searchVal
    }

    const {loadingUser, canDelete, canSeeAllClients, canSeeConfidentialData} = useAuthorization()

    const [deleteModal, setDeleteModal] = useState({open: false, row: 0})
    const [deleteStatus, setDeleteStatus] = useState({error: false, row: 0})
    const [isInError, setIsInError] = useState({open: false, message: ''})


    const handlerDeleteModal = (id) => {
        setDeleteModal({open: true, row: id})
    }

    const onHandlerDeleteElement = async () => {
        const response = await fetchSecure(window.REACT_APP_BASE_API_URL + '/wish/' + deleteModal.row, {
            method: 'DELETE',
        })
        if (!response.ok) {
            setIsInError({open: true, message: 'Ocurrió un error al eliminar el objeto'})
            setDeleteStatus({error: true, row: 0})
        } else {
            setDeleteStatus({error: false, row: deleteModal.row})
        }
        setDeleteModal({open: false, row: 0})
    }


    useEffect(() => {
        setLoading(true)
        fetchSecure(window.REACT_APP_BASE_API_URL + `/wish/pending?page=${page}&size=${rowsPerPage}&supplierOrder=&productDescription=`)
            .then(response => response.json())
            .then(data => {
                setWishList(data)
                setQuantityOfWish(data.length)
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
                setIsInError({open: true, message: 'Ocurrió un error al cargar los datos'})
            });

    }, [page, rowsPerPage, deleteStatus.row]);

    const [quantityOfWish, setQuantityOfWish] = useState(0);

    const downloadWishPrices = async () => {
        const response = await fetchSecure(window.REACT_APP_BASE_API_URL + '/wish/downloadListPrices')
        const blob = await response.blob()
        const file = window.URL.createObjectURL(blob)
        window.location.assign(file)
    }


    if (loading || loadingUser) {
        return <Box display="flex" justifyContent="center" alignItems="center" style={{height: '100vh'}}><LoadingScreen
            backgroundColor={'white'}/></Box>
    }


    return <div>
        <Paper className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Box m={2}>
                        <h1>Pedidos - Pendientes </h1>
                        <TextField
                            onChange={e => setSearchVal(e.target.value)}
                            value={searchVal}
                            placeholder="Search"
                            enterButton
                            style={{position: "sticky", top: "0", left: "0"}}
                        />
                    </Box>
                </Grid>

                <Grid item xs={3} alignItems="right">
                    <Box m={3}>
                        <Link to={{pathname: '/wish/create', state: {prevPath: '/wish/create'}}}>
                            <Button variant="contained" color="primary">{t('button.create')}</Button>
                        </Link>
                        <Link to={{pathname: '/wish/all', state: {prevPath: '/wish/all'}}} style={{marginLeft: 8}}>
                            <Button variant="contained" color="primary">{t('button.all')}</Button>
                        </Link>
                        <Link to={{pathname: '/wish/finished', state: {prevPath: '/wish/finished'}}}
                              style={{marginLeft: 8}}>
                            <Button variant="contained" color="primary">{t('button.finished')}</Button>
                        </Link>

                    </Box>
                </Grid>
                <Grid item xs={3} alignItems="right">
                    {canSeeConfidentialData &&
                    <Button onClick={downloadWishPrices} style={{marginLeft: 8}} color="primary">Descargar Lista de
                        pagos</Button>}
                </Grid>

            </Grid>

            <TableComponent
                columns={columns}
                filteredData={canSeeAllClients ? filteredData : filteredData.filter((wish) => wish.customer.id !== 347)}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                count={quantityOfWish}
                urlEdit={createTo}
                usingPagination={false}
                wish
                shouldShowIconToDelete={() => canDelete}
                handlerDeleteModal={handlerDeleteModal}
            />

            <DeleteModal deleteModal={deleteModal}
                         setDeleteModal={setDeleteModal}
                         onHandlerDeleteElement={onHandlerDeleteElement}/>


            <SnackbarMessage open={isInError.open} handleClose={() => {
                setIsInError({open: false, message: ''})
            }} severity={'error'} message={isInError.message ?? 'Ocurrió un error al borrar el pedido'}/>

        </Paper>


    </div>


}

export default Wish;
