import {
    Button,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    useTheme
} from "@material-ui/core"
import Box from "@material-ui/core/Box"
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { fetchSecure } from "../../modules/fetchSecure"
import { InternalFormPage } from "./InternalFormPage"
import { SnackbarMessage } from "./SnackbarMessage"
import LoadingScreen from "../loading/LoadingScreen"

export const InternalListComponent = ({columns, fetchUrl, editInitialState, editBaseSaveUrl, editFields, functionOnResult = undefined, readonly=false}) => {
    const [t] = useTranslation('common')
    const [objectsList, setObjectsList] = useState([])
    const [loading, setLoading] = useState(true)
    const [isInError, setIsInError] = useState(false)
    const theme = useTheme()

    const [selectedId, setSelectedId] = useState(0)
    const [openCreationModal, setOpenCreationModal] = useState(false)

    const [selectedIdForDeletion, setSelectedIdForDeletion] = useState(0)
    const [openDeletionModal, setOpenDeletionModal] = useState(false)

    const fillObjectsList = async () => {
        const response = await fetchSecure(fetchUrl)
        const responseJson = await response.json()

        if(response.ok) {
            if(functionOnResult) {
                await functionOnResult(responseJson)
            }
            setObjectsList(responseJson)
            setLoading(false)
        } else {
            setIsInError(true)
            setLoading(false)
        }
    }

    useEffect(() => {
        fillObjectsList()

    }, [fetchUrl]);

    const fieldByString = (o, s) => {
        s = s.replace(/\[(\w+)]/g, '.$1')
        s = s.replace(/^\./, '')
        const a = s.split('.')
        for (let i = 0, n = a.length; i < n; ++i) {
            let k = a[i]
            if (k in o) {
                o = o[k]
            } else {
                return
            }
        }
        return o
    }

    if(loading) {
        return <Box style={{display: 'flex', height: '20vh', flexDirection: 'column'}}>
            <LoadingScreen />
        </Box>
    }

    return <Box><TableContainer >
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell
                            key={column.id}
                            align={column.align}
                            style={{minWidth: column.minWidth, maxWidth: column.maxWidth }}
                        >
                            {column.label??t(column.id.split(".")[0])}
                        </TableCell>
                    ))}
                    {editBaseSaveUrl && !readonly &&
                    <><TableCell>
                        {t('editItem')}
                    </TableCell>
                        <TableCell>
                        {t('deleteItem')}
                        </TableCell></>
                    }

                </TableRow>
            </TableHead>
            <TableBody>
                {objectsList.map((row) => {
                    return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                            {columns.map((column) => {
                                try {
                                    const value = fieldByString(row, column.id);
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                            {column.type === 'boolean'? t(value): column.format ? column.format(value) : value}
                                        </TableCell>
                                    );
                                } catch (e) {
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                        </TableCell>
                                    )
                                }

                            })}
                            {

                                editBaseSaveUrl && !readonly && <TableCell>
                                    <Button variant="outlined" color="primary" onClick={() => {setSelectedId(row.id)
                                        setOpenCreationModal(true)}} ><EditIcon /></Button>
                                </TableCell>
                            }
                            {

                                editBaseSaveUrl && !readonly && <TableCell>
                                    <Button variant="outlined" style={{color: theme.palette.text.error}} onClick={() => {

                                        setSelectedIdForDeletion(row.id)
                                        setOpenDeletionModal(true)}} ><DeleteIcon /></Button>
                                </TableCell>
                            }
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    </TableContainer>
        <SnackbarMessage open={isInError} handleClose={() => {setIsInError(false)}} severity={'error'} message={'Ocurrió un error al listar objetos'} />

        <Button style={{marginTop: theme.spacing(2)}}
                onClick={() => {setSelectedId(null)
                        setOpenCreationModal(true)}}
                variant={'outlined'} color={'primary'}>{t('button.add')}</Button>
        <CreationModal open={openCreationModal} onClose={() => {setOpenCreationModal(false)}} objectId={selectedId}
                       initialState={editInitialState} baseSaveUrl={editBaseSaveUrl} fields={editFields} onSaveSuccess={fillObjectsList}
        />
        <DeletionModal open={openDeletionModal} onClose={() => {setOpenDeletionModal(false)}} objectId={selectedIdForDeletion}
                       deletionUrl={editBaseSaveUrl} onDeleteSuccess={fillObjectsList}
        />
    </Box>

}


const CreationModal = ({open, onClose, initialState, objectId, baseSaveUrl, fields, onSaveSuccess}) => {
    const theme = useTheme()

    return <Modal open={open} onClose={onClose} tyle={{ alignItems: "center", justifyContent: "center", display:'flex' }}>
        <Box style={{backgroundColor: 'white', display:'flex', justifyContent: 'center', marginRight: '20%', marginLeft: '20%',
        marginTop: '10%', borderRadius: '8px', padding: theme.spacing(2)}}>
        <InternalFormPage initialState={initialState} objectId={objectId} baseSaveUrl={baseSaveUrl} fields={fields} onSaveSuccess={onSaveSuccess} />
        </Box>
    </Modal>
}


const DeletionModal = ({open, onClose,objectId,deletionUrl, onDeleteSuccess}) => {

    const [isInErrorState, setIsInErrorState] = useState(false)
    const [wasDeletedSuccessfully, setWasDeletedSuccessfully] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const onDelete = async () => {

        const method = 'DELETE'
        const url = deletionUrl + '/' + objectId

        const requestOptions = {
            method: method,
            headers: { 'Content-Type': 'application/json' }
        }

        try{
            const response = await fetchSecure(url, requestOptions)
            const responseJson = await response.json()
            if (response.ok) {
                setWasDeletedSuccessfully(true)
                setTimeout(() => {
                    setWasDeletedSuccessfully(false)
                }, 3000)
                onClose()
                await onDeleteSuccess()


            } else {
                const responseErrorMessage = (responseJson && responseJson.message) || response.status
                setErrorMessage(responseErrorMessage)
                setIsInErrorState(true)
                setTimeout(() => {
                    setIsInErrorState(false)
                }, 3000)
            }
        } catch(error) {
            setErrorMessage(error)
            setIsInErrorState(true)
            setTimeout(() => {
                setIsInErrorState(false)
            }, 3000)
        }

    }
    const theme = useTheme()

    return <><Modal open={open} onClose={onClose} tyle={{ alignItems: "center", justifyContent: "center", display:'flex' }}>
        <Box style={{backgroundColor: 'white', display:'flex', alignItems: 'center', flexDirection:'column',justifyContent: 'center', marginRight: '20%', marginLeft: '20%',
            marginTop: '10%', borderRadius: '8px', padding: theme.spacing(4)}}>
            <Typography sx={{mb: 2}}>¿Está seguro que desea eliminar el objeto?</Typography>
            <Box style={{display:'flex', justifyContent: 'center', marginTop: theme.spacing(2)}}>
                <Button color={'primary'} variant={'outlined'} onClick={onClose}>No</Button>
                <Button style={{marginLeft: theme.spacing(2), backgroundColor: theme.palette.text.error, color: '#FFFFFF'}} variant={'contained'} onClick={onDelete}>Sí, eliminar</Button>
            </Box>
        </Box>
    </Modal>

        <SnackbarMessage open={isInErrorState} handleClose={() => {setIsInErrorState(false)}} severity={'error'} message={'Ha ocurrido un error inesperado al intentar borrar. '+errorMessage} />

        <SnackbarMessage open={wasDeletedSuccessfully} handleClose={() => {setWasDeletedSuccessfully(false)}} severity={'success'} message={'El objeto se ha eliminado con éxito'} />

    </>
}


