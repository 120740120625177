import InputField from '../../../components/inputField/InputField.js';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import {Button, FormGroup} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import React, {Fragment, useCallback, useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import {useTranslation} from "react-i18next";
import SelectField from "../../../components/selectField/SelectField";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import {Link, useHistory} from "react-router-dom";
import {fetchSecure} from "../../../modules/fetchSecure";
import ListComponent from "../../../components/listComponent/ListComponent";
import InputTextAreaField from "../../../components/inputField/InputTextAreaField";
import DateField from "../../../components/dateField/DateField";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";


function ProductItem(props) {

    const [product, setProduct] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [savedStatus, setSavedStatus] = useState({saved: false, error: false});
    const [errors, setErrors] = useState({})
    const [show, setShow] = useState(props.match.params.objectId === null)
    const [existingImage, setExistingImage] = useState(undefined)
    const [existingImageName, setExistingImageName] = useState(undefined)
    const [existingImage2, setExistingImage2] = useState(undefined)
    const [existingImageName2, setExistingImageName2] = useState(undefined)
    const [existingImage3, setExistingImage3] = useState(undefined)
    const [existingImageName3, setExistingImageName3] = useState(undefined)
    const [existingImage4, setExistingImage4] = useState(undefined)
    const [existingImageName4, setExistingImageName4] = useState(undefined)
    const handleShow = e => {
        setShow(e.target.checked)
    }
    const [t] = useTranslation('common');
    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {},
    });
    const classes = useStyles();

    const handleListChange = (listName, listValue) => {
        let newProduct = {...product}
        newProduct[listName] = listValue;
        setProduct(newProduct);
    }

    const handleChange = e => {
        let newProduct = {...product}
        newProduct[e.target.name] = e.target.value;
        setProduct(newProduct);
    }

    const handleImageSave = async (localProduct, field, imageTokenField) => {
        if (localProduct[field]) {
            const putFileResponse = await fetchSecure(window.REACT_APP_TOKENIZATION_URL + '/file', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({fileName: localProduct[field].name})
            })
            const putFileResponseBody = await putFileResponse.json()

            const uploadFileResponse = await fetch(putFileResponseBody.uploadUrl, {
                method: 'PUT',
                body: localProduct[field]
            })
            if (!uploadFileResponse.ok) {
                setSavedStatus({
                    saved: false,
                    error: true,
                    errorMessage: 'No se pudo subir la imagen, por favor reintentar'
                })
                return
            }
            localProduct[imageTokenField] = putFileResponseBody.token
        }
    }


    const handleSave = async () => {
        let method = 'POST';
        let url = window.REACT_APP_BASE_API_URL + '/product';
        if (props.match.params.objectId != null) {
            method = 'PUT';
            url = url + "/" + props.match.params.objectId
        } else {
            product.version = 0;
        }

        const productToSave = {...product}

        await Promise.all([handleImageSave(productToSave, 'selectedImage', 'imageToken'),
            handleImageSave(productToSave, 'selectedImage2', 'imageToken2'),
            handleImageSave(productToSave, 'selectedImage3', 'imageToken3'),
            handleImageSave(productToSave, 'selectedImage4', 'imageToken4')])


        let productInJson = JSON.stringify(productToSave);
        const requestOptions = {
            method: method,
            headers: {'Content-Type': 'application/json'},
            body: productInJson
        };

        try {
            const response = await fetchSecure(url, requestOptions)
            const data = await response.json();
            if (!response.ok) {

                setErrors(data.fields == null ? {} : data.fields)
                const errorMessage = (data && data.message) || response.status;
                return Promise.reject(errorMessage);
            } else {
                setErrors({})
                props.match.params.objectId = data.id
                setSavedStatus({saved: true, error: false})
            }
        } catch (errorMessage) {
            setSavedStatus({saved: false, error: true, errorMessage: errorMessage})
        }


    }

    const fetchImage = async (data, field, setCurrentExistingImage, setCurrentExistingImageName) => {
        if (data[field]) {
            const getFileResponse = await fetchSecure(window.REACT_APP_TOKENIZATION_URL + '/file/' + data[field], {
                method: 'GET',
                headers: {'Content-Type': 'application/json'}
            })
            const getFileResponseBody = await getFileResponse.json()

            const downloadFileResponse = await fetch(getFileResponseBody.downloadUrl, {method: 'GET'})
            if (!downloadFileResponse.ok) {
                setSavedStatus({saved: false, error: true, errorMessage: 'No se pudo descargar la imagen del producto'})
            } else {
                const fileBlob = await downloadFileResponse.blob()
                const reader = new FileReader();
                reader.readAsDataURL(fileBlob);
                reader.onloadend = () => {
                    const base64data = reader.result
                    setCurrentExistingImage(base64data)
                    setCurrentExistingImageName("image")
                }

            }


        }
    }


    const fetchProduct = useCallback(async (id) => {
        const response = await fetchSecure(window.REACT_APP_BASE_API_URL + '/product/' + id)
        const data = await response.json()
        setProduct(data)
        setLoaded(true)

        fetchImage(data, 'imageToken', setExistingImage, setExistingImageName).then(() => {
        })
        fetchImage(data, 'imageToken2', setExistingImage2, setExistingImageName2).then(() => {
        })
        fetchImage(data, 'imageToken3', setExistingImage3, setExistingImageName3).then(() => {
        })
        fetchImage(data, 'imageToken4', setExistingImage4, setExistingImageName4).then(() => {
        })


    }, [])

    useEffect(() => {
        if (Object.keys(errors).length === 0 && props.match.params.objectId != null) {
            fetchProduct(props.match.params.objectId).then(() => {
            })
        }
    }, [errors, loaded, props.match.params.objectId, savedStatus, fetchProduct]);

    let history = useHistory()
    const goToPreviousPath = () => {
        history.goBack()
    }

    const codePerCustomerList =
        {
            "elements": [
                {
                    "type": "textField",
                    "typeTextField": "text",
                    "id": "code",
                    "fieldLabel": "Codigo Cliente",
                    "disabled": show
                },
                {
                    "type": "textField",
                    "typeTextField": "text",
                    "id": "customerCodeProforma",
                    "fieldLabel": "Codigo de barra",
                    "disabled": show
                },
                {
                    "type": "select",
                    "typeTextField": "select",
                    "id": "customer",
                    "label": "Cliente",
                    "optionValue": "id",
                    "optionShow": "name",
                    "url": window.REACT_APP_BASE_API_URL + '/customer',
                    "disabled": show
                }
            ]
        }

    const productSupplierList =
        {
            "elements": [
                {
                    "type": "textField",
                    "typeTextField": "text",
                    "id": "code",
                    "fieldLabel": "Codigo proveedor",
                    "disabled": show
                },
                {
                    "type": "select",
                    "typeTextField": "select",
                    "id": "supplier",
                    "label": "Proveedor",
                    "optionValue": "id",
                    "optionShow": "name",
                    "url": window.REACT_APP_BASE_API_URL + '/supplier',
                    "disabled": show
                },
                {
                    "type": "select",
                    "typeTextField": "select",
                    "id": "currency",
                    "optionValue": "id",
                    "optionShow": "name",
                    "url": window.REACT_APP_BASE_API_URL + '/currency',
                    "disabled": show
                },
                {
                    "type": "select",
                    "typeTextField": "select",
                    "id": "priceCondition",
                    "optionValue": "id",
                    "optionShow": "name",
                    "url": window.REACT_APP_BASE_API_URL + '/priceCondition',
                    "disabled": show
                },
                {
                    "type": "select",
                    "typeTextField": "select",
                    "id": "unit",
                    "optionValue": "id",
                    "optionShow": "description",
                    "url": window.REACT_APP_BASE_API_URL + '/itemUnit',
                    "disabled": show
                },
                {
                    "type": "select",
                    "typeTextField": "select",
                    "id": "color",
                    "optionValue": "id",
                    "optionShow": "description",
                    "url": window.REACT_APP_BASE_API_URL + '/color',
                    "disabled": show
                },
                {
                    "type": "select",
                    "typeTextField": "select",
                    "id": "productType",
                    "optionValue": "id",
                    "optionShow": "description",
                    "url": window.REACT_APP_BASE_API_URL + '/productType',
                    "disabled": show
                },
                {
                    "type": "textField",
                    "typeTextField": "number",
                    "id": "unitPrice",
                    "label": "Precio x unidad",
                    "disabled": show
                },
                {"type": "dateField", "id": "lastPriceDate", "label": "lastPriceDate", "disabled": show},

                {"type": "textField", "typeTextField": "number", "id": "length", "disabled": show},
                {
                    "type": "textField",
                    "typeTextField": "number",
                    "id": "width",
                    "disabled": show
                },
                {"type": "textField", "typeTextField": "number", "id": "height", "disabled": show},
                {
                    "type": "textField",
                    "typeTextField": "number",
                    "id": "quantityArtByExternalBox",
                    "disabled": show
                },
                {
                    "type": "textField",
                    "typeTextField": "number",
                    "id": "quantityPallets",
                    "disabled": show
                },
                {
                    "type": "textField",
                    "typeTextField": "number",
                    "id": "netWeightPerBox",
                    "disabled": show
                },
                {
                    "type": "textField",
                    "typeTextField": "number",
                    "id": "grossWeightPerBox",
                    "disabled": show
                },
                {
                    "type": "textField",
                    "typeTextField": "number",
                    "id": "sectionWeight",
                    "disabled": show
                },
                {
                    "type": "textField",
                    "typeTextField": "number",
                    "id": "sectionHeight",
                    "label": "sectionHeight",
                    "disabled": show
                },
                {
                    "type": "textField",
                    "typeTextField": "number",
                    "id": "quantityProfilesSection",
                    "label": "quantityProfilesSection",
                    "disabled": show
                },
                {
                    "type": "textField",
                    "typeTextField": "number",
                    "id": "quantityPerBag",
                    "label": "quantityPerBag",
                    "disabled": show
                },
                {
                    "type": "textField",
                    "typeTextField": "number",
                    "id": "unitRealPrice",
                    "label": "unitRealPrice",
                    "disabled": show
                },
            ]
        }


    return (
        <Paper className={classes.root}>
            <Box m={2}>
                <Button variant="contained" color="primary" onClick={goToPreviousPath}>Listado</Button>
            </Box>
            {savedStatus.error && <Alert severity="error">
                Ha ocurrido un error inesperado al intentar grabar el producto
                {Object.keys(errors).map((it) =>
                    <Fragment>
                        <li>
                            {errors[it]}
                        </li>
                    </Fragment>
                )}
            </Alert>
            }

            {savedStatus.saved && <Alert severity="success">El producto se ha grabado con éxito</Alert>}

            {props.match.params.objectId != null &&
            <Box m={2}>
                <Grid container spacing={3}>
                    <Grid item xs={9}>
                    </Grid>
                    <Grid item xs={2}>
                        {t('button.show')} <Switch defaultChecked={show} color="primary" onClick={handleShow}/>
                    </Grid>
                </Grid>
            </Box>
            }

            <Box m={2}>
                <form className={classes.container} noValidate>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h6">Información</Typography>
                            <FormGroup row={true}>
                                <SelectField disabled={show} error={!!errors.family} name="family" label="family"
                                             value={product.family} changeHandler={handleListChange}
                                             className={classes.textField}
                                             url={window.REACT_APP_BASE_API_URL + '/family'} optionValue="id"
                                             optionShow="description"/>

                            </FormGroup>
                        </CardContent>
                    </Card>


                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h6">Atributos</Typography>
                            <FormGroup row={true}>
                                <InputTextAreaField disabled={show} error={!!errors.descriptionSP} name="descriptionSP"
                                                    label="descriptionSP" value={product.descriptionSP} type="text"
                                                    onChange={handleChange} className={classes.textField}/>
                                <InputTextAreaField disabled={show} error={!!errors.descriptionEN} name="descriptionEN"
                                                    label="descriptionEN" value={product.descriptionEN} type="text"
                                                    onChange={handleChange} className={classes.textField}/>
                                <InputField disabled={true} error={!!errors.customerCode} name="customerCode"
                                            label="Número de BL" value={product.customerCode} type="text"
                                            onChange={handleChange} className={classes.textField}/>
                                <SelectField disabled={show} error={!!errors.positionArancel} name="positionArancel"
                                             label="positionArancel" value={product.positionArancel}
                                             changeHandler={handleListChange} className={classes.textField}
                                             url={window.REACT_APP_BASE_API_URL + '/positionArancel'} optionValue="id"
                                             optionShow="positionArancelId"/>
                                <InputTextAreaField error={!!errors.expertClassification} fullWidth={true}
                                                    key="expertClassification" name="expertClassification"
                                                    label="expertClassification" disabled={show} className="text"
                                                    onChange={handleChange} value={product.expertClassification}/>
                                <DateField error={!!errors.dateClassification} key="dateClassification" disabled={show}
                                           name="dateClassification" label="dateClassification" className="text"
                                           onChange={handleListChange} value={product.dateClassification}/>
                            </FormGroup>
                            <FormGroup row={true}>
                                {product.id && <>
                                    <ProductImageComponent existingImageName={existingImageName}
                                                           existingImage={existingImage}
                                                           selectedImage={product.selectedImage} onChange={(event) => {
                                        setProduct((product) => {
                                            return {...product, selectedImage: event.target?.files?.[0]}
                                        })
                                    }}/>
                                    <ProductImageComponent existingImageName={existingImageName2}
                                                           existingImage={existingImage2}
                                                           selectedImage={product.selectedImage2} onChange={(event) => {
                                        setProduct((product) => {
                                            return {...product, selectedImage2: event.target?.files?.[0]}
                                        })
                                    }}/>
                                    <ProductImageComponent existingImageName={existingImageName3}
                                                           existingImage={existingImage3}
                                                           selectedImage={product.selectedImage3} onChange={(event) => {
                                        setProduct((product) => {
                                            return {...product, selectedImage3: event.target?.files?.[0]}
                                        })
                                    }}/>
                                    <ProductImageComponent existingImageName={existingImageName4}
                                                           existingImage={existingImage4}
                                                           selectedImage={product.selectedImage4} onChange={(event) => {
                                        setProduct((product) => {
                                            return {...product, selectedImage4: event.target?.files?.[0]}
                                        })
                                    }}/>
                                </>
                                }
                            </FormGroup>
                        </CardContent>
                    </Card>

                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h6">Clientes</Typography>
                            <FormGroup row={true}>
                                <ListComponent disabled={show} errorsList={errors} onChangeList={handleListChange}
                                               elementToRender={codePerCustomerList}
                                               elementToUpload={product.codePerCustomerList}
                                               listName={"codePerCustomerList"}/>
                            </FormGroup>
                        </CardContent>
                    </Card>

                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h6">Proveedores</Typography>
                            <FormGroup row={true}>
                                <ListComponent disabled={show} errorsList={errors} onChangeList={handleListChange}
                                               elementToRender={productSupplierList}
                                               elementToUpload={product.productSupplierList}
                                               listName={"productSupplierList"}/>
                            </FormGroup>
                            {product?.subWishList && product?.subWishList?.length > 0 &&
                            <Typography variant="h6">Ultimos 2 pedidos</Typography>
                            }
                            <FormGroup row={true}>
                                {product?.subWishList && product?.subWishList?.length > 0 && product?.subWishList?.slice(0, 2)?.map((element) => {
                                    return <Box style={{display: 'flex', flexDirection: 'row', marginBottom: '16px'}}>
                                        <InputField style={{marginRight: '16px'}} disabled={true} name="opNumber"
                                                    label="Número de BL" value={element.opNumber} type="text"
                                                    onChange={handleChange} className={classes.textField}/>
                                        <DateField style={{marginRight: '16px', marginLeft: '16px'}} disabled={true}
                                                   name="proformaDate" value={element.proformaDate}
                                                   onChange={handleListChange} className={classes.textField}/>
                                        <InputField disabled={true} name="unitPrice" label="Precio"
                                                    value={element.unitPrice} type="text" onChange={handleChange}
                                                    className={classes.textField}/>
                                        <Link to={`/wish/edit/${element.wishId}/null`}>
                                            <Typography> Ir al pedido</Typography>
                                        </Link>
                                    </Box>
                                })}
                            </FormGroup>
                        </CardContent>
                    </Card>


                    {(props.match.params.readonly == null || props.match.params.readonly === 'false') && <CardActions>
                        <FormGroup row={true}>
                            <Box m={2}>
                                <Button variant="contained" color="primary" onClick={handleSave}>Guardar</Button>
                            </Box>
                        </FormGroup>
                    </CardActions>
                    }
                </form>
            </Box>

        </Paper>
    )
}


const ProductImageComponent = ({existingImage, existingImageName, selectedImage, onChange}) => {

    const [preview, setPreview] = useState()

    useEffect(() => {
        if (!selectedImage) {
            setPreview(existingImage)
            return
        }

        const objectUrl = URL.createObjectURL(selectedImage)
        setPreview(objectUrl)

        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedImage, existingImage])

    return <Box display={'flex'}><Box mr={1}><Button variant="contained" component="label" color="primary">
        Seleccionar imagen
        <input type="file" hidden onChange={onChange}/>

    </Button>

    </Box>
        {preview && <Box display={'flex'}><img src={preview} alt="preview" height={'128px'}/>
            <Box display={'flex'}><Button onClick={() => {
                const downloadLink = document.createElement("a")
                downloadLink.href = selectedImage ? preview : existingImage
                downloadLink.download = selectedImage ? selectedImage.name : existingImageName
                downloadLink.click()


            }}><CloudDownloadIcon/></Button></Box>
        </Box>}
    </Box>

}


export default ProductItem
