import {Box} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import {CostOptimizationService} from "../../services/CostOptimizationService";
import {PreOrderService} from "../../services/PreOrderService";
import {CostCalculatorService} from "../../services/CostCalculatorService";
import {fetchSecureJson} from "../../modules/fetchSecureJson";
import {LoadingIndicator} from "../../components/atoms/LoadingIndicator";
import SelectField from "../../components/selectField/SelectField";
import InputField from "../../components/inputField/InputField";
import {useTranslation} from "react-i18next";
import {formatNumber} from "../../lib/formatNumber";
import {Button, Typography} from "@mui/material";
import {exportToExcel, JsonToExcel} from "react-json-to-excel";

const preOrderService = new PreOrderService()
const costOptimizationService = new CostOptimizationService()
const costCalculatorService = new CostCalculatorService()

const fields = [
    "totalFob",
    "certifiedFreight",
    "insurance",
    "adjustmentAmount",
    "taxBase",
    "rights",
    "statisticalRate",
    "baseForTaxes",
    "iva",
    "ivaPerception",
    "incomeTaxAdvancement",
    "iibbPerception",
    "customsCost",
    "customsFiscalCredit",
    "freight",
    "agencyExpenses",
    "dispatchDigitalization",
    "portExpenses",
    "bankExpenses",
    "customsBrokerFee",
    "dispatchExpenses",
    "guaranteesConstitution",
    "companyFee",
    "haulage"
]

const calculatedCostPerItemFields = [
    "productCode",
    "description",
    "quantity",
    "price",
    "totalFob",
    "unitRealCost",
    "unitFiscalCredit",
    "unitFinancialCost",
    "idealContainerName",
    "idealContainerQuantity",
    "unitRealCostPerFullContainer",
    "unitFiscalCreditPerFullContainer",
    "unitFinancialCostPerFullContainer"

]

export const PreOrderCostInput = (props) => {

    const [t] = useTranslation('common')

    const {objectId, forwarderId, portId} = props.match.params


    const [forwarder, setForwarder] = useState(null)
    const [port, setPort] = useState(null)
    const [preOrderItemsResult, setPreOrderItemsResult] = useState(null)
    const [optimizationResult, setOptimizationResult] = useState(null)

    const [customsBrokerList, setCustomsBrokerList] = useState([])
    const [customsBroker, setCustomsBroker] = useState(null)
    const [adjustmentAmount, setAdjustmentAmount] = useState(0)

    const [calculatedCost, setCalculatedCost] = useState(null)
    const [calculatedCostPerItem, setCalculatedCostPerItem] = useState(null)

    const [, setPreOrder] = useState({})
    const [loading, setLoading] = useState(true)


    useEffect(() => {

        Promise.all([
                fetchSecureJson(window.REACT_APP_BASE_API_URL + '/forwarder/' + forwarderId),
                fetchSecureJson(window.REACT_APP_BASE_API_URL + '/portTerminal/' + portId),
                preOrderService.getPreOrderItems(objectId),
                fetchSecureJson(window.REACT_APP_BASE_API_URL + '/customsBroker'),
                preOrderService.getPreOrder(objectId)
            ]
        ).then(([newForwarder, newPort, newPreOrderItemsResult, newCustomsBrokerList,
                    newPreOrder]) => {
            setForwarder(newForwarder)
            setPort(newPort)
            setPreOrderItemsResult(newPreOrderItemsResult)
            setCustomsBrokerList(newCustomsBrokerList)
            setPreOrder(newPreOrder)
            setLoading(false)

        })

    }, [forwarderId, objectId, portId])

    useEffect(() => {
        if (preOrderItemsResult && forwarder && port) {
            costOptimizationService.optimize(preOrderItemsResult.items, forwarder, port).then(
                newOptimizationResult => {
                    setOptimizationResult(newOptimizationResult)
                }
            )
        }

    }, [preOrderItemsResult, forwarder, port])

    useEffect(() => {
        if (optimizationResult && forwarder && port && customsBroker && preOrderItemsResult && customsBrokerList) {
            const customsBrokerWithAllFields = customsBrokerList.find(currentCustomsBroker => currentCustomsBroker.id === customsBroker.id)

            costCalculatorService.calculateCost(preOrderItemsResult, forwarder, port, optimizationResult, customsBrokerWithAllFields, adjustmentAmount).then(
                newCalculatedCost => {
                    setCalculatedCost(newCalculatedCost.calculatedCostSummary)
                    setCalculatedCostPerItem(newCalculatedCost.calculatedCostPerItem)
                }
            )
        }
    }, [optimizationResult, forwarder, port, customsBroker, adjustmentAmount, preOrderItemsResult, customsBrokerList])


    if (loading) {
        return <LoadingIndicator/>
    }


    const summaryToDownload = calculatedCost ? Object.keys(calculatedCost).filter(key => key !== 'errors').map(key => {
        return {
            'Concepto': t('cost.' + key),
            'Valor': formatNumber(calculatedCost[key])
        }
    }) : []


    const detailToDownload = calculatedCostPerItem
        ? calculatedCostPerItem.map(item => {
            let finalItem = {};

            calculatedCostPerItemFields.forEach(field => {
                let fieldValue = item[field];
                if (typeof fieldValue !== 'number' || isNaN(fieldValue)) {
                    finalItem[t('calculatedCostPerItem.' + field)] = fieldValue;
                } else {
                    finalItem[t('calculatedCostPerItem.' + field)] = formatNumber(fieldValue, 2);
                }
            });

            return finalItem
        })
        : []


    return <Box display={'row'} flexDirection={'column'} ml={2} mr={1} mt={4}>

        <Box display={'flex'} flexDirection={'row'}>

            <SelectField name="customsBroker" value={customsBroker} changeHandler={(_, value) => {
                setCustomsBroker(value)
            }} data={customsBrokerList} optionValue="id" optionShow="name"/>
            <InputField name="adjustmentAmount" label="Ajuste" value={adjustmentAmount} type="number"
                        onChange={(event) => {
                            setAdjustmentAmount(event.target.value)
                        }}/>


        </Box>
        {!calculatedCost && <Box display={'flex'} flexDirection={'row'} mt={2}>
            <p>Debe seleccionar un despachante y un ajuste</p>
        </Box>}
        {calculatedCost && calculatedCostPerItem && <Box display={'flex'} flexDirection={'row'} sx={{mt: 2}}>
            <Box display={'flex'} flexDirection={'column'}>
                <Box display={'flex'} flexDirection={'row'}>
                    <table>
                        <thead>
                        <tr>
                            <th>Concepto</th>
                            <th>Valor</th>
                        </tr>
                        </thead>
                        <tbody>
                        {fields.map(field => {
                            return <tr key={field}>
                                <td>{t('cost.' + field)}</td>
                                <td style={{
                                    paddingLeft: '16px',
                                    textAlign: 'right'
                                }}>{formatNumber(calculatedCost[field], 2)}</td>
                            </tr>

                        })}
                        </tbody>

                    </table>
                    <Box display={'flex'} flexDirection={'column'} ml={2}>
                        <JsonToExcel
                            title="Descargar resumen"
                            data={summaryToDownload}
                            fileName="resumen"
                        />
                        <Box mt={1}>
                            <JsonToExcel
                                title="Descargar detalle"
                                data={detailToDownload}
                                fileName="detalle"
                            />
                        </Box>
                        <Box mt={1}>
                            <Button variant="contained" color="primary" onClick={() => exportToExcel([{
                                sheetName: "resumen",
                                details: summaryToDownload
                            }, {
                                sheetName: "detalle",
                                details: detailToDownload
                            }], 'resumen_y_detalle', true)}>
                                Descargar todo
                            </Button>

                        </Box>
                    </Box>
                </Box>
                <Box display={'flex'} flexDirection={'row'} sx={{mt: 2}}>
                    <table>
                        <thead>
                        <tr>
                            {calculatedCostPerItemFields.map(field => {
                                return <th key={field}>{t('calculatedCostPerItem.' + field)}</th>
                            })
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {calculatedCostPerItem.map(item => {
                            return <tr key={item.id}>
                                {calculatedCostPerItemFields.map(field => {
                                    const fieldValue = item[field]
                                    if (typeof fieldValue == 'string') {
                                        return <td key={field} style={{
                                            paddingLeft: '16px',
                                            textAlign: 'right'
                                        }}>{fieldValue}</td>

                                    } else if (isNaN(fieldValue)) {
                                        return <td key={field}>{fieldValue}</td>

                                    } else {
                                        return <td key={field} style={{
                                            paddingLeft: '16px',
                                            textAlign: 'right'
                                        }}>{formatNumber(fieldValue, 2)}</td>

                                    }
                                })}
                            </tr>
                        })}
                        </tbody>

                    </table>
                </Box>
            </Box>
            <Box sx={{ml: 4, mt: 4}}>
                {calculatedCost.errors.map(error => {
                    return <Typography key={error} color={'red'}>{error}</Typography>
                })}

            </Box>
        </Box>


        }

    </Box>
}

