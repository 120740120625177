export class CostOptimizationService {

    getContainers(forwarder, portTerminal) {
        const containers = {}

        for (const portTerminalContainer of portTerminal.containers) {

            const forwarderContainer = forwarder.containers.find(fc => fc.container.id === portTerminalContainer.container.id)

            if (!forwarderContainer.available) {
                continue
            }

            const forwarderContainerPrice = (forwarderContainer?.freight ?? 0) +
                (forwarderContainer?.maritimeExpenses ?? 0) +
                (forwarderContainer?.insurance ?? 0) +
                (forwarderContainer?.certifiedFreight ?? 0)


            containers[portTerminalContainer.container.name] = {
                "weight": portTerminalContainer.container.weight,
                "volume": portTerminalContainer.container.volume,
                "price": portTerminalContainer.priceTerminal + (forwarderContainerPrice ?? 0)
            }

        }
        return containers
    }

    getIdQuantityWeightVolume(item) {
        const productSupplier = item.product.productSupplierList.filter(ps => ps.supplier.id === item.supplierId)[0]

        return {
            'id': item.id,
            'quantity': item.quantity,
            'weight': productSupplier?.grossWeightPerBox / productSupplier?.quantityArtByExternalBox,
            'volume': productSupplier?.height * productSupplier?.width * productSupplier?.length / 1000000000 / productSupplier?.quantityArtByExternalBox
        }
    }

    getIdealContainer(item, forwarder, portTerminal) {
        const containers = this.getContainers(forwarder, portTerminal)
        const {weight, volume} = this.getIdQuantityWeightVolume(item)

        if (Object.keys(containers).length === 0) {
            return null;
        }

        let optimalContainerName = null;
        let optimalContainer = null;
        let minPricePerItem = Infinity;
        let maxItemsFit = 0;

        for (let containerName in containers) {
            const container = containers[containerName];
            const canFitByWeight = Math.floor(container.weight / weight);
            const canFitByVolume = Math.floor(container.volume / volume);
            const canFitCount = Math.min(canFitByWeight, canFitByVolume);

            const pricePerItem = container.price / canFitCount;

            if (pricePerItem < minPricePerItem) {
                minPricePerItem = pricePerItem;
                optimalContainerName = containerName;
                optimalContainer = container;
                maxItemsFit = canFitCount;
            }
        }

        return {
            containerName: optimalContainerName,
            container: optimalContainer,
            pricePerItem: minPricePerItem,
            quantity: maxItemsFit
        }

    }

    async optimize(preOrderItemList, forwarder, portTerminal) {
        const containers = this.getContainers(forwarder, portTerminal)

        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': window.REACT_APP_OPTIMIZATION_API_KEY
            },
            body: JSON.stringify({
                    'products': preOrderItemList.map(item => {
                        return this.getIdQuantityWeightVolume(item)

                    }),
                    containers
                }
            )
        }

        const optimizationResultFetched = await fetch(window.REACT_APP_OPTIMIZATION_URL + '/optimize', options)
        return {result: await optimizationResultFetched.json(), containers}


    }
}
