import InputField from '../../../components/inputField/InputField.js';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import { Button, FormGroup } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from "react-i18next";
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import {useHistory} from "react-router-dom";
import {fetchSecure} from "../../../modules/fetchSecure";


function PriceConditionItem(props) {

    const [priceCondition, setPriceCondition] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [savedStatus, setSavedStatus] = useState({ saved: false, error: false });
    const [errors, setErrors] = useState({})
    const [t] = useTranslation('common');
    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {
        },
    });
    const classes = useStyles();

    const handleChange = e => {
        let newPriceCondition = { ...priceCondition }
        newPriceCondition[e.target.name] = e.target.value;
        setPriceCondition(newPriceCondition);
    }

    const [show, setShow] = useState(props.match.params.objectId != null)
    const handleShow = e => {
        setShow(e.target.checked)
    }


    const handleSave = () => {
        let method = 'POST';
        let url = window.REACT_APP_BASE_API_URL + '/priceCondition';
        if (props.match.params.objectId != null) {
            method = 'PUT';
            url = url + "/" + props.match.params.objectId
        } else {
            priceCondition.version = 0;
        }
        let priceConditionInJson = JSON.stringify(priceCondition);
        const requestOptions = {
            method: method,
            headers: { 'Content-Type': 'application/json' },
            body: priceConditionInJson
        };


        fetchSecure(url, requestOptions)
            .then(async response => {
                const data = await response.json();
                if (!response.ok) {

                    setErrors(data.fields == null ? {} : data.fields)
                    const errorMessage = (data && data.message) || response.status;
                    return Promise.reject(errorMessage);
                } else {
                    setErrors({})
                    props.match.params.objectId = data.id
                    setSavedStatus({ saved: true, error: false })
                }
            }
            ).catch(errorMessage => {
                setSavedStatus({ saved: false, error: true, errorMessage: errorMessage })
            });

    }


    useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (props.match.params.objectId != null) {
                fetchSecure(window.REACT_APP_BASE_API_URL + '/priceCondition/' + props.match.params.objectId)
                    .then(response => response.json())
                    .then(data => setPriceCondition(data))
                    .then(() => setLoaded(true));
            }
        }
    }, [errors, loaded, props.match.params.objectId, props.match.params.readonly, savedStatus]);

    let history = useHistory()
    const goToPreviousPath = () => {
        history.goBack()
    }
    return (
        <Paper className={classes.root}>
            <Box m={2} >
                <Button variant="contained" color="primary" onClick={goToPreviousPath}>Listado</Button>
            </Box>
            {savedStatus.error && <Alert severity="error">
                Ha ocurrido un error inesperado al intentar grabar la condición de estado
					{Object.keys(errors).map((it) =>
                <Fragment>
                    <li>
                        {errors[it]}
                    </li>
                </Fragment>
            )}
            </Alert>
            }

            {savedStatus.saved && <Alert severity="success">La condición de estado se ha grabado con éxito</Alert>
            }

{props.match.params.objectId != null &&
                    <Box m={2} >
                        <Grid container spacing={3}>
                            <Grid item xs={9}>
                            </Grid>
                            <Grid item xs={2}>
                                {t('button.show')} <Switch defaultChecked={show} color="primary" onClick={handleShow}/>
                            </Grid>
                        </Grid>
                    </Box>
                }

            <Box m={2} >
                <form className={classes.container} noValidate>
                <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h6">{t('priceConditionTermInformation')}</Typography>
                            <FormGroup row={true}>
                                <InputField disabled={show} error={!!errors.name} name="name" label="Nombre" value={priceCondition.name} type="text" onChange={handleChange} className={classes.textField} />
                                <InputField disabled={show} error={!!errors.description} name="description" label="Descripcion" value={priceCondition.description} type="text" onChange={handleChange} className={classes.textField} />
                            </FormGroup>
                        </CardContent>
                    </Card>


                    {show === false && <CardActions>
                        <FormGroup row={true}  >
                            <Box m={2} >
                                <Button variant="contained" color="primary" onClick={handleSave}>Guardar</Button>
                            </Box >
                        </FormGroup>
                    </CardActions>
                    }
                </form>
            </Box>

        </Paper >
    )
}

export default PriceConditionItem;
