import React from 'react';
import {Box, TextField} from '@material-ui/core';

import {useTranslation} from "react-i18next";

function InputField({
                        name,
                        value,
                        onChange,
                        type,
                        className,
                        error,
                        disabled,
                        style,
                        InputProps,
                        InputLabelProps,
                        fieldLabel = null
                    }) {

    const [t] = useTranslation('common');

    let currentValue = "";
    if (value != null) {
        currentValue = value;
    }

    return <Box style={{display: 'flex', flexDirection: 'column', marginBottom: '16px', marginRight: '8px'}}>
        <TextField value={currentValue}
                   error={error}
                   onChange={onChange}
                   name={name}
                   id={name}
                   fullWidth
                   disabled={disabled}
                   label={fieldLabel ?? t(name)}
                   type={type}
                   className={className}
                   style={style}
                   InputProps={InputProps}
                   InputLabelProps={{...InputLabelProps, shrink: true}}/>
    </Box>
}

export default InputField
