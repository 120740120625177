import {FormPage} from "../../components/templates/FormPage"
import {Box, Button, useTheme} from "@material-ui/core"
import {SnackbarMessage} from "../../components/atoms/SnackbarMessage"
import {useTranslation} from "react-i18next"
import Typography from "@material-ui/core/Typography"
import {fetchSecure} from "../../modules/fetchSecure"
import Card from "@material-ui/core/Card"
import {InternalListComponent} from "../../components/atoms/InternalListComponent"
import {Link, useHistory} from "react-router-dom";
import {useState} from "react"
import LoadingScreen from "../../components/loading/LoadingScreen"

export const PreOrderItem = (props) => {
    const initialState = {customerId: null, version: 0}
    const objectId = props.match.params.objectId
    const baseSaveUrl = window.REACT_APP_BASE_API_URL + '/preOrder'

    const [currentState, setCurrentState] = useState(undefined)
    const [loadingImport, setLoadingImport] = useState(false)

    const fields = [
        {
            name: 'customerId',
            type: 'SimpleSelectField',
            url: window.REACT_APP_BASE_API_URL + '/customer/withProducts',
            optionValue: 'id',
            optionShow: 'name',
            label: 'Cliente'
        }
    ]


    return <Box display={'flex'} flexDirection={'column'}><FormPage setCurrentState={setCurrentState}
                                                                    initialState={initialState} objectId={objectId}
                                                                    baseSaveUrl={baseSaveUrl} fields={fields}
                                                                    showSaveButtonBelowForm={!currentState?.id}/>
        {currentState?.id && !loadingImport &&
        <PreOrderItemList preOrderId={currentState.id} customerId={currentState.customerId}/>}
        {loadingImport && <Box display={'flex'} justifyContent={'center'}><LoadingScreen/></Box>}

        {currentState?.id && <Box display={'flex'} flexDirection={'row'}>
            <ImportPreOrderItems preOrderId={currentState.id} loadingImport={loadingImport}
                                 setLoadingImport={setLoadingImport}/>
            <ExportPreOrderItems preOrder={currentState}/>
            <GenerateOrders preOrderId={currentState.id}/>
            <Box display={'flex'} ml={1}>
                <Button component={Link} to={'/preOrder/cost/' + currentState.id} color="primary">Cálculo de
                    costos</Button>
            </Box>
        </Box>}
    </Box>

}

const GenerateOrders = ({preOrderId}) => {
    const [t] = useTranslation('common')

    const [loading, setLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const sendFile = async () => {
        setLoading(true)
        setIsSuccess(false)
        setIsError(false)
        try {
            const response = await fetchSecure(window.REACT_APP_BASE_API_URL + '/preOrder/' + preOrderId + '/generateOrders', {
                method: 'POST'

            })

            if (response.ok) {
                const responseJson = await response.json()
                if (responseJson.generated === "true") {
                    setIsSuccess(true)
                } else {
                    setIsError(true)
                    setErrorMessage(responseJson?.errorMessage)
                }
            } else {
                const responseJson = await response.json()
                setErrorMessage(responseJson.message)
                setIsError(true)
            }
            setLoading(false)
        } catch (e) {
            setErrorMessage(e.message)
            setLoading(false)
            setIsError(true)
        }

    }

    return <Box mt={4} ml={2} display={'flex'} flexDirection={'column'}>
        <Box>
            <Typography variant={'h6'}>{t('preOrder.generateOrders')}</Typography>
        </Box>
        <Box mt={1}>
            <Button disabled={loading} onClick={sendFile} color="primary">{t('preOrder.generateOrders')}</Button>
        </Box>
        <SnackbarMessage open={isError} handleClose={() => {
            setIsError(false)
        }} severity={'error'} message={'Ha ocurrido un error al intentar generar los pedidos. ' + errorMessage}/>

        <SnackbarMessage open={isSuccess} handleClose={() => {
            setIsSuccess(false)
        }} severity={'success'} message={'La generación se ha realizado con éxito.'}/>

    </Box>
}

const ImportPreOrderItems = ({
                                 preOrderId, loadingImport, setLoadingImport
                             }) => {
    const [t] = useTranslation('common')

    const history = useHistory()

    const [selectedFile, setSelectedFile] = useState(null)
    const [isSuccess, setIsSuccess] = useState(false)
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const uploadFile = (e) => {
        setSelectedFile(e.target.files[0])
    }

    const sendFile = async () => {
        setLoadingImport(true)
        setIsSuccess(false)
        setIsError(false)

        const formData = new FormData()
        formData.append('file', selectedFile)
        try {
            const response = await fetchSecure(window.REACT_APP_BASE_API_URL + '/preOrder/' + preOrderId + '/import', {
                method: 'PUT',
                body: formData

            })
            if (response.ok) {
                setIsSuccess(true)
                setTimeout(() => {
                    history.push('/preOrder/edit/' + preOrderId)
                }, 1000)

            } else {
                const responseJson = await response.json()
                setErrorMessage(responseJson.message)
                setIsError(true)
            }

            setLoadingImport(false)
        } catch (e) {
            setErrorMessage(e.message)
            setLoadingImport(false)
            setIsError(true)
        }

    }

    return <Box mt={4} ml={2} display={'flex'} flexDirection={'column'}>
        <Box>
            <Typography variant={'h6'}>{t('preOrder.import')}</Typography>
        </Box>
        <Box mt={1}>
            <input type="file" name="preOrderItemsFile" onChange={(e) => uploadFile(e)}/>
        </Box>
        <Box mt={1}>
            <Button onClick={sendFile} disabled={!selectedFile || loadingImport} color="primary">{t('upload')}</Button>
        </Box>
        <SnackbarMessage open={isError} handleClose={() => {
            setIsError(false)
        }} severity={'error'} message={'Ha ocurrido un error al intentar importar. ' + errorMessage}/>

        <SnackbarMessage open={isSuccess} handleClose={() => {
            setIsSuccess(false)
        }} severity={'success'} message={'La importación se ha realizado con éxito'}/>

    </Box>
}


const ExportPreOrderItems = ({preOrder}) => {
    const [t] = useTranslation('common')

    const downloadFile = async () => {
        if(!preOrder.closed) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: {}
            };
    
            await fetchSecure(window.REACT_APP_BASE_API_URL + '/preOrder/' + preOrder.id + '/updateProductData', requestOptions)
    
        }
        const url = window.REACT_APP_BASE_API_URL + '/preOrder/' + preOrder.id + '/download'
        const response = await fetchSecure(url)
        const blob = await response.blob()
        const file = window.URL.createObjectURL(blob)
        window.location.assign(file)
    }

    return <Box mt={4} ml={2} display={'flex'} flexDirection={'column'}>
        <Box>
            <Typography variant={'h6'}>{t('download')}</Typography>
        </Box>
        <Box>
            <Button onClick={downloadFile} color="primary">{t('download')}</Button>
        </Box>
    </Box>
}


const PreOrderItemList = ({preOrderId, customerId}) => {

    const theme = useTheme()

    const columns = [
        {id: 'supplier.name', minWidth: 100, label: 'Proveedor'},
        {id: 'productCode', type: 'TextField', label: 'Codigo producto'},
        {
            id: 'product',
            minWidth: 100,
            label: 'Producto',
            format: (value) => <Link target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}
                                     to={`/product/edit/${value.id}`}>{value.descriptionSP}</Link>
        },
        {id: 'quantity', minWidth: 100, label: 'Cantidad'},
        {id: 'price', minWidth: 100, label: 'Precio'},
        {id: 'supplierOrder', minWidth: 100, label: 'Nro de orden'},
    ]

    const editInitialState = {quantity: 0, price: 0, supplierId: -1, productId: -1, preOrderId}

    const editBaseSaveUrl = window.REACT_APP_BASE_API_URL + '/preOrderItem'

    const editFields = [
        {
            name: 'supplierId',
            type: 'SimpleSelectField',
            url: window.REACT_APP_BASE_API_URL + '/supplier/byCustomer/' + customerId,
            optionValue: 'id',
            optionShow: 'name'
        },
        {
            name: 'productId', type: 'SimpleSelectField', dynamicUrl: (preOrderItem) => {
                if (!customerId || !preOrderItem.supplierId) {
                    return undefined
                }
                return `${window.REACT_APP_BASE_API_URL}/codePerCustomer/${customerId}/${preOrderItem.supplierId}`
            }, optionValue: 'productId', optionShow: 'code'
        },
        {name: 'quantity', type: 'DecimalField'},
        {name: 'price', type: 'DecimalField', optional: true},
        {name: 'supplierOrder', type: 'TextField', label: 'Nro de orden', optional: true}

    ]

    const fetchUrl = window.REACT_APP_BASE_API_URL + '/preOrder/' + preOrderId + '/preOrderItemList'

    return <Card style={{margin: theme.spacing(1, 0, 1, 0), padding: theme.spacing(2, 2, 2, 2)}}>
        <Typography style={{marginBottom: theme.spacing(2)}} variant={'h6'}>Items</Typography>

        <InternalListComponent columns={columns} fetchUrl={fetchUrl} editInitialState={editInitialState}
                               editBaseSaveUrl={editBaseSaveUrl} editFields={editFields}/>

    </Card>


}
