import {Button, Fab, TextField, useTheme} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {fetchSecure} from "../../modules/fetchSecure"
import {useHistory} from "react-router-dom"
import Box from "@material-ui/core/Box"
import Switch from "@material-ui/core/Switch"
import {Formik} from "formik"
import Grid from "@material-ui/core/Grid"
import {SimpleSelectField} from "../selectField/SimpleSelectField"
import {SnackbarMessage} from "../atoms/SnackbarMessage"
import {NewDateField} from "../dateField/NewDateField"
import {NewCheckboxField} from "../atoms/NewCheckboxField"
import LoadingScreen from "../loading/LoadingScreen"


export const FormPage = ({
                             initialState, objectId, baseSaveUrl, fields, setCurrentState = () => {
    }, showSaveButtonBelowForm = false
                         }) => {

    const theme = useTheme()

    const [loadedObject, setLoadedObject] = useState(initialState)

    const [loading, setLoading] = useState(true)
    const [isInErrorState, setIsInErrorState] = useState(false)
    const [wasSavedSuccessfully, setWasSavedSuccessfully] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const [t] = useTranslation('common')
    const [show, setShow] = useState(objectId != null)

    const handleShow = e => {
        setShow(e.target.checked)
    }

    const onSubmit = async (values) => {

        const method = objectId ? 'PUT' : 'POST'
        const url = objectId ? baseSaveUrl + '/' + objectId : baseSaveUrl

        const body = JSON.stringify(values)

        const requestOptions = {
            method: method,
            headers: {'Content-Type': 'application/json'},
            body
        }

        try {
            const response = await fetchSecure(url, requestOptions)
            const responseJson = await response.json()
            if (response.ok) {
                setLoadedObject(responseJson)
                setCurrentState(responseJson)
                setWasSavedSuccessfully(true)
                setTimeout(() => {
                    setWasSavedSuccessfully(false)
                }, 3000)

            } else {
                const responseErrorMessage = (responseJson && responseJson.message) || response.status
                setErrorMessage(responseErrorMessage)
                setIsInErrorState(true)
                setTimeout(() => {
                    setIsInErrorState(false)
                }, 3000)
            }
        } catch (error) {
            setErrorMessage(error)
            setIsInErrorState(true)
            setTimeout(() => {
                setIsInErrorState(false)
            }, 3000)
        }

    }


    useEffect(() => {
        if (objectId) {
            fetchSecure(baseSaveUrl + '/' + objectId)
                .then(response => response.json())
                .then(data => {
                    setLoadedObject(data)
                    setCurrentState(data)
                    setLoading(false)
                })
        } else {
            setLoading(false)
        }

    }, [objectId])

    const history = useHistory()
    const goToPreviousPath = () => {
        history.goBack()
    }

    if (loading) {
        return <Box style={{display: 'flex', height: '100vh', flexDirection: 'column'}}><LoadingScreen/></Box>
    }

    return (
        <Box padding={2}>
            <Box display="flex" mb={4}>
                <Button variant="contained" color="primary" onClick={goToPreviousPath}>Listado</Button>
                <Box style={{marginLeft: 'auto', marginRight: theme.spacing(2)}}>{t('button.show')}<Switch
                    defaultChecked={show} color="primary" onClick={handleShow}/></Box>
            </Box>

            <Formik enableReinitialize={true} initialValues={loadedObject} onSubmit={onSubmit}>
                {formik => (
                    <Grid container spacing={1}>

                        {fields.map((field) => (
                            <Grid item xs={4} sm={2} key={field.name}>
                                {
                                    field.type === 'TextField' &&
                                    <TextField style={{width: '100%'}} disabled={show}
                                               error={!!formik.errors[field.name]}
                                               helperText={formik.errors[field.name]} value={formik.values[field.name]}
                                               onChange={formik.handleChange} variant="outlined"
                                               label={field.label ?? t(field.name)} placeholder={t(field.name)}
                                               name={field.name}/>
                                }
                                {
                                    field.type === 'DateField' &&
                                    <NewDateField style={{width: '100%'}} disabled={show}
                                                  error={!!formik.errors[field.name]} name={field.name}
                                                  value={formik.values[field.name]} setFieldValue={formik.setFieldValue}
                                                  label={field.label ?? t(field.name)}/>

                                }
                                {
                                    field.type === 'SimpleSelectField' &&
                                    <SimpleSelectField style={{width: '100%'}} disabled={show}
                                                       error={!!formik.errors[field.name]} name={field.name}
                                                       value={formik.values[field.name]} onChange={(e, newValue) => {
                                        formik.setFieldValue(field.name, newValue)
                                    }} url={field.url} optionValue={field.optionValue} optionShow={field.optionShow}/>
                                }
                                {
                                    field.type === 'DecimalField' &&
                                    <TextField style={{width: '100%'}} error={!!formik.errors[field.name]}
                                               helperText={formik.errors[field.name]} value={formik.values[field.name]}
                                               onChange={formik.handleChange} variant="outlined"
                                               label={field.label ?? t(field.name)} placeholder={t(field.name)}
                                               name={field.name} type="number"/>
                                }
                                {
                                    field.type === 'Checkbox' &&
                                    <NewCheckboxField label={field.label ?? t(field.name)} style={{width: '100%'}}
                                                      disabled={show} name={field.name}
                                                      value={formik.values[field.name]} onChange={formik.handleChange}/>

                                }
                            </Grid>
                        ))}
                        {showSaveButtonBelowForm &&
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={formik.handleSubmit}>Guardar</Button>
                        </Grid>
                        }


                        {!show &&
                        <Fab variant="extended" color="primary" onClick={formik.handleSubmit} style={{
                            position: 'fixed',
                            bottom: theme.spacing(2),
                            right: theme.spacing(2)
                        }}>Guardar</Fab>
                        }

                    </Grid>
                )}
            </Formik>


            <SnackbarMessage open={isInErrorState} handleClose={() => {
                setIsInErrorState(false)
            }} severity={'error'} message={'Ha ocurrido un error inesperado al intentar grabar. ' + errorMessage}/>

            <SnackbarMessage open={wasSavedSuccessfully} handleClose={() => {
                setWasSavedSuccessfully(false)
            }} severity={'success'} message={'El objeto se ha grabado con éxito'}/>


        </Box>
    )
}
