import InputField from '../../../components/inputField/InputField.js';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import {Button, FormGroup} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import React, {Fragment, useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import {useTranslation} from "react-i18next";
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import CheckboxField from "../../../components/checkboxField/CheckboxField";
import TransferList from "../../../components/transferList/TransferList";
import {useHistory} from "react-router-dom";
import {Auth} from "@aws-amplify/auth";
import {fetchSecure} from "../../../modules/fetchSecure";


function ReportItem(props) {

    const [report, setReport] = useState({});
    const [fields, setFields] = useState(undefined);
    const [loaded, setLoaded] = useState(false);
    const [loadedFields, setLoadedFields] = useState(false);

    const [showConfidential, setShowConfidential] = useState(false)
    const [showConfidentialLoaded, setShowConfidentialLoaded] = useState(false)

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(async (user) => {
                setShowConfidential(['ignacio', 'carlos', 'lucas', 'sebastian', 'nacho'].includes(user.username))
                setShowConfidentialLoaded(true)
            })
            .catch((e) => {
                setShowConfidential(false)
                setShowConfidentialLoaded(true)
                console.error(JSON.stringify(e))
            })
    }, []);

    const [savedStatus, setSavedStatus] = useState({saved: false, error: false});
    const [errors, setErrors] = useState({})
    const [t] = useTranslation('common');
    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {},
    });
    const classes = useStyles();

    const handleChange = e => {
        let newReport = {...report}
        newReport[e.target.name] = e.target.value;
        setReport(newReport);
    }

    const handleListChange = (listName, listValue) => {
        let newReport = {...report}
        newReport[listName] = listValue;
        setReport(newReport);
    }

    function not(a, b) {
        return a === undefined ? [] : b === undefined ? a : a.filter((value) => b.indexOf(value) === -1)
    }

    const [show, setShow] = useState(props.match.params.objectId != null)
    const handleShow = e => {
        setShow(e.target.checked)
    }


    const handleSave = () => {
        let method = 'POST';
        let url = window.REACT_APP_BASE_API_URL + '/report';
        if (props.match.params.objectId != null && props.location?.state?.clone !== true) {
            method = 'PUT';
            url = url + "/" + props.match.params.objectId
        } else {
            report.version = 0;
            delete report.id
        }
        const reportInJson = JSON.stringify(report);
        const requestOptions = {
            method: method,
            headers: {'Content-Type': 'application/json'},
            body: reportInJson
        };


        fetchSecure(url, requestOptions)
            .then(async response => {
                    const data = await response.json();
                    if (!response.ok) {
                        setErrors(data.fields == null ? {} : data.fields)
                        const errorMessage = (data && data.message) || response.status;
                        return Promise.reject(errorMessage);
                    } else {
                        setErrors({})
                        props.match.params.objectId = data.id
                        setSavedStatus({saved: true, error: false})
                    }
                }
            ).catch(errorMessage => {
            setSavedStatus({saved: false, error: true, errorMessage: errorMessage})
        });

    }


    useEffect(() => {
        if (Object.keys(errors).length === 0 && showConfidentialLoaded) {
            if (props.match.params.objectId != null) {
                fetchSecure(window.REACT_APP_BASE_API_URL + '/report/' + props.match.params.objectId)
                    .then(response => response.json())
                    .then(data => setReport(data))
                    .finally(() => setLoadedFields(true))
            } else {
                setLoadedFields(true)
            }
            fetchSecure(window.REACT_APP_BASE_API_URL + '/wish/fields')
                .then(response => response.json())
                .then(data => setFields(data.map((it) => it.name).filter(fieldName => showConfidential || !['fee', 'extras', 'visa', 'confirmed', 'toBeConfirmed', 'supplierContact', 'confirmedDate', 'toBeConfirmedDate'].includes(fieldName))))
                .finally(() => setLoaded(true))

        }
    }, [errors, loaded, loadedFields, props.match.params.objectId, savedStatus, t, showConfidential, showConfidentialLoaded]);

    const history = useHistory()
    const goToPreviousPath = () => {
        history.goBack()
    }

    if (!loaded || !loadedFields) {
        return <div>Cargando</div>
    } else {
        return <Paper className={classes.root}>
            <Box m={2}>
                <Button variant="contained" color="primary" onClick={goToPreviousPath}>Listado</Button>
            </Box>
            {savedStatus.error && <Alert severity="error">
                Ha ocurrido un error inesperado al intentar grabar el reporte
                {Object.keys(errors).map((it) =>
                    <Fragment>
                        <li>
                            {errors[it]}
                        </li>
                    </Fragment>
                )}
            </Alert>
            }

            {savedStatus.saved && <Alert severity="success">El reporte se ha grabado con éxito</Alert>
            }

            <Box m={2}>
                <Grid container spacing={3}>
                    <Grid item xs={9}>
                    </Grid>
                    <Grid item xs={2}>
                        {t('button.show')}<Switch defaultChecked={show} color="primary" onClick={handleShow}/>
                    </Grid>
                </Grid>
            </Box>

            <Box m={2}>
                <form className={classes.container} noValidate>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h6">{t('reportInformation')}</Typography>
                            <FormGroup row={true}>
                                <InputField disabled={show} error={!!errors.name} name="name" label="Nombre"
                                            value={report.name} type="text" onChange={handleChange}
                                            className={classes.textField}/>
                                <CheckboxField disabled={show} name="pendingOnly" label="Solo pendientes"
                                               value={report.pendingOnly} onChange={handleListChange}
                                               className={classes.textField}/>
                                <CheckboxField disabled={show} name="finalized" label="Finalizado"
                                               value={report.finalized} onChange={handleListChange}
                                               className={classes.textField}/>
                                <CheckboxField disabled={show} name="finalizedWithOfficializationDate"
                                               value={report.finalizedWithOfficializationDate}
                                               onChange={handleListChange}
                                               className={classes.textField}/>
                                <InputField disabled={show} error={!!errors.usersPermitted} name="usersPermitted"
                                            label="usersPermitted"
                                            value={report.usersPermitted} type="text" onChange={handleChange}
                                            className={classes.textField}/>

                            </FormGroup>
                        </CardContent>
                    </Card>

                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h6">{t('reportFields')}</Typography>
                            <FormGroup row={true}>
                                <TransferList leftValue={not(fields, report.fields)}
                                              rightValue={report.fields !== undefined ? report.fields : []}
                                              onChange={handleListChange} name="fields" show={show}/>
                            </FormGroup>
                        </CardContent>
                    </Card>


                    {show === false && <CardActions>
                        <FormGroup row={true}>
                            <Box m={2}>
                                <Button variant="contained" color="primary" onClick={handleSave}>Guardar</Button>
                            </Box>
                        </FormGroup>
                    </CardActions>
                    }
                </form>
            </Box>

        </Paper>
    }
}

export default ReportItem;
