import InputField from '../../../components/inputField/InputField.js';
import SelectField from '../../../components/selectField/SelectField.js';
import DateField from '../../../components/dateField/DateField.js';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import {Button, FormGroup} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import React, {Fragment, useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import {useTranslation} from "react-i18next";
import CheckboxField from "../../../components/checkboxField/CheckboxField";
import SelectFieldMultiple from "../../../components/selectField/SelectFieldMultiple";
import {fetchSecure} from "../../../modules/fetchSecure";
import {useHistory} from "react-router-dom";
import InputTextAreaField from "../../../components/inputField/InputTextAreaField";


function DocumentsCourierRecordItem(props) {
    const history = useHistory()
    const [courierRecord, setCourierRecord] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [savedStatus, setSavedStatus] = useState({saved: false, error: false});
    const [errors, setErrors] = useState({})
    const [t] = useTranslation('common');
    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {},
    });
    const classes = useStyles();

    const handleChange = e => {
        let newCourierRecord = {...courierRecord}
        newCourierRecord[e.target.name] = e.target.value;
        setCourierRecord(newCourierRecord);
    }

    const handleListChange = (listName, listValue) => {
        let newCourierRecord = {...courierRecord}
        newCourierRecord[listName] = listValue;
        setCourierRecord(newCourierRecord);
    }

    const dataSelect =
        [
            {"name": "Supplier", "id": 1},
            {"name": "Cliente", "id": 2}
        ]

    const handleSave = () => {
        let method = 'POST';
        let url = window.REACT_APP_BASE_API_URL + '/documentsCourierRecord';
        if (props.match.params.objectId != null) {
            method = 'PUT';
            url = url + "/" + props.match.params.objectId
        } else {
            courierRecord.version = 0;
            courierRecord.costsInChargeOf = (courierRecord.costsInChargeOf !== undefined && courierRecord.costsInChargeOf !== null) ? courierRecord.costsInChargeOf.name : null;
        }
        let courierRecordInJson = JSON.stringify(courierRecord);
        const requestOptions = {
            method: method,
            headers: {'Content-Type': 'application/json'},
            body: courierRecordInJson
        };


        fetchSecure(url, requestOptions)
            .then(async response => {
                    const data = await response.json();
                    if (!response.ok) {

                        setErrors(data.fields == null ? {} : data.fields)
                        const errorMessage = (data && data.message) || response.status;
                        return Promise.reject(errorMessage);
                    } else {
                        setErrors({})
                        props.match.params.objectId = data.id
                        setSavedStatus({saved: true, error: false})
                    }
                }
            ).catch(errorMessage => {
            setSavedStatus({saved: false, error: true, errorMessage: errorMessage})
        });

    }


    useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (props.match.params.objectId != null) {
                fetchSecure(window.REACT_APP_BASE_API_URL + '/documentsCourierRecord/' + props.match.params.objectId)
                    .then(response => response.json())
                    .then(data => setCourierRecord(data))
                    .then(() => setLoaded(true));
            }
        }
    }, [errors, loaded, props.match.params.objectId, savedStatus]);


    return (
        <Paper className={classes.root}>

            <Box m={2}>
                <Button variant="contained" color="primary"
                        onClick={() => history.push("/courierRecord")}>Listado</Button>

            </Box>

            {savedStatus.error && <Alert severity="error">
                Ha ocurrido un error inesperado al intentar grabar el documento de courier
                {Object.keys(errors).map((it) =>
                    <Fragment>
                        <li>
                            {errors[it]}
                        </li>
                    </Fragment>
                )}
            </Alert>
            }

            {savedStatus.saved && <Alert severity="success">El documento de courier se ha grabado con éxito</Alert>
            }

            <Box m={2}>
                <form className={classes.container} noValidate>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h6">{t('documentsCourierRecordInformation')}</Typography>
                            <FormGroup row={true}>
                                <SelectField error={!!errors.courier} name="courier" label="courier"
                                             value={courierRecord.courier} changeHandler={handleListChange}
                                             className={classes.textField}
                                             url={window.REACT_APP_BASE_API_URL + '/couriers'} optionValue="id"
                                             optionShow="name"/>
                                <DateField error={!!errors.departureDate} name="departureDate" label="Fecha de salida"
                                           value={courierRecord.departureDate} onChange={handleListChange}
                                           className={classes.textField}/>
                                <DateField error={!!errors.estimatedArrivalDate} name="estimatedArrivalDate"
                                           label="Fecha estimada de llegada" value={courierRecord.estimatedArrivalDate}
                                           onChange={handleListChange} className={classes.textField}/>
                                <DateField error={!!errors.arrivalDate} name="arrivalDate" label="Fecha de llegada"
                                           value={courierRecord.arrivalDate} onChange={handleListChange}
                                           className={classes.textField}/>
                                <InputField error={!!errors.observations} name="observations" label="Observaciones"
                                            value={courierRecord.observations} type="text" onChange={handleChange}
                                            className={classes.textField}/>
                            </FormGroup>
                        </CardContent>
                        <CardContent>
                            <FormGroup row={true}>
                                {props.match.params.objectId != null ?
                                    <SelectField error={!!errors.costsInChargeOf} name="costsInChargeOf"
                                                 label="costsInChargeOf" value={courierRecord.costsInChargeOf}
                                                 changeHandler={handleListChange} className={classes.textField}
                                                 data={dataSelect} optionValue="name" optionShow="name"/>
                                    :
                                    <SelectField error={!!errors.costsInChargeOf} name="costsInChargeOf"
                                                 label="costsInChargeOf" value={courierRecord.costsInChargeOf}
                                                 changeHandler={handleListChange} className={classes.textField}
                                                 data={dataSelect} optionValue="name" optionShow="name"/>
                                } <InputField error={!!errors.source} name="source" label="Origen"
                                              value={courierRecord.source} type="text" onChange={handleChange}
                                              className={classes.textField}/>
                                <InputField error={!!errors.destination} name="destination" label="Destino"
                                            value={courierRecord.destination} type="text" onChange={handleChange}
                                            className={classes.textField}/>
                                <InputField error={!!errors.aditionalCosts} name="aditionalCosts"
                                            label="Costos adicionales" value={courierRecord.aditionalCosts} type="text"
                                            onChange={handleChange} className={classes.textField}/>
                                <InputField error={!!errors.trackingNumber} name="trackingNumber"
                                            label="Número de seguimiento" value={courierRecord.trackingNumber}
                                            type="text" onChange={handleChange} className={classes.textField}/>
                                <CheckboxField name="requiereDebitNote" label="Nota de debito"
                                               value={courierRecord.requiereDebitNote} onChange={handleListChange}
                                               className={classes.textField}/>
                            </FormGroup>
                        </CardContent>
                        <CardContent>
                            <FormGroup row={true}>
                                <SelectField error={!!errors.issuer} name="issuer" label="issuer"
                                             value={courierRecord.issuer} changeHandler={handleListChange}
                                             className={classes.textField}
                                             url={window.REACT_APP_BASE_API_URL + '/stakeholders'} optionValue="id"
                                             optionShow="name"/>
                                <InputField error={!!errors.order} name="order" label="Costos adicionales"
                                            value={courierRecord.order} type="text" onChange={handleChange}
                                            className={classes.textField}/>
                                <SelectFieldMultiple error={!!errors.receivers} name="receivers" label="receivers"
                                                     value={courierRecord.receivers} changeHandler={handleListChange}
                                                     className={classes.textField}
                                                     optionShowWithI18n={false}
                                                     url={window.REACT_APP_BASE_API_URL + '/stakeholders'}
                                                     optionValue="id" optionShow="name"/>
                                <InputTextAreaField style={{marginLeft: '16px'}} error={!!errors.additionalInfo}
                                                    name="additionalInfo" label="Informacion Adicional"
                                                    value={courierRecord.additionalInfo} type="text"
                                                    onChange={handleChange} className={classes.textField}/>
                            </FormGroup>
                        </CardContent>
                    </Card>


                    <CardActions>
                        <FormGroup row={true}>
                            <Box m={2}>
                                <Button variant="contained" color="primary" onClick={handleSave}>Guardar</Button>
                            </Box>
                        </FormGroup>
                    </CardActions>
                </form>
            </Box>

        </Paper>
    )
}

export default DocumentsCourierRecordItem;
