
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Auth} from "@aws-amplify/auth";


function Home() {
	const [t] = useTranslation('common');


	useEffect(() => {
		Auth.currentAuthenticatedUser()
			.then(async (user) => {
				setUsername(user.username)
			})
			.catch((e) => {
				console.error(JSON.stringify(e))
			})

	}, []);

	const [username, setUsername] = useState('');

	return (
		<div><p style={{marginLeft: 8}}>{t('welcome')} {username}</p></div>
	)

}


export default Home;
