import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import common_en from "../resources/translations/en/common.json";
import common_es from "../resources/translations/es/common.json";


i18n.use(initReactI18next)
    .init({
        resources: {
            en: {
                common: common_en
            },
            es: {
                common: common_es
            }
        },
        fallbackLng: 'es',
        debug: process.env.NODE_ENV !== 'production',
        react: {
            wait: true,
        },
    })
export default i18n;
