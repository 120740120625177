import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {Box, FormGroup} from "@material-ui/core";
import SelectField from "../../../components/selectField/SelectField";
import {fetchSecure} from "../../../modules/fetchSecure";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Card from "@material-ui/core/Card";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {Button} from "@mui/material";
import {Auth} from "@aws-amplify/auth";
import {fullAccessUsers} from "../../../lib/fullAccessUsers";
import LoadingScreen from "../../../components/loading/LoadingScreen";


export const StakeholderReportDownload = ({stakeholderId}) => {
    const [report, setReport] = useState({})
    const handleReportListChange = (listName, listValue) => {
        setReport(listValue);
    }
    const [t] = useTranslation('common');
    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {
        },
    });
    const classes = useStyles()

    const [showConfidential, setShowConfidential] = useState(false)
    const [showConfidentialLoaded, setShowConfidentialLoaded] = useState(false)
    const [currentUser, setCurrentUser] = useState('')
    const [loading, setLoading] = useState(false);
    const [reportList, setReportList] = useState([]);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(async (user) => {
                setCurrentUser(user.username)
                setShowConfidential(fullAccessUsers.includes(user.username) )
                setShowConfidentialLoaded(true)
            })
            .catch((e) => {
                setShowConfidential(false)
                setShowConfidentialLoaded(true)
                console.error(JSON.stringify(e))
            })
    }, [])
    useEffect(() => {
        if(showConfidentialLoaded) {
            setLoading(true)
            fetchSecure(window.REACT_APP_BASE_API_URL+'/report')
                .then(response => response.json())
                .then(data => setReportList(data.filter(report => showConfidential || (!report.confidential && (report.usersPermitted ===null || report.usersPermitted.isEmpty || report.usersPermitted.split(',').includes(currentUser))))))
                .then(() => setLoading(false))
                .catch(() => setLoading(false));

        }
    }, [showConfidentialLoaded, showConfidential, currentUser]);

    if (loading) {
        return <Box display="flex" justifyContent="center" alignItems="center" style={{height: '100vh'}}><LoadingScreen backgroundColor={'white'}/></Box>
    }

    return <Card className={classes.root} variant="outlined">
        <CardContent>

            <Typography variant="h6">{t('report')}</Typography>
            <Box display={'flex'}>
                <FormGroup row={true}>
                    <SelectField name="report" label="report" value={report} changeHandler={handleReportListChange} data={reportList} optionValue="id" optionShow="name" />
                </FormGroup>

                <Button color="primary" onClick={() => {
                    fetchSecure(window.REACT_APP_BASE_API_URL+'/report/download/'+report.id+'/'+stakeholderId).then(response => response.blob())
                        .then( blob => {
                            const file = window.URL.createObjectURL(blob)
                            window.location.assign(file)
                        })
                }
                }>
                    <CloudDownloadIcon color="primary" />
                </Button>
            </Box>
        </CardContent>
    </Card>
}
