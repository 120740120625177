
import { Checkbox, FormGroup, FormControlLabel} from '@material-ui/core'

export const NewCheckboxField = ({name, value, onChange, className,disabled, style, label}) => {

	let currentValue = false;
	if(value != null) {
		currentValue = value;
	}

  return <FormGroup style={{display: 'flex', justifyContent: 'center',alignItems: 'center', ...style}} >
  	    <FormControlLabel
			style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
          control={<Checkbox
				disabled={disabled}
		  		checked={currentValue}
		  		onChange={onChange}
		  		name={name} id={name}
		  		className={className}
				/>
          }
          label={<span >{label}</span>}
          labelPlacement="start"
        />
        </FormGroup>
}

