import React, {useEffect, useState} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import {Box, Button, Paper, TextField} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import {useTableSearch} from "../../../components/search/UseTableSearch";
import TableComponent from "../../../components/table/TableComponent.js";
import Grid from '@material-ui/core/Grid';
import {Link} from "react-router-dom";
import {fetchSecure} from "../../../modules/fetchSecure";
import {useAuthorization} from "../../../lib/useAuthorization";
import LoadingScreen from '../../../components/loading/LoadingScreen.js';


function Customer() {

    const [t] = useTranslation('common');


    const [customerList, setCustomerList] = useState([]);
    const [searchVal, setSearchVal] = useState(null);
    const [loading, setLoading] = useState(false);

    const {canSeeAllClients} = useAuthorization()

    const {filteredData} = useTableSearch({
        searchVal,
        retrieve: customerList
    });
    const columns = [
        {id: 'id', label: 'ID', minWidth: 170},
        {id: 'name', label: 'Nombre', minWidth: 100},
        {id: 'email', label: 'Email', minWidth: 100},
        {id: 'defaultReport.name', label: 'Reporte default', minWidth: 100},
        {id: 'defaultLocale.language', label: 'Idioma', minWidth: 100},
        {id: 'address', label: 'Dirección', minWidth: 100}
    ];
    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {
            maxHeight: 440,
        },
    });

    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const createTo = {
        pathname: "/customer/create",
    };

    useEffect(() => {
        setLoading(true)
        fetchSecure(window.REACT_APP_BASE_API_URL + '/customer')
            .then(response => response.json())
            .then(data => setCustomerList(data))
            .then(() => setLoading(false))
            .catch(() => setLoading(false));

    }, [page]);

    if (loading) {
        return <Box display="flex" justifyContent="center" alignItems="center" style={{height: '100vh'}}><LoadingScreen
            backgroundColor={'white'}/></Box>
    } else {
        return <div>
            <Paper className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={9}>
                        <Box m={2}>
                            <TextField
                                onChange={e => setSearchVal(e.target.value)}
                                placeholder="Search"
                                enterButton
                                style={{position: "sticky", top: "0", left: "0"}}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={3} alignItems="left">

                        <Box m={2}>
                            <Link to={createTo}>
                                <Button variant="contained" color="primary">{t('button.create')}</Button>
                            </Link>
                        </Box>

                    </Grid>
                </Grid>

                <TableComponent
                    columns={columns}
                    filteredData={canSeeAllClients ? filteredData : filteredData.filter((customer) => customer.id !== 347)}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    count={customerList.length}
                    urlEdit={"/customer/edit/"}
                />
            </Paper>
        </div>
    }

}

export default Customer;
