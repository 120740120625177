import React, {Fragment, useCallback, useEffect, useState} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import Grid from '@material-ui/core/Grid';
import {Link} from "react-router-dom";
import SelectField from '../../components/selectField/SelectField.js';
import {fetchSecure} from "../../modules/fetchSecure";
import {format as formatDate} from "date-fns";
import LoadingScreen from '../../components/loading/LoadingScreen.js';

function LetterOfGuarantee() {

    const [letterOfGuaranteeList, setLetterOfGuaranteeList] = useState([]);
    const [year, setYear] = useState({year: {id: "2023"}});

    const dataSelect =
        [
            {"id": "2026", "name": "year"},
            {"id": "2025", "name": "year1"},
            {"id": "2024", "name": "year1"},
            {"id": "2023", "name": "year1"},
            {"id": "2022", "name": "year1"},
            {"id": "2021", "name": "year1"},
            {"id": "2020", "name": "year1"},
            {"id": "2019", "name": "year1"},
            {"id": "2018", "name": "year1"},
            {"id": "2017", "name": "year1"},
            {"id": "2016", "name": "year1"},
            {"id": "2015", "name": "year1"},
            {"id": "2014", "name": "year1"},
            {"id": "2013", "name": "year1"},
            {"id": "2012", "name": "year1"},
            {"id": "2011", "name": "year1"},
            {"id": "2010", "name": "year1"},
            {"id": "2009", "name": "year1"},
            {"id": "2008", "name": "year1"},
            {"id": "2007", "name": "year1"},
            {"id": "2006", "name": "year1"},
            {"id": "2005", "name": "year1"},
            {"id": "2004", "name": "year1"},
            {"id": "2003", "name": "year1"},
            {"id": "2002", "name": "year1"},
        ]

    const handleListChange = useCallback((listName, listValue) => {
        let newYear = {...year}
        newYear[listName] = listValue;
        setYear(newYear);
        fetchSecure(window.REACT_APP_BASE_API_URL + '/letterOfGuaranteeCustomerForwarder/' + newYear.year.id)
            .then(response => response.json())
            .then(data => setLetterOfGuaranteeList(data));
    }, [year])


    const [t] = useTranslation('common');
    const [loading, setLoading] = useState(false);


    const createTo = {
        pathname: "/letterOfGuarantee/create",
    };

    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {
            maxHeight: 440,
        },
    });

    const classes = useStyles();


    useEffect(() => {
        setLoading(true)
        fetchSecure(window.REACT_APP_BASE_API_URL + '/letterOfGuaranteeCustomerForwarder/' + 2023)
            .then(response => response.json())
            .then(data => setLetterOfGuaranteeList(data))
            .then(() => setLoading(false))
            .catch(() => setLoading(false));

    }, []);


    if (loading) {
        return <Box display="flex" justifyContent="center" alignItems="center" style={{height: '100vh'}}><LoadingScreen
            backgroundColor={'white'}/></Box>
    } else {
        return <Box sx={{mt: 4, ml: 2}}>
            <Grid container spacing={3}>

                <Grid item xs={8} alignItems="left">
                    <SelectField error={false} name="year" label="Año" value={year.year}
                                 changeHandler={handleListChange} className={classes.textField} data={dataSelect}
                                 optionValue="id" optionShow="id"/>
                </Grid>

                <Grid item xs={3} alignItems="left">
                    <Box m={2}>
                        <Link to={createTo}>
                            <Button variant="contained" color="primary">{t('button.create')}</Button>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
            <br/>
            <hr/>
            <br/>
            <Grid container spacing={3}>
                <Grid item xs={10}>
                    {letterOfGuaranteeList.forwarders !== undefined && letterOfGuaranteeList.customers !== undefined &&
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell key={123121312}>
                                        {}
                                    </TableCell>
                                    {letterOfGuaranteeList.forwarders.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{minWidth: column.minWidth}}
                                        >
                                            {column.name}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {letterOfGuaranteeList.customers.map((customer) => (

                                    <TableRow hover role="checkbox" tabIndex={-1} key={customer.id}>
                                        <Fragment>
                                            <TableCell key={customer.id}>
                                                {customer.name}
                                            </TableCell>
                                            {letterOfGuaranteeList.forwarders.map((fowarder) => {
                                                let mapCustomer = letterOfGuaranteeList.letters.find(element1 => (element1.forwarderId === fowarder.id && element1.customerId === customer.id));
                                                if (mapCustomer !== undefined) {
                                                    if (mapCustomer.letterId != null && mapCustomer.hasWish === true) {
                                                        return (
                                                            <TableCell key={fowarder.id}
                                                                       style={{backgroundColor: '#81c784'}}>
                                                                <Link
                                                                    to={"/letterOfGuarantee/edit/" + mapCustomer.letterId}>
                                                                    <Typography>
                                                                        {formatDate(new Date(mapCustomer?.deliveryDate), 'dd/MM/yyyy')}
                                                                    </Typography>
                                                                </Link>
                                                            </TableCell>
                                                        )

                                                    }
                                                    if (mapCustomer.letterId == null && mapCustomer.hasWish === true) {
                                                        return (
                                                            <TableCell key={fowarder.id}
                                                                       style={{backgroundColor: '#f44336'}}>
                                                                <Link to={createTo}>
                                                                    <Button variant="outlined" color="primary">
                                                                        {t('createLetter')}
                                                                    </Button>
                                                                </Link>
                                                            </TableCell>
                                                        )
                                                    }
                                                    if (mapCustomer.letterId != null && mapCustomer.hasWish === false) {
                                                        return (
                                                            <TableCell key={fowarder.id}
                                                                       style={{backgroundColor: '#ffb74d'}}>
                                                                <Link
                                                                    to={"/letterOfGuarantee/edit/" + mapCustomer.letterId}>
                                                                    <Typography>
                                                                        {formatDate(new Date(mapCustomer?.deliveryDate), 'dd/MM/yyyy')}
                                                                    </Typography>

                                                                </Link>
                                                            </TableCell>

                                                        )

                                                    }
                                                    return null
                                                } else {
                                                    return (
                                                        <TableCell key={fowarder.id}
                                                                   style={{'background-color': '#2196f3'}}>
                                                            <Link to={createTo}>
                                                                <Button variant="outlined" color="primary">
                                                                    {t('createLetter')}
                                                                </Button>

                                                            </Link>
                                                        </TableCell>
                                                    )
                                                }
                                            })}
                                        </Fragment>
                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    }
                </Grid>
            </Grid>


            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableBody>

                                <TableRow hover role="checkbox" tabIndex={-1} key={1234}>
                                    <TableCell key={123} style={{'background-color': '#2196f3'}}>
                                        <Typography style={{'color': '#FFFFFF'}}>No hay pedidos cargados y no está
                                            cargada la carta de garantía</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow hover role="checkbox" tabIndex={-1} key={9992}>
                                    <TableCell key={112345} style={{'background-color': '#81c784'}}>
                                        <Typography style={{'color': '#FFFFFF'}}>Hay pedidos cargados y la carta de
                                            garantía está cargada</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow hover role="checkbox" tabIndex={-1} key={912}>
                                    <TableCell key={123} style={{'background-color': '#ffb74d'}}>
                                        <Typography style={{'color': '#FFFFFF'}}>No hay pedidos cargados, pero la
                                            carta de garantía ya está cargada</Typography>

                                    </TableCell>
                                </TableRow>
                                <TableRow hover role="checkbox" tabIndex={-1} key={123}>
                                    <TableCell key={12} style={{'background-color': '#f44336'}}>
                                        <Typography style={{'color': '#FFFFFF'}}>Hay perdidos cargados y no está
                                            cargada la carta de garantía</Typography>
                                    </TableCell>
                                </TableRow>


                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

        </Box>
    }

}

export default LetterOfGuarantee;
