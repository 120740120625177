import React from "react";
import { Box, Button, CircularProgress } from "@mui/material";

const LoadingButton = ({
  loading = false,
  loadingSize = 14,
  children = undefined,
  ...rest
}) => {
  const newProps = { ...rest, disabled: loading || rest.disabled };
  return (
    <Button {...newProps}>
      {loading && (
        <Box display="flex" justifyContent="center" flex={1}>
          <CircularProgress size={loadingSize} />
        </Box>
      )}
      {!loading && children}
    </Button>
  );
};

export default LoadingButton;