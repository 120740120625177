import {useEffect, useState} from "react";
import {Auth} from "@aws-amplify/auth";

export const canDelete = ['ignacio', 'carlos', 'lucas', 'nacho', 'mimi', "carlos", "sebastian", 'june', 'ayelen']
export const canSeeConfidentual = ['ignacio', 'carlos', 'lucas', 'nacho', 'mimi', 'sebastian', 'june', 'ayelen']
export const canSeeConfidentialData = ['ignacio', 'carlos', 'lucas', 'nacho', 'sebastian']

export const useAuthorization = () => {

    const [loadingUser, setLoadingUser] = useState(true)
    const [user, setUser] = useState(undefined)

    useEffect(() => {
        setLoadingUser(true)
        Auth.currentAuthenticatedUser()
            .then(async (user) => {
                setUser(user)
                setLoadingUser(false)
            })
            .catch(() => {
                setUser(undefined)
                setLoadingUser(false)
            })
    }, []);


    return {
        loadingUser, canDelete: !!user && canDelete.includes(user.username),
        canSeeConfidentual: !!user && canSeeConfidentual.includes(user.username),
        canSeeConfidentialData: !!user && canSeeConfidentialData.includes(user.username),
        canSeeAllClients: !!user && user.username !== 'lucia' && user.username !== 'anahi',
        canSeeReports: !!user && user.username !== 'anahi',
    }


}
