import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useTableSearch} from "../search/UseTableSearch";
import {fetchSecure} from "../../modules/fetchSecure";
import {Box, Button, Modal, TextField} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import TableComponent from "../table/TableComponent";
import {SnackbarMessage} from "../atoms/SnackbarMessage";
import Typography from "@material-ui/core/Typography";
import LoadingScreen from "../loading/LoadingScreen";

export const ListPage = ({createToPath, columns, fetchUrl, editPath, shouldShowIconToDelete}) => {

    const [objectsList, setObjectsList] = useState([])
    const [loading, setLoading] = useState(true)
    const [isInError, setIsInError] = useState({open: false, message: ''})

    const [t] = useTranslation('common');
    const [searchVal, setSearchVal] = useState(null);

    const {filteredData} = useTableSearch({searchVal, retrieve: objectsList});

    const createTo = {
        pathname: createToPath,
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100)

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const [deleteModal, setDeleteModal] = useState({open: false, row: 0})
    const [deleteStatus, setDeleteStatus] = useState({error: false, row: 0})


    const onHandlerDeleteElement = async () => {
        const response = await fetchSecure(fetchUrl + '/' + deleteModal.row, {
            method: 'DELETE',
        })
        if (!response.ok) {
            setIsInError({open: true, message: 'Ocurrió un error al eliminar el objeto'})
            setDeleteStatus({error: true, row: 0})
        } else {
            setDeleteStatus({error: false, row: deleteModal.row})
        }
        setDeleteModal({open: false, row: 0})
    }

    const handlerDeleteModal = (id) => {
        setDeleteModal({open: true, row: id})
    }

    useEffect(() => {
        const fillObjectsList = async () => {
            const response = await fetchSecure(fetchUrl)
            const responseJson = await response.json()

            if (response.ok) {
                setObjectsList(responseJson)
                setLoading(false)
            } else {
                setIsInError({open: true, message: 'Ocurrió un error al listar objetos'})
                setLoading(false)
            }
        }

        fillObjectsList()

    }, [fetchUrl, deleteStatus.row]);

    if (loading) {
        return <Box style={{display: 'flex', height: '100vh', flexDirection: 'column'}}>
            <LoadingScreen/>
        </Box>
    }

    return <Box m={2}>
        <Grid container spacing={3}>
            <Grid item xs={9}>
                <Box m={2}>
                    <TextField
                        onChange={e => setSearchVal(e.target.value)}
                        placeholder="Search"
                        enterButton
                        style={{position: "sticky", top: "0", left: "0"}}
                    />
                </Box>
            </Grid>
            <Grid item xs={3} alignItems="left">

                <Box m={2}>
                    <Link to={createTo}>
                        <Button variant="contained" color="primary">{t('button.create')}</Button>
                    </Link>
                </Box>

            </Grid>
        </Grid>

        <TableComponent
            columns={columns}
            filteredData={filteredData}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={objectsList.length}
            urlEdit={editPath}
            handlerDeleteModal={handlerDeleteModal}
            shouldShowIconToDelete={shouldShowIconToDelete}
        />

        <Modal
            open={deleteModal.open}
            onClose={() => setDeleteModal({open: false, row: 0})}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4
            }}>
                <Typography id="modal-modal-description" sx={{mt: 2}}>
                    Estas seguro que desea elimininarlo?
                </Typography>
                <Box sx={{display: 'flex', flexDirection: 'row', flex: 1, marginTop: '16px'}}>
                    <Button variant="contained" onClick={async () => {
                        await onHandlerDeleteElement()
                    }} style={{flex: 1, display: 'flex'}} color="secondary">
                        Borrar
                    </Button>
                    <Button variant="text" onClick={() => {
                        setDeleteModal({open: false, row: 0})
                    }} style={{flex: 1, display: 'flex'}}>
                        Cancelar
                    </Button>
                </Box>

            </Box>
        </Modal>


        <SnackbarMessage open={isInError.open} handleClose={() => {
            setIsInError({open: false, message: ''})
        }} severity={'error'} message={'Ocurrió un error al listar objetos'}/>

    </Box>


}
