import InputField from '../../../components/inputField/InputField.js';
import SelectField from '../../../components/selectField/SelectField.js';
import DateField from '../../../components/dateField/DateField.js';
import CheckboxField from '../../../components/checkboxField/CheckboxField.js';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import { Button, FormGroup } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from "react-i18next";
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import { Editor } from "@tinymce/tinymce-react";
import SelectFieldMultiple from '../../../components/selectField/SelectFieldMultiple.js';
import {useHistory} from "react-router-dom";
import {fetchSecure} from "../../../modules/fetchSecure";

function ReportSendItem(props) {

    const [reportSend, setReportSend] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [savedStatus, setSavedStatus] = useState({ saved: false, error: false });
    const [errors, setErrors] = useState({})


    const [t] = useTranslation('common');
    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {
        },
    });
    const classes = useStyles();

    const handleChange = e => {
        let newReportSend = { ...reportSend }
        newReportSend[e.target.name] = e.target.value;
        setReportSend(newReportSend);
    }

    const handleListChange = (listName, listValue) => {
        let newReportSend = { ...reportSend }
        newReportSend[listName] = listValue;
        setReportSend(newReportSend);
    }

    const [show, setShow] = useState(props.match.params.objectId != null)
    const handleShow = e => {
        setShow(e.target.checked)
    }

    const handleEditorChange = newBody => {
        setReportSend({ ...reportSend, body: newBody })
    }



    const handleSave = () => {
        let method = 'POST';
        let url = window.REACT_APP_BASE_API_URL + '/reportSendConfiguration';
        if (props.match.params.objectId != null) {
            method = 'PUT';
            url = url + "/" + props.match.params.objectId
        } else {
            reportSend.version = 0;
        }
        let reportSendInJson = JSON.stringify(reportSend);
        const requestOptions = {
            method: method,
            headers: { 'Content-Type': 'application/json' },
            body: reportSendInJson
        };


        fetchSecure(url, requestOptions)
            .then(async response => {
                const data = await response.json()
                if (!response.ok) {
                    setErrors(data.fields == null ? {} : data.fields)
                    const errorMessage = (data && data.message) || response.status;
                    return Promise.reject(errorMessage);
                } else {
                    setErrors({})
                    props.match.params.objectId = data.id
                    setSavedStatus({ saved: true, error: false })
                }
            }
            ).catch(errorMessage => {
                setSavedStatus({ saved: false, error: true, errorMessage: errorMessage })
            });

    }


    useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (props.match.params.objectId != null) {
                fetchSecure(window.REACT_APP_BASE_API_URL + '/reportSendConfiguration/' + props.match.params.objectId)
                    .then(response => response.json())
                    .then(data => setReportSend(data))
                    .then(() => setLoaded(true));
            }
        }
    }, [errors, loaded, props.match.params.objectId, savedStatus]);

    let history = useHistory()
    const goToPreviousPath = () => {
        history.goBack()
    }
    return (
        <Paper className={classes.root}>
            <Box m={2} >
                <Button variant="contained" color="primary" onClick={goToPreviousPath}>Listado</Button>
            </Box>
            {savedStatus.error && <Alert severity="error">
                Ha ocurrido un error inesperado al intentar grabar el reporte
					{Object.keys(errors).map((it) =>
                <Fragment>
                    <li>
                        {errors[it]}
                    </li>
                </Fragment>
            )}
            </Alert>


            }

            {savedStatus.saved && <Alert severity="success">El reporte se ha grabado con éxito</Alert>
            }

            {props.match.params.objectId != null &&
                <Box m={2} >
                    <Grid container spacing={3}>
                        <Grid item xs={9}>
                        </Grid>
                        <Grid item xs={2}>
                            {t('button.show')} <Switch checked={show} color="primary" onClick={handleShow}/>
                        </Grid>
                    </Grid>
                </Box>
            }

            <Box m={2} >
                <form className={classes.container} noValidate>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h6">{t('reportSendinformation')}</Typography>
                            <FormGroup row={true}>
                                <DateField disabled={show} error={!!errors.nextSendDate} name="nextSendDate" label="Fecha estimada de salida del buque" value={reportSend.nextSendDate} onChange={handleListChange} className={classes.textField} />
                                <InputField disabled={show} error={!!errors.name} name="hour" label="hour" value={reportSend.hour} type="text" onChange={handleChange} className={classes.textField} />
                                <InputField disabled={show} error={!!errors.frequencyInDays} name="frequencyInDays" label="frequencyInDays" value={reportSend.frequencyInDays} type="number" onChange={handleChange} className={classes.textField} />


                            </FormGroup>
                        </CardContent>

                        <CardContent>
                            <FormGroup row={true}>

                                <Grid container spacing={3}>
                                    <Grid item xs={9}>
                                        <InputField fullWidth disabled={show} error={!!errors.subject} name="subject" label="subject" value={reportSend.subject} type="text" onChange={handleChange} className={classes.textField} />
                                    </Grid>
                                </Grid>

                            </FormGroup>
                        </CardContent>

                        <CardContent>
                            <FormGroup row={true}>
                                <Editor
                                    apiKey='d65jibki9k8te3ftrq0y1v6qip6sshhxedoy6uvaiwzo17us'
                                    disabled={show}
                                    name="body"
                                    value={reportSend.body}
                                    style={{ margin: "0px !important" }}
                                    init={{
                                        plugins: 'print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists textcolor wordcount imagetools contextmenu colorpicker textpattern help',
                                        toolbar: 'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat',
                                        height: 300
                                    }}
                                    onEditorChange={handleEditorChange}
                                />
                            </FormGroup>
                        </CardContent>

                        <CardContent>
                            <FormGroup row={true}>
                                <SelectField disabled={show} error={!!errors.stakeholder} name="stakeholder" label="stakeholder" value={reportSend.stakeholder} changeHandler={handleListChange} className={classes.stakeholder} url={window.REACT_APP_BASE_API_URL + '/stakeholders'} optionValue="id" optionShow="name" />
                                <SelectFieldMultiple optionShowWithI18n={false} disabled={show} error={!!errors.contacts} name="contacts" label="contacts" value={reportSend.contacts} changeHandler={handleListChange} className={classes.contacts} url={reportSend.stakeholder !== undefined ? window.REACT_APP_BASE_API_URL + '/stakeholders/' + reportSend.stakeholder.id + '/contacts' : undefined} optionValue="id" optionShow="name" />

                            </FormGroup>
                        </CardContent>

                        <CardContent>
                            <FormGroup row={true}>
                                <SelectField disabled={show} error={!!errors.report} name="report" label="report" value={reportSend.report} changeHandler={handleListChange} className={classes.report} url={window.REACT_APP_BASE_API_URL + '/report'} optionValue="id" optionShow="name" />
                                <CheckboxField disabled={show} name="active" label="¿Salió?" value={reportSend.active} onChange={handleListChange} className={classes.textField} />
                                <CheckboxField disabled={show} name="sendAlerts" label="¿Salió?" value={reportSend.sendAlerts} onChange={handleListChange} className={classes.textField} />
                                <CheckboxField disabled={show} name="sendReport" label="¿Enviar reporte?" value={reportSend.sendReport} onChange={handleListChange} className={classes.textField} />
                            </FormGroup>
                        </CardContent>
                    </Card>

                    {show === false && <CardActions>
                        <FormGroup row={true}  >
                            <Box m={2} >
                                <Button variant="contained" color="primary" onClick={handleSave}>Guardar</Button>
                            </Box >
                        </FormGroup>
                    </CardActions>
                    }
                </form>
            </Box>

        </Paper >
    )
}

export default ReportSendItem;
