import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormGroup,
  TextField,
  TextareaAutosize,
  Box,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { fetchSecure } from '../../modules/fetchSecure'

function ListEditModal({ open, handleClose, elementToEdit, options }) {
  const [t] = useTranslation('common')
  const [selectedColor, setSelectedColor] = useState('')
  const [selectedProductType, setSelectedProductType] = useState('')
  const [selectedItemUnit, setSelectedItemUnit] = useState('')
  const [editedElement, setEditedElement] = useState({ ...elementToEdit })
  const editBaseSaveUrl = window.REACT_APP_BASE_API_URL

  const handleInputChange = (fieldName, value) => {
    setEditedElement(prevElement => ({ ...prevElement, [fieldName]: value }))
  }

  const handleSave = async objectId => {
    try {
      const method = 'PUT'
      const url = `${editBaseSaveUrl}/subWishItem/${objectId}`
      const requestOptions = {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(editedElement),
      }
      await fetchSecure(url, requestOptions)
      handleClose()
    } catch (error) {
      console.error(error.message)
    }
  }

  const handleSelectChange = (e, fieldName, optionsList) => {
    const selectedOption = optionsList.find(option => option.description === e.target.value)
    const selectedValue = { id: selectedOption.id, version: selectedOption.version }
    handleInputChange(fieldName, selectedValue)
    switch (fieldName) {
      case 'color':
        setSelectedColor(e.target.value)
        break
      case 'productType':
        setSelectedProductType(e.target.value)
        break
      case 'itemUnit':
        setSelectedItemUnit(e.target.value)
        break
      default:
        break
    }
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{t('editItem')} Item</DialogTitle>
        <DialogContent>
          {elementToEdit ? (
            <FormGroup style={{ gap: '10px' }}>
              <TextField
                label={t('calculatedCostPerItem.productCode')}
                defaultValue={elementToEdit.codePerCustomer?.code || ''}
                onChange={e => handleInputChange('codePerCustomer.code', e.target.value)}
                type="text"
                disabled
              />
              <TextField
                label={t('quantity')}
                defaultValue={elementToEdit.quantity || ''}
                onChange={e => handleInputChange('quantity', Number(e.target.value))}
                type="number"
              />
              <TextField
                label={t('unitPrice')}
                defaultValue={elementToEdit.unitPrice || ''}
                onChange={e => handleInputChange('unitPrice', Number(e.target.value))}
                type="number"
              />
              <TextField
                label={t('subtotal')}
                defaultValue={elementToEdit.subtotal || ''}
                onChange={e => handleInputChange('subtotal', e.target.value)}
                type="number"
              />
              <div>
                <InputLabel htmlFor="description-TextareaAutosize">{t('description')}</InputLabel>
                <TextareaAutosize
                  id="description-TextareaAutosize"
                  aria-label="description"
                  defaultValue={elementToEdit.productDescription || ''}
                  onChange={e => handleInputChange('productDescription', e.target.value)}
                  minRows={2}
                  style={{ width: '100%' }}
                />
              </div>
              <TextField
                label={t('width')}
                defaultValue={elementToEdit.productWidth || ''}
                onChange={e => handleInputChange('productWidth', Number(e.target.value))}
                type="number"
              />
              <TextField
                label={t('height')}
                defaultValue={elementToEdit.productHeight || ''}
                onChange={e => handleInputChange('productHeight', Number(e.target.value))}
                type="number"
              />
              <TextField
                label={t('length')}
                defaultValue={elementToEdit.productLength || ''}
                onChange={e => handleInputChange('productLength', Number(e.target.value))}
                type="number"
              />
              <TextField
                label={t('quantityArtByExternalBox')}
                defaultValue={elementToEdit.productQuantityArtByExternalBox || ''}
                onChange={e => handleInputChange('productQuantityArtByExternalBox', Number(e.target.value))}
                type="number"
              />
              <TextField
                label={t('quantityPallets')}
                defaultValue={elementToEdit.productQuantityPallets || ''}
                onChange={e => handleInputChange('productQuantityPallets', Number(e.target.value))}
                type="number"
              />
              <TextField
                label={t('netWeightPerBox')}
                defaultValue={elementToEdit.productNetWeightPerBox || ''}
                onChange={e => handleInputChange('productNetWeightPerBox', Number(e.target.value))}
                type="number"
              />
              <TextField
                label={t('grossWeightPerBox')}
                defaultValue={elementToEdit.productGrossWeightPerBox || ''}
                onChange={e => handleInputChange('productGrossWeightPerBox', Number(e.target.value))}
                type="number"
              />
              <div>
                <InputLabel htmlFor="date-label">{t('cartonPrintingInfoSentDate')}</InputLabel>
                <TextField
                  id="date-label"
                  defaultValue={elementToEdit.cartonPrintingInfoSentDate || ''}
                  onChange={e => handleInputChange('cartonPrintingInfoSentDate', new Date(e.target.value).getTime())}
                  type="date"
                />
              </div>
              <div>
                <InputLabel id="color-label">{t('color')}</InputLabel>
                <Select
                  labelId="color-label"
                  value={selectedColor}
                  onChange={e => handleSelectChange(e, 'color', options.colorOptions)}
                  label={t('color')}
                  style={{ width: '320px' }}
                >
                  {options.colorOptions.map(option => (
                    <MenuItem key={option.id} value={option.description}>
                      {option.description}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div>
                <InputLabel id="product-type-label">{t('productType')}</InputLabel>
                <Select
                  labelId="product-type-label"
                  value={selectedProductType}
                  onChange={e => handleSelectChange(e, 'productType', options.productTypeOptions)}
                  label={t('productType')}
                  style={{ width: '320px' }}
                >
                  {options.productTypeOptions.map(option => (
                    <MenuItem key={option.id} value={option.description}>
                      {option.description}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div>
                <InputLabel id="item-unit-label">{t('itemUnit')}</InputLabel>
                <Select
                  labelId="item-unit-label"
                  value={selectedItemUnit}
                  onChange={e => handleSelectChange(e, 'itemUnit', options.itemUnitOptions)}
                  label={t('itemUnit')}
                  style={{ width: '320px' }}
                >
                  {options.itemUnitOptions.map(option => (
                    <MenuItem key={option.id} value={option.description}>
                      {option.description}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </FormGroup>
          ) : (
            <Box display="flex" justifyContent="center" flex={1}>
              <CircularProgress size={14} />
            </Box>
          )}
        </DialogContent>
        <DialogActions style={{ margin: '10px' }}>
          <Button onClick={() => handleSave(elementToEdit?.id)} color="primary" variant="outlined" disabled={!elementToEdit?.id}>
            {t('save')}
          </Button>
          <Button onClick={handleClose} color="primary">
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ListEditModal
