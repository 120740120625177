import {format as formatDate} from "date-fns"
import {ListPage} from "../../components/templates/ListPage"
import React from "react";

export const PreOrder = () => {

    const shouldShowIconToDelete = (row) => {
        return !row.closed
    }
    const columns = [
        { id: 'id', label: 'ID', minWidth: 100 },
        { id: 'customer.name', label: 'Cliente', minWidth: 100 },
        { id: 'dateCreated', label: 'Fecha de creación', minWidth: 100, type: 'date', format: (date) => {return formatDate(new Date(date), 'dd/MM/yyyy')} },
        { id: 'closed', label: 'Estado del pedido', minWidth: 100, format: (closed) => {return closed?"Creado":"En proceso"} }
    ]


    return <ListPage createToPath={"/preOrder/create"} columns={columns} fetchUrl={window.REACT_APP_BASE_API_URL + '/preOrder'} editPath={"/preOrder/edit/"} shouldShowIconToDelete={shouldShowIconToDelete}/>
}
