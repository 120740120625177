import Box from '@material-ui/core/Box'
import React, {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {fetchSecure} from "../../../modules/fetchSecure";
import {useHistory} from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import {Button, FormGroup} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import InputField from "../../../components/inputField/InputField";
import ListComponent from "../../../components/listComponent/ListComponent";
import CardActions from "@material-ui/core/CardActions";


function PortTerminalItem(props) {
    const [portTerminal, setPortTerminal] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [savedStatus, setSavedStatus] = useState({saved: false, error: false});
    const [errors, setErrors] = useState({})
    const [t] = useTranslation('common');
    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {},
    });
    const classes = useStyles();

    const handleChange = e => {
        let newPortTerminal = {...portTerminal}
        newPortTerminal[e.target.name] = e.target.value;
        setPortTerminal(newPortTerminal);
    }


    const handleListChange = (listName, listValue) => {
        let newPortTerminal = {...portTerminal}
        newPortTerminal[listName] = listValue;
        setPortTerminal(newPortTerminal);
    }

    const [show, setShow] = useState(props.match.params.objectId != null)
    const handleShow = e => {
        setShow(e.target.checked)
    }


    const handleSave = () => {
        let method = 'POST';
        let url = window.REACT_APP_BASE_API_URL + '/portTerminal';
        if (props.match.params.objectId != null) {
            method = 'PUT';
            url = url + "/" + props.match.params.objectId
        } else {
            portTerminal.version = 0;
        }
        let forwarderInJson = JSON.stringify(portTerminal);
        const requestOptions = {
            method: method,
            headers: {'Content-Type': 'application/json'},
            body: forwarderInJson
        };


        fetchSecure(url, requestOptions)
            .then(async response => {
                    const data = await response.json();
                    if (!response.ok) {

                        setErrors(data.fields == null ? {} : data.fields)
                        const errorMessage = (data && data.message) || response.status;
                        return Promise.reject(errorMessage);
                    } else {
                        setErrors({})
                        props.match.params.objectId = data.id
                        setSavedStatus({saved: true, error: false})
                    }
                }
            ).catch(errorMessage => {
            setSavedStatus({saved: false, error: true, errorMessage: errorMessage})
        });

    }


    useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (props.match.params.objectId != null) {
                fetchSecure(window.REACT_APP_BASE_API_URL + '/portTerminal/' + props.match.params.objectId)
                    .then(response => response.json())
                    .then(data => setPortTerminal(data))
                    .then(() => setLoaded(true));
            }
        }
    }, [errors, loaded, props.match.params.objectId, savedStatus]);


    const containerToRender =
        {
            "elements": [
                {
                    "type": "select",
                    "typeTextField": "select",
                    "id": "container",
                    "label": "Contenedor",
                    "optionValue": "id",
                    "optionShow": "name",
                    "url": window.REACT_APP_BASE_API_URL + '/container',
                    "disabled": show
                },
                {
                    "type": "textField",
                    "typeTextField": "number",
                    "id": "priceTerminal",
                    "label": "Gasto Terminal",
                    "disabled": show
                },
            ]
        }
    let history = useHistory()
    const goToPreviousPath = () => {
        history.goBack()
    }
    return (
        <Paper className={classes.root}>
            <Box m={2}>
                <Button variant="contained" color="primary" onClick={goToPreviousPath}>Listado</Button>
            </Box>
            {savedStatus.error && <Alert severity="error">
                Ha ocurrido un error inesperado al intentar grabar el forwarder
                {Object.keys(errors).map((it) =>
                    <Fragment>
                        <li>
                            {errors[it]}
                        </li>
                    </Fragment>
                )}
            </Alert>
            }

            {savedStatus.saved && <Alert severity="success">El forwarder se ha grabado con éxito</Alert>
            }

            {props.match.params.objectId != null &&
            <Box m={2}>
                <Grid container spacing={3}>
                    <Grid item xs={9}>
                    </Grid>
                    <Grid item xs={2}>
                        {t('button.show')} <Switch defaultChecked={show} color="primary" onClick={handleShow}/>
                    </Grid>
                </Grid>
            </Box>
            }

            <Box m={2}>
                <form className={classes.container} noValidate>
                    <Card className={classes.root} variant="outlined" style={{marginBottom: '16px'}}>
                        <CardContent>
                            <Typography variant="h6">Informacion de la terminal</Typography>
                            <FormGroup row={true}>
                                <InputField disabled={show} error={!!errors.name} name="name" label="Nombre"
                                            value={portTerminal.name} type="text" onChange={handleChange}
                                            className={classes.textField}/>
                            </FormGroup>
                        </CardContent>
                    </Card>

                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h6">{t('informationContainer')}</Typography>
                            <FormGroup row={true}>
                                <ListComponent disabled={show} errorsList={errors} onChangeList={handleListChange}
                                               elementToRender={containerToRender}
                                               elementToUpload={portTerminal.containers} listName={"containers"}/>
                            </FormGroup>
                        </CardContent>
                    </Card>

                    {show === false && <CardActions>
                        <FormGroup row={true}>
                            <Box m={2}>
                                <Button variant="contained" color="primary" onClick={handleSave}>Guardar</Button>
                            </Box>
                        </FormGroup>
                    </CardActions>
                    }
                </form>
            </Box>
        </Paper>
    )
}

export default PortTerminalItem;
