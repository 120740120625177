import React, {useEffect, useState} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import InputField from '../../../components/inputField/InputField.js';
import {Box, Button, Card, CardContent, FormGroup, Paper, TextField, Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {useTableSearch} from "../../../components/search/UseTableSearch";
import TableComponent from "../../../components/table/TableComponent.js";
import {useTranslation} from "react-i18next";
import {fetchSecure} from "../../../modules/fetchSecure";
import LoadingScreen from '../../../components/loading/LoadingScreen.js';


function SubWish(props) {

    const [t] = useTranslation('common');

    const [subWishList, setSubWishList] = useState([]);
    const [searchVal, setSearchVal] = useState(null);
    const [wish, setWish] = useState({});
    const [loading, setLoading] = useState(false);


    const {filteredData} = useTableSearch({
        searchVal,
        retrieve: subWishList
    });

    const columns = [
        {id: 'supplier.name', label: 'Proveedor', minWidth: 100},
        {id: 'deliveryDateConfirmed', label: 'Envio confirmado', minWidth: 100},
        {id: 'itemsQuantity', label: 'Cantidad de items distintos', minWidth: 100},
        {id: 'totalFob', label: 'Total fob', minWidth: 100},
    ];
    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {
            maxHeight: 440,
        },
    });

    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        setLoading(true)
        fetchSecure(window.REACT_APP_BASE_API_URL + '/wish/' + props.match.params.objectId + '/subWish')
            .then(response => response.json())
            .then(data => {
                    data.forEach(
                        (item) => {
                            item.itemsQuantity = item.subWishItemList?.length
                            item.totalFob = item.subWishItemList?.reduce((acc, swi) => acc + swi.quantity * swi.unitPrice, 0)?.toFixed(2)
                        }
                    )

                    setSubWishList(data)
                }
            )
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
        fetchSecure(window.REACT_APP_BASE_API_URL + '/wish/' + props.match.params.objectId)
            .then(response => response.json())
            .then(data => setWish(data));

    }, [page, props.match.params.objectId]);

    const createTo = {
        pathname: "/wish/" + props.match.params.objectId + "/subWish/create",
        wishOpNumber: wish?.opNumber,
        wishCustomerName: wish?.customer?.name,
        wishSupplierOrder: wish?.supplierOrder,
        wishVersion: wish?.version
    };

    if (loading) {
        return <Box display="flex" justifyContent="center" alignItems="center" style={{height: '100vh'}}><LoadingScreen
            backgroundColor={'white'}/></Box>
    } else {
        return <div>

            <Paper className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={9}>
                        <Box m={2}>
                            <TextField
                                onChange={e => setSearchVal(e.target.value)}
                                placeholder="Search"
                                enterButton
                                style={{position: "sticky", top: "0", left: "0"}}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={3} alignItems="left">

                        <Box m={2}>
                            <Link to={createTo}>
                                <Button variant="contained" color="primary">{t('button.create')}</Button>
                            </Link>

                            <Link to={"/wish/edit/" + props.match.params.objectId} style={{marginLeft: 8}}>
                                <Button variant="contained" color="primary">{t('button.wish')}</Button>
                            </Link>

                            <Box style={{marginTop: 8}}><Button color="secondary" variant={'contained'}
                                                                onClick={async () => {
                                                                    fetchSecure(window.REACT_APP_BASE_API_URL + `/wish/${props.match.params.objectId}/download`).then(response => response.blob())
                                                                        .then(blob => {
                                                                            const file = window.URL.createObjectURL(blob)
                                                                            window.location.assign(file)
                                                                        })
                                                                }
                                                                }>{t('button.downloadItems')}</Button>
                            </Box>
                        </Box>

                    </Grid>
                </Grid>


                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Typography variant="h6">{t('wish.information')}</Typography>
                        <FormGroup row={true}>
                            <InputField name="opNumber" label="Numero de pedido" value={wish?.opNumber} type="number"/>
                            <InputField name="customer" label="Cliente" value={wish?.customer?.name} type="text"/>
                            <InputField name="supplierOrder" label="supplierOrder" value={wish?.supplierOrder}
                                        type="text"/>

                        </FormGroup>
                    </CardContent>
                </Card>

                <TableComponent
                    columns={columns}
                    filteredData={filteredData}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    count={subWishList.length}
                    urlEdit={"/wish/" + props.match.params.objectId + "/subWish/edit/"}
                />
            </Paper>


        </div>
    }
}

export default SubWish;
