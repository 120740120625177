import {CostOptimizationService} from "./CostOptimizationService"


const costOptimizationService = new CostOptimizationService()

const BANK_EXPENSES = 100
const DISPATCH_EXPENSES = 0
const GUARANTEES_CONSTITUTION = 0

export class CostCalculatorService {

    applyStatisticalFeeTop(statisticalFee, taxBase) {
        if (taxBase <= 10000) {
            return Math.min(statisticalFee, 180)
        }
        if (taxBase <= 100000) {
            return Math.min(statisticalFee, 3000)
        }
        if (taxBase <= 1000000) {
            return Math.min(statisticalFee, 30000)
        }
        return Math.min(statisticalFee, 150000)
    }

    getCostsPerFullContainer(item, idealContainer, forwarder, adjustmentAmount, port, customsBroker) {
        const itemQuantity = idealContainer.quantity
        const itemPrice = item.price
        const containerQuantity = 1
        const totalFob = itemQuantity * itemPrice

        const forwarderContainer = forwarder.containers.filter(c => c.container.name === idealContainer.containerName)[0]
        const portContainer = port.containers.filter(c => c.container.name === idealContainer.containerName)[0]

        //Expenses to distribute
        const certifiedFreight = containerQuantity * forwarderContainer.certifiedFreight
        const insurance = totalFob * forwarder.insurancePercentage / 100
        const freight = containerQuantity * forwarderContainer.freight
        const maritimeExpenses = containerQuantity * forwarderContainer.maritimeExpenses
        const priceTerminal = containerQuantity * portContainer.priceTerminal

        const extrasTaxBase = certifiedFreight + insurance + adjustmentAmount
        const extrasTax = extrasTaxBase * (item.product.positionArancel.ivaPercentage + item.product.positionArancel.ivaPerceptionPercentage + item.product.positionArancel.earningsAdvancePercentage + item.product.positionArancel.iibbPerceptionPercentage) / 100

        const bankExpenses = BANK_EXPENSES
        const dispatchDigitalization = customsBroker.digitization
        const dispatchExpenses = DISPATCH_EXPENSES
        const guaranteesConstitution = GUARANTEES_CONSTITUTION
        const customsBrokerFee = Math.max((totalFob + certifiedFreight + insurance) / 100, customsBroker.minimumFee ?? 0)
        const companyFee = Math.max(totalFob * 3 / 100, 300)
        const haulage = customsBroker.haulage

        //Expenses per item
        const itemRights = itemPrice * item.product.positionArancel.rights / 100
        const itemStatisticalFee = itemPrice * item.product.positionArancel.statisticalFee / 100
        const itemTaxBase = itemPrice + itemRights + itemStatisticalFee
        const productIva = itemTaxBase * item.product.positionArancel.ivaPercentage / 100
        const productIvaPerception = itemTaxBase * item.product.positionArancel.ivaPerceptionPercentage / 100
        const productIncomeTaxAdvancement = itemTaxBase * item.product.positionArancel.earningsAdvancePercentage / 100
        const productIibbPerception = itemTaxBase * item.product.positionArancel.iibbPerceptionPercentage / 100

        // TODO: a distribuir por peso y volumen: gastos de despacho y todo lo que depende del contenedor (terminal portuaria por ejemplo)
        const totalCosts = certifiedFreight
            + insurance
            + adjustmentAmount
            + freight
            + maritimeExpenses
            + dispatchDigitalization
            + priceTerminal
            + bankExpenses
            + customsBrokerFee
            + dispatchExpenses
            + guaranteesConstitution
            + companyFee
            + haulage

        const unitRealCostPerFullContainer = itemPrice + totalCosts / itemQuantity

        const unitFiscalCreditPerFullContainer = productIva + productIvaPerception + productIncomeTaxAdvancement + productIibbPerception
            + extrasTax / item.quantity

        return {
            unitRealCostPerFullContainer,
            unitFiscalCreditPerFullContainer,
            unitFinancialCostPerFullContainer: unitRealCostPerFullContainer + unitFiscalCreditPerFullContainer
        }
    }


    async calculateCost(preOrderItemsResult, forwarder, port, optimizationResult, customsBroker, adjustmentAmount) {

        const errors = []

        const calculatedCost = {}


        let totalVolumeAvailable = 0
        let totalWeightAvailable = 0
        let totalVolumeUsed = 0
        let totalWeightUsed = 0
        const containerNames = Object.keys(optimizationResult.containers)
        const containerList = containerNames.map(containerName => {
            const containerQuantity = optimizationResult.result.variables[containerName]
            totalVolumeAvailable += containerQuantity * optimizationResult.containers[containerName].volume
            totalWeightAvailable += containerQuantity * optimizationResult.containers[containerName].weight
            totalVolumeUsed += totalVolumeAvailable - optimizationResult.result.slack[containerName + '_volume']
            totalWeightUsed += totalWeightAvailable - optimizationResult.result.slack[containerName + '_weight']
            return {containerName, quantity: containerQuantity}
        }).filter(container => container.quantity > 0)
        const percentageVolumeUsed = totalVolumeUsed / totalVolumeAvailable
        const percentageWeightUsed = totalWeightUsed / totalWeightAvailable
        const normalizedPercentageVolumeUsed = percentageVolumeUsed / (percentageVolumeUsed + percentageWeightUsed)
        const normalizedPercentageWeightUsed = percentageWeightUsed / (percentageVolumeUsed + percentageWeightUsed)


        calculatedCost.totalFob = preOrderItemsResult.calculatedAmount

        const forwarderExpenses = containerList.reduce((currentTotals, container) => {
            const containerQuantity = container.quantity
            const forwarderContainer = forwarder.containers.filter(c => c.container.name === container.containerName)[0]

            currentTotals.certifiedFreight = currentTotals.certifiedFreight + containerQuantity * forwarderContainer.certifiedFreight
            currentTotals.insurance = calculatedCost.totalFob * forwarder.insurancePercentage / 100
            currentTotals.freight = currentTotals.freight + containerQuantity * forwarderContainer.freight
            currentTotals.maritimeExpenses = currentTotals.maritimeExpenses + containerQuantity * forwarderContainer.maritimeExpenses

            return currentTotals
        }, {certifiedFreight: 0, insurance: 0, freight: 0, maritimeExpenses: 0})

        calculatedCost.certifiedFreight = forwarderExpenses.certifiedFreight
        calculatedCost.insurance = forwarderExpenses.insurance
        calculatedCost.adjustmentAmount = Number(adjustmentAmount)

        const portExpenses = containerList.reduce((currentTotals, container) => {
            const containerQuantity = container.quantity
            const portContainer = port.containers.filter(c => c.container.name === container.containerName)[0]

            currentTotals.priceTerminal = currentTotals.priceTerminal + containerQuantity * portContainer.priceTerminal

            return currentTotals
        }, {priceTerminal: 0})

        let totalExtrasTax = 0

        preOrderItemsResult.items.forEach(item => {
            const productPrice = item.price

            const extrasTaxBase = (calculatedCost.certifiedFreight + calculatedCost.insurance + calculatedCost.adjustmentAmount) / calculatedCost.totalFob * item.quantity * productPrice
            const extrasTax = extrasTaxBase * (item.product.positionArancel.ivaPercentage + item.product.positionArancel.ivaPerceptionPercentage + item.product.positionArancel.earningsAdvancePercentage + item.product.positionArancel.iibbPerceptionPercentage) / 100
            totalExtrasTax += extrasTax

            const itemRights = (item.quantity * productPrice + extrasTaxBase) * item.product.positionArancel.rights / 100
            const itemStatisticalFee = (item.quantity * productPrice + extrasTaxBase) * item.product.positionArancel.statisticalFee / 100

            const itemTaxBase = item.quantity * productPrice + extrasTaxBase + itemRights + itemStatisticalFee

            const totalTaxBase = itemTaxBase

            item.itemRights = itemRights
            item.itemStatisticalFee = itemStatisticalFee

            item.baseProductIva = itemTaxBase * item.product.positionArancel.ivaPercentage / 100
            item.baseProductIvaPerception = itemTaxBase * item.product.positionArancel.ivaPerceptionPercentage / 100
            item.baseProductIncomeTaxAdvancement = itemTaxBase * item.product.positionArancel.earningsAdvancePercentage / 100
            item.baseProductIibbPerception = itemTaxBase * item.product.positionArancel.iibbPerceptionPercentage / 100

            item.productIva = totalTaxBase * item.product.positionArancel.ivaPercentage / 100
            item.productIvaPerception = totalTaxBase * item.product.positionArancel.ivaPerceptionPercentage / 100
            item.productIncomeTaxAdvancement = totalTaxBase * item.product.positionArancel.earningsAdvancePercentage / 100
            item.productIibbPerception = totalTaxBase * item.product.positionArancel.iibbPerceptionPercentage / 100
        })


        const productExpenses = preOrderItemsResult.items.reduce((currentTotals, item) => {

            currentTotals.rights = currentTotals.rights + item.itemRights
            currentTotals.statisticalFee = currentTotals.statisticalFee + item.itemStatisticalFee


            if (!item.product.positionArancel.ivaPercentage) {
                errors.push(`El producto ${item.product.descriptionSP} no tiene definidos todos los impuestos`)
            }

            currentTotals.iva += item.productIva
            currentTotals.ivaPerception += item.productIvaPerception
            currentTotals.incomeTaxAdvancement += item.productIncomeTaxAdvancement
            currentTotals.iibbPerception += item.productIibbPerception

            return currentTotals

        }, {rights: 0, statisticalFee: 0, iva: 0, ivaPerception: 0, incomeTaxAdvancement: 0, iibbPerception: 0})


        calculatedCost.certifiedFreight = forwarderExpenses.certifiedFreight
        calculatedCost.insurance = forwarderExpenses.insurance
        calculatedCost.adjustmentAmount = Number(adjustmentAmount)
        calculatedCost.taxBase = calculatedCost.totalFob + calculatedCost.certifiedFreight + calculatedCost.insurance + calculatedCost.adjustmentAmount
        calculatedCost.rights = productExpenses.rights
        calculatedCost.statisticalRate = this.applyStatisticalFeeTop(productExpenses.statisticalFee, calculatedCost.taxBase)
        calculatedCost.baseForTaxes = calculatedCost.taxBase + calculatedCost.rights + calculatedCost.statisticalRate

        calculatedCost.iva = productExpenses.iva
        calculatedCost.ivaPerception = productExpenses.ivaPerception
        calculatedCost.incomeTaxAdvancement = productExpenses.incomeTaxAdvancement
        calculatedCost.iibbPerception = productExpenses.iibbPerception

        calculatedCost.customsCost = calculatedCost.rights + calculatedCost.statisticalRate
        calculatedCost.customsFiscalCredit = calculatedCost.iva + calculatedCost.ivaPerception + calculatedCost.incomeTaxAdvancement + calculatedCost.iibbPerception
        calculatedCost.freight = forwarderExpenses.freight
        calculatedCost.agencyExpenses = forwarderExpenses.maritimeExpenses

        calculatedCost.dispatchDigitalization = customsBroker.digitization
        calculatedCost.portExpenses = portExpenses.priceTerminal
        calculatedCost.bankExpenses = BANK_EXPENSES
        calculatedCost.customsBrokerFee = Math.max((calculatedCost.totalFob + calculatedCost.certifiedFreight + calculatedCost.insurance) * 1 / 100, customsBroker.minimumFee ?? 0)

        calculatedCost.dispatchExpenses = DISPATCH_EXPENSES
        calculatedCost.guaranteesConstitution = GUARANTEES_CONSTITUTION

        calculatedCost.companyFee = Math.max(calculatedCost.totalFob * 3 / 100, 400)
        calculatedCost.haulage = customsBroker.haulage //ESTO NO INCLUYE IVA, HAY QUE SUMARLE EL 21%

        calculatedCost.errors = errors

        const totalCosts = calculatedCost.certifiedFreight
            + calculatedCost.insurance
            + calculatedCost.adjustmentAmount
            + calculatedCost.customsCost
            + calculatedCost.freight
            + calculatedCost.agencyExpenses
            + calculatedCost.dispatchDigitalization
            + calculatedCost.portExpenses
            + calculatedCost.bankExpenses
            + calculatedCost.customsBrokerFee
            + calculatedCost.dispatchExpenses
            + calculatedCost.guaranteesConstitution
            + calculatedCost.companyFee
            + calculatedCost.haulage

        const calculatedCostPerItem = preOrderItemsResult.items.map(item => {
            const productPrice = item.price

            const productSupplier = item.product.productSupplierList.filter(productSupplierElement => productSupplierElement.supplier.id === item.supplierId)[0]
            const productWeight = productSupplier?.grossWeightPerBox / productSupplier?.quantityArtByExternalBox
            const productVolume = productSupplier?.height * productSupplier?.width * productSupplier?.length / 1000000000 / productSupplier?.quantityArtByExternalBox
            const costPercentageAssigned = (productWeight / totalWeightUsed) * normalizedPercentageWeightUsed + (productVolume / totalVolumeUsed) * normalizedPercentageVolumeUsed


            const unitFiscalCredit = (item.baseProductIva + item.baseProductIvaPerception + item.baseProductIncomeTaxAdvancement + item.baseProductIibbPerception) / item.quantity
                + totalExtrasTax * costPercentageAssigned

            const unitRealCost = productPrice + totalCosts * costPercentageAssigned


            const idealContainer = costOptimizationService.getIdealContainer(item, forwarder, port)
            const {
                unitRealCostPerFullContainer,
                unitFiscalCreditPerFullContainer,
                unitFinancialCostPerFullContainer
            } = this.getCostsPerFullContainer(item, idealContainer, forwarder, calculatedCost.adjustmentAmount, port, customsBroker)


            return {
                productCode: item.product.codePerCustomerList[0].code,
                description: item.product.descriptionSP,
                quantity: item.quantity,
                price: productPrice,
                totalFob: item.quantity * productPrice,

                unitRealCost: unitRealCost,
                unitFiscalCredit: unitFiscalCredit,
                unitFinancialCost: unitRealCost + unitFiscalCredit,

                idealContainerName: idealContainer.containerName,
                idealContainerQuantity: idealContainer.quantity,

                unitRealCostPerFullContainer,
                unitFiscalCreditPerFullContainer,
                unitFinancialCostPerFullContainer
            }

        })


        return {calculatedCostSummary: calculatedCost, calculatedCostPerItem}
    }
}
