import React, {useEffect, useState} from 'react';
import {Box, FormControl, InputLabel, MenuItem, Select} from '@material-ui/core';

import {useTranslation} from "react-i18next";
import {fetchSecure} from "../../modules/fetchSecure";

function SelectField({
                         name,
                         value,
                         changeHandler,
                         className,
                         url,
                         optionValue,
                         optionShow,
                         error,
                         disabled,
                         data = [],
                         fieldLabel = null,
                     }) {

    const [optionsList, setOptionsList] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const [t] = useTranslation('common');

    useEffect(() => {
        if (url !== undefined) {
            fetchSecure(url)
                .then(response => response.json())
                .then(data => {
                    setOptionsList(data)
                })
                .then(() => setLoaded(true))
        } else {
            setOptionsList(data)
        }
    }, [loaded, url]);

    const handleChange = e => {
        let element = {};
        element[optionValue] = e.target.value;
        element.version = optionsList.find(element => element[optionValue] === e.target.value)?.version;

        changeHandler(name, element)
    }

    const labelId = name + '-label';

    let currentValue = "";
    if (value != null && optionsList.length > 0) {
        currentValue = value[optionValue];
    }
    return <FormControl><Box style={{display: 'flex', flexDirection: 'column', marginBottom: '16px'}}>
        <InputLabel shrink id={labelId}>
            {fieldLabel ?? t(name)}
        </InputLabel>
        <Select style={{width: 200}} disabled={disabled} error={error} labelId="labelId" displayEmpty={true} id={name}
                name={name} value={currentValue} className={className} onChange={handleChange}>
            <MenuItem key={-1} value="">Por favor elija una opción</MenuItem>

            {optionsList.map((it, index) =>
                <MenuItem key={index} value={it[optionValue]}>{it[optionShow]}</MenuItem>
            )}
        </Select>
    </Box>
    </FormControl>

}

export default SelectField
