import InputField from '../../components/inputField/InputField.js';
import SelectField from '../../components/selectField/SelectField.js';
import DateField from '../../components/dateField/DateField.js';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import {Button, FormGroup} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import React, {Fragment, useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import {useTranslation} from "react-i18next";
import {fetchSecure} from "../../modules/fetchSecure";
import {useHistory} from "react-router-dom";



function LetterOfGuaranteeItem(props) {

    const [letterOfGuarantee, setLetterOfGuarantee] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [savedStatus, setSavedStatus] = useState({saved: false, error: false});
    const [errors, setErrors] = useState({})
    const [t] = useTranslation('common');
    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {},
    });
    const classes = useStyles();

    const handleChange = e => {
        let newLetterOfGuarantee = {...letterOfGuarantee}
        newLetterOfGuarantee[e.target.name] = e.target.value;
        setLetterOfGuarantee(newLetterOfGuarantee);
    }

    const handleListChange = (listName, listValue) => {
        let newLetterOfGuarantee = {...letterOfGuarantee}
        newLetterOfGuarantee[listName] = listValue;
        setLetterOfGuarantee(newLetterOfGuarantee);
    }


    const handleSave = () => {
        let method = 'POST';
        let url = window.REACT_APP_BASE_API_URL + '/letterOfGuarantee';
        if (props.match.params.objectId != null) {
            method = 'PUT';
            url = url + "/" + props.match.params.objectId
        } else {
            letterOfGuarantee.version = 0;
        }
        let letterOfGuaranteeInJson = JSON.stringify(letterOfGuarantee);
        const requestOptions = {
            method: method,
            headers: {'Content-Type': 'application/json'},
            body: letterOfGuaranteeInJson
        };


        fetchSecure(url, requestOptions)
            .then(async response => {
                    const data = await response.json();
                    if (!response.ok) {

                        setErrors(data.fields == null ? {} : data.fields)
                        const errorMessage = (data && data.message) || response.status;
                        return Promise.reject(errorMessage);
                    } else {
                        setErrors({})
                        props.match.params.objectId = data.id
                        setSavedStatus({saved: true, error: false})
                    }
                }
            ).catch(errorMessage => {
            setSavedStatus({saved: false, error: true, errorMessage: errorMessage})
        });

    }


    useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (props.match.params.objectId != null) {
                fetchSecure(window.REACT_APP_BASE_API_URL + '/letterOfGuarantee/' + props.match.params.objectId)
                    .then(response => response.json())
                    .then(data => setLetterOfGuarantee(data))
                    .then(setLoaded(true));
            }
        }
    }, [errors, loaded, props.match.params.objectId, savedStatus]);

    let history = useHistory()
    const goToPreviousPath = () => {
        history.goBack()
    }
    return (
        <Paper className={classes.root}>
            <Box m={2}>
                <Button variant="contained" color="primary" onClick={goToPreviousPath}>Volver</Button>
            </Box>
            {savedStatus.error && <Alert severity="error">
                Ha ocurrido un error inesperado al intentar grabar la carta de garantía
                {Object.keys(errors).map((it) =>
                    <Fragment>
                        <li>
                            {errors[it]}
                        </li>
                    </Fragment>
                )}
            </Alert>
            }

            {savedStatus.saved && <Alert severity="success">La carta de garantía se ha grabado con éxito</Alert>
            }

            <Box m={2}>
                <form className={classes.container} noValidate>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h6">{t('agentInformation')}</Typography>
                            <FormGroup row={true}>
                                <InputField error={!!errors.year} name="year" label="Año" value={letterOfGuarantee.year}
                                            type="text" onChange={handleChange} className={classes.textField}/>
                                <SelectField error={!!errors.customer} name="customer" label="Cliente"
                                             value={letterOfGuarantee.customer} changeHandler={handleListChange}
                                             className={classes.textField}
                                             url={window.REACT_APP_BASE_API_URL + '/customer'} optionValue="id"
                                             optionShow="name"/>
                                <SelectField error={!!errors.forwarder} name="forwarder" label="Forwarder"
                                             value={letterOfGuarantee.forwarder} changeHandler={handleListChange}
                                             className={classes.textField}
                                             url={window.REACT_APP_BASE_API_URL + '/forwarder'} optionValue="id"
                                             optionShow="name"/>
                                <DateField error={!!errors.deliveryDate} forceLabel="Fecha de envío" name="deliveryDate"
                                           value={letterOfGuarantee.deliveryDate} onChange={handleListChange}
                                           className={classes.textField}/>
                            </FormGroup>
                        </CardContent>
                    </Card>

                    <CardActions>
                        <FormGroup row={true}>
                            <Box m={2}>
                                <Button variant="contained" color="primary" onClick={handleSave}>Guardar</Button>
                            </Box>
                        </FormGroup>
                    </CardActions>
                </form>
            </Box>

        </Paper>
    )
}

export default LetterOfGuaranteeItem;
