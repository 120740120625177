import React from 'react';
import {useTranslation} from "react-i18next";
import spainFlag from '../../espana.png';
import eeuuFlag from '../../estados-unidos-de-america.png';

function HeaderComponent()
{
    const [,i18n] = useTranslation('common');

    return <div>
        <button onClick={() => i18n.changeLanguage('es')}><img src={spainFlag} alt={spainFlag} /></button>
        <button onClick={() => i18n.changeLanguage('en')}><img src={eeuuFlag} alt={eeuuFlag} /></button>
    </div>
}

export default HeaderComponent;
