import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import {Button, Fab, FormGroup} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React, {Fragment, useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import {useTranslation} from "react-i18next";
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import {useHistory} from "react-router-dom";
import {fetchSecure} from "../../modules/fetchSecure";
import InputField from "../../components/inputField/InputField";
import Snackbar from "@material-ui/core/Snackbar";


function DocumenTypeItem(props) {

    const [maritimeLine, setMaritimeLine] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [savedStatus, setSavedStatus] = useState({saved: false, error: false});
    const [errors, setErrors] = useState({})
    const [t] = useTranslation('common')
    const [open, setOpen] = useState(false)

    const handleChange = e => {
        let newMaritimeLine = {...maritimeLine}
        newMaritimeLine[e.target.name] = e.target.value;
        setMaritimeLine(newMaritimeLine);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const [show, setShow] = useState(props.match.params.objectId != null)
    const handleShow = e => {
        setShow(e.target.checked)
    }


    const handleSave = async () => {
        let method = 'POST';
        let url = window.REACT_APP_BASE_API_URL + '/maritimeLine';
        if (props.match.params.objectId != null) {
            method = 'PUT';
            url = url + "/" + props.match.params.objectId
        } else {
            maritimeLine.version = 0;

        }
        let maritimeLineInJson = JSON.stringify(maritimeLine);
        const requestOptions = {
            method: method,
            headers: {'Content-Type': 'application/json'},
            body: maritimeLineInJson
        };

        try {
            const response = await fetchSecure(url, requestOptions)
            const data = await response.json();
            if (!response.ok) {
                setErrors(data.fields == null ? {} : data.fields)
                const errorMessage = (data && data.message) || response.status;
                setOpen(true)
                return Promise.reject(errorMessage)

            } else {
                setErrors({})
                props.match.params.objectId = data.id
                setSavedStatus({saved: true, error: false})
                setOpen(true);
            }
        } catch (errorMessage) {

            setSavedStatus({saved: false, error: true, errorMessage: errorMessage})
        }

    }

    useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (props.match.params.objectId != null) {
                fetchSecure(window.REACT_APP_BASE_API_URL + '/maritimeLine/' + props.match.params.objectId)
                    .then(response => response.json())
                    .then(data => setMaritimeLine(data))
                    .then(() => setLoaded(true))
            }
        }
    }, [errors, loaded, props.match.params.objectId, savedStatus]);

    let history = useHistory()
    const goToPreviousPath = () => {
        history.goBack()
    }
    return (
        <Paper>
            <Box m={2}>
                <Button variant="contained" color="primary" onClick={goToPreviousPath}>Listado</Button>
            </Box>
            {savedStatus.error &&
            <Snackbar open={open} onClose={handleClose}>
                <Alert onClose={handleClose} variant="filled" severity="error">
                    Ha ocurrido un error inesperado al intentar grabar el pedido
                    {Object.keys(errors).map((it) =>
                        <Fragment>
                            <li>
                                {errors[it]}
                            </li>
                        </Fragment>
                    )}
                </Alert>
            </Snackbar>
            }

            {savedStatus.saved &&
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} variant="filled" severity="success">El objeto se ha grabado con
                    éxito</Alert>
            </Snackbar>
            }

            <Box m={2}>
                <Grid container spacing={3}>
                    <Grid item xs={9}>
                    </Grid>
                    <Grid item xs={2}>
                        {t('button.show')}<Switch defaultChecked={show} color="primary" onClick={handleShow}/>
                    </Grid>
                </Grid>
            </Box>

            <Box m={2}>
                <form noValidate>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h6">{t('menu.maritimeLine')}</Typography>
                            <FormGroup row={true}>
                                <InputField disabled={show} error={!!errors.name} name="name"
                                            value={maritimeLine.name} type="text" onChange={handleChange}
                                />

                            </FormGroup>
                        </CardContent>
                    </Card>


                    {show === false &&
                    <Fab variant="extended" color="primary" onClick={handleSave}
                         style={{
                             position: 'fixed',
                             bottom: '16px',
                             right: '16px'
                         }}>Guardar</Fab>
                    }
                </form>
            </Box>

        </Paper>
    )
}

export default DocumenTypeItem;
