import React, {useEffect, useState} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import {Box, Button, Paper, TextField} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import {useTableSearch} from "../../../components/search/UseTableSearch";
import TableComponent from "../../../components/table/TableComponent.js";
import Grid from '@material-ui/core/Grid';
import {Link} from "react-router-dom";
import {format as formatDate, subHours} from "date-fns";
import {fetchSecure} from "../../../modules/fetchSecure";
import LoadingScreen from '../../../components/loading/LoadingScreen.js';


function ReportSend() {

	const [sendReportList, setReportList] = useState([]);
	const [loading, setLoading] = useState(false);


	const [t] = useTranslation('common');
	const [searchVal, setSearchVal] = useState(null);

	const { filteredData } = useTableSearch({
		searchVal,
		retrieve: sendReportList
	});

	const createTo = {
		pathname: "/reportSendConfiguration/create",
	};

	const columns = [
		{ id: 'id', label: 'ID', minWidth: 50 },
		{ id: 'report.name', label: 'Reporte', minWidth: 170 },
		{ id: 'stakeholder.name', label: 'Interesado', minWidth: 170 },
		{ id: 'lastSentDate', label: 'Fecha de último envío', minWidth: 150, type: 'date', format: (date) => {return formatDate(subHours(new Date(date), 3), 'dd/MM/yyyy HH:mm:ss')} },
		{ id: 'nextSendDate', label: 'Fecha de próximo envío', minWidth: 150, type: 'date', format: (date) => {return formatDate(new Date(date), 'dd/MM/yyyy')}  },
		{ id: 'frequencyInDays', label: 'Nombre', minWidth: 100 },
		{ id: 'hour', label: 'Hora', minWidth: 150 },
		{ id: 'active', label: 'Hora', minWidth: 100, type: 'boolean' }


	];
	const useStyles = makeStyles({
		root: {
			width: '100%',
		},
		container: {
			maxHeight: 440,
		},
	});

	const classes = useStyles();
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(100);
	const handleChangePage = (_, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	useEffect(() => {
		setLoading(true)
		fetchSecure(window.REACT_APP_BASE_API_URL+'/reportSendConfiguration')
			.then(response => {
				return response.json()})
			.then(data => setReportList(data))
			.then(() => setLoading(false))
			.catch(() => setLoading(false));

	}, [page]);

	if (loading) {
		return <Box display="flex" justifyContent="center" alignItems="center" style={{height: '100vh'}}><LoadingScreen backgroundColor={'white'}/></Box>
	} else {
		return <div>
			<Paper className={classes.root}>
				<Grid container spacing={3}>
					<Grid item xs={9}>
						<Box m={2}>
							<TextField
								onChange={e => setSearchVal(e.target.value)}
								placeholder="Search"
								enterButton
								style={{ position: "sticky", top: "0", left: "0" }}
							/>
						</Box>
					</Grid>
					<Grid item xs={3} alignItems="left">

						<Box m={2} >
							<Link to={createTo}>
								<Button variant="contained" color="primary">{t('button.create')}</Button>
							</Link>
						</Box>

					</Grid>
				</Grid>

				<TableComponent
	columns={columns}
	filteredData={filteredData}
	handleChangePage={handleChangePage}
	handleChangeRowsPerPage={handleChangeRowsPerPage}
	page={page}
	rowsPerPage={rowsPerPage}
	count={sendReportList.length}
	urlEdit={"/reportSendConfiguration/edit/"}
	/>
			</Paper>


		</div>
	}

}

export default ReportSend;
