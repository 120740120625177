import Snackbar from "@material-ui/core/Snackbar"
import Alert from "@material-ui/lab/Alert"


export const SnackbarMessage = ({open, handleClose, severity, message}) => {

    return <Snackbar open={open} onClose={handleClose}>
        <Alert onClose={handleClose} variant="filled" severity={severity}>
            {message}
        </Alert>
    </Snackbar>
}
