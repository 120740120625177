import InputField from '../../../components/inputField/InputField.js';
import SelectField from '../../../components/selectField/SelectField.js';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import {Button, FormGroup} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import React, {Fragment, useEffect, useState} from 'react';
import ListComponent from '../../../components/listComponent/ListComponent.js';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import {useTranslation} from "react-i18next";
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import {useHistory} from "react-router-dom";
import {fetchSecure} from "../../../modules/fetchSecure";
import {StakeholderReportDownload} from "../components/StakeholderReportDownload";


function CustomsBrokerItem(props) {
    const [customerBroker, setCustomerBroker] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [savedStatus, setSavedStatus] = useState({saved: false, error: false});
    const [errors, setErrors] = useState({})
    const [t] = useTranslation('common');
    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {},
    });
    const classes = useStyles();

    const handleChange = e => {
        let newCustomerBroker = {...customerBroker}
        newCustomerBroker[e.target.name] = e.target.value;
        setCustomerBroker(newCustomerBroker);
    }

    const handleListChange = (listName, listValue) => {
        let newCustomerBroker = {...customerBroker}
        newCustomerBroker[listName] = listValue;
        setCustomerBroker(newCustomerBroker);
    }


    const [show, setShow] = useState(props.match.params.objectId != null)
    const handleShow = e => {
        setShow(e.target.checked)
    }


    const handleSave = () => {
        let method = 'POST';
        let url = window.REACT_APP_BASE_API_URL + '/customsBroker';
        if (props.match.params.objectId != null) {
            method = 'PUT';
            url = url + "/" + props.match.params.objectId
        } else {
            customerBroker.version = 0;
        }
        let customerBrokerInJson = JSON.stringify(customerBroker);
        const requestOptions = {
            method: method,
            headers: {'Content-Type': 'application/json'},
            body: customerBrokerInJson
        };

        fetchSecure(url, requestOptions)
            .then(async response => {
                    const data = await response.json();
                    if (!response.ok) {

                        setErrors(data.fields == null ? {} : data.fields)
                        const errorMessage = (data && data.message) || response.status;
                        return Promise.reject(errorMessage);
                    } else {
                        setErrors({})
                        props.match.params.objectId = data.id
                        setSavedStatus({saved: true, error: false})
                    }
                }
            ).catch(errorMessage => {
            setSavedStatus({saved: false, error: true, errorMessage: errorMessage})
        });

    }


    useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (props.match.params.objectId != null) {
                fetchSecure(window.REACT_APP_BASE_API_URL + '/customsBroker/' + props.match.params.objectId)
                    .then(response => response.json())
                    .then(data => setCustomerBroker(data))
                    .then(() => setLoaded(true));
            }
        }
    }, [errors, loaded, props.match.params.objectId, props.match.params.readonly, savedStatus]);

    const fieldsToRender =
        {
            "elements": [
                {"type": "textField", "typeTextField": "text", "id": "name", "label": "Nombre", "disabled": show},
                {"type": "textField", "typeTextField": "text", "id": "email", "label": "Email", "disabled": show},
                {
                    "type": "textField",
                    "typeTextField": "text",
                    "id": "cellPhone",
                    "label": "Cell Phone",
                    "disabled": show
                },
                {"type": "textField", "typeTextField": "text", "id": "position", "label": "Posición", "disabled": show},
                {
                    "type": "checkBox",
                    "typeTextField": "checkBox",
                    "id": "sendAlerts",
                    "label": "Enviar alertas?",
                    "disabled": show
                }
            ]
        }
    let history = useHistory()
    const goToPreviousPath = () => {
        history.goBack()
    }
    return (
        <Paper className={classes.root}>
            <Box m={2}>
                <Button variant="contained" color="primary" onClick={goToPreviousPath}>Listado</Button>
            </Box>
            {savedStatus.error && <Alert severity="error">
                Ha ocurrido un error inesperado al intentar grabar el customsBroker
                {Object.keys(errors).map((it) =>
                    <Fragment>
                        <li>
                            {errors[it]}
                        </li>
                    </Fragment>
                )}
            </Alert>
            }

            {savedStatus.saved && <Alert severity="success">El customsBroker se ha grabado con éxito</Alert>
            }

            {props.match.params.objectId != null &&
            <Box m={2}>
                <Grid container spacing={3}>
                    <Grid item xs={9}>
                    </Grid>
                    <Grid item xs={2}>
                        {t('button.show')} <Switch defaultChecked={show} color="primary" onClick={handleShow}/>
                    </Grid>
                </Grid>
            </Box>
            }

            <Box m={2}>
                <form className={classes.container} noValidate>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h6">{t('agentInformation')}</Typography>
                            <FormGroup row={true}>
                                <InputField disabled={show} error={!!errors.name} name="name" label="Nombre"
                                            value={customerBroker.name} type="text" onChange={handleChange}
                                            className={classes.textField}/>
                                <InputField disabled={show} error={!!errors.telephone} name="telephone" label="Teléfono"
                                            value={customerBroker.telephone} type="number" onChange={handleChange}
                                            className={classes.textField}/>
                                <InputField disabled={show} error={!!errors.email} name="email" label="Email"
                                            value={customerBroker.email} type="text" onChange={handleChange}
                                            className={classes.textField}/>
                                <InputField disabled={show} error={!!errors.notes} name="notes" label="Notas"
                                            value={customerBroker.notes} type="text" onChange={handleChange}
                                            className={classes.textField}/>
                                <InputField disabled={show} error={!!errors.address} name="address" label="Dirección"
                                            value={customerBroker.address} type="text" onChange={handleChange}
                                            className={classes.textField}/>
                            </FormGroup>
                        </CardContent>
                        <CardContent>
                            <FormGroup row={true}>
                                <InputField disabled={show} error={!!errors.cuit} name="cuit" label="Cuit"
                                            value={customerBroker.cuit} type="text" onChange={handleChange}
                                            className={classes.textField}/>
                                <InputField disabled={show} error={!!errors.province} name="province" label="Provincia"
                                            value={customerBroker.province} type="text" onChange={handleChange}
                                            className={classes.textField}/>
                                <InputField disabled={show} error={!!errors.locality} name="locality" label="Localidad"
                                            value={customerBroker.locality} type="text" onChange={handleChange}
                                            className={classes.textField}/>
                                <SelectField disabled={show} error={!!errors.country} name="country" label="country"
                                             value={customerBroker.country} changeHandler={handleListChange}
                                             className={classes.country}
                                             url={window.REACT_APP_BASE_API_URL + '/country'} optionValue="id"
                                             optionShow="name"/>
                                <SelectField disabled={show} error={!!errors.defaultLocale} name="defaultLocale"
                                             label="defaultLocale" value={customerBroker.defaultLocale}
                                             changeHandler={handleListChange} className={classes.defaultLocale}
                                             url={window.REACT_APP_BASE_API_URL + '/availableLocale'} optionValue="id"
                                             optionShow="language"/>
                                <SelectField disabled={show} s error={!!errors.defaultReport} name="defaultReport"
                                             label="defaultReport" value={customerBroker.defaultReport}
                                             changeHandler={handleListChange} className={classes.defaultReport}
                                             url={window.REACT_APP_BASE_API_URL + '/report'} optionValue="id"
                                             optionShow="name"/>
                            </FormGroup>
                        </CardContent>
                        <CardContent>
                            <FormGroup row={true}>
                                <InputField disabled={show} error={!!errors.minimumFee} name="minimumFee"
                                            value={customerBroker.minimumFee} type="number" onChange={handleChange}
                                            className={classes.textField}/>
                                <InputField disabled={show} error={!!errors.haulage} name="haulage"
                                            value={customerBroker.haulage} type="number" onChange={handleChange}
                                            className={classes.textField}/>
                                <InputField disabled={show} error={!!errors.digitization} name="digitization"
                                            value={customerBroker.digitization} type="number" onChange={handleChange}
                                            className={classes.textField}/>
                                <InputField disabled={show} error={!!errors.portTerminal} name="portTerminal"
                                            value={customerBroker.portTerminal} type="number" onChange={handleChange}
                                            className={classes.textField}/>

                                <InputField disabled={show} error={!!errors.shippingCosts} name="shippingCosts"
                                            value={customerBroker.shippingCosts} type="number" onChange={handleChange}
                                            className={classes.textField}/>
                                <InputField disabled={show} error={!!errors.constitutionOfGuarantees}
                                            name="constitutionOfGuarantees"
                                            value={customerBroker.constitutionOfGuarantees} type="number"
                                            onChange={handleChange}
                                            className={classes.textField}/>

                            </FormGroup>
                        </CardContent>
                    </Card>

                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h6">{t('informationContacts')}</Typography>
                            <FormGroup row={true}>
                                <ListComponent disabled={show} errorsList={errors} onChangeList={handleListChange}
                                               elementToRender={fieldsToRender}
                                               elementToUpload={customerBroker.contacts} listName={"contacts"}/>
                            </FormGroup>
                        </CardContent>
                    </Card>

                    {show === false && <CardActions>
                        <FormGroup row={true}>
                            <Box m={2}>
                                <Button variant="contained" color="primary" onClick={handleSave}>Guardar</Button>
                            </Box>
                        </FormGroup>
                    </CardActions>
                    }
                </form>

                <StakeholderReportDownload stakeholderId={customerBroker.id}/>
            </Box>

        </Paper>
    )
}

export default CustomsBrokerItem;
