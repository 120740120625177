import { useEffect, useState } from 'react'

import Menu from './components/menu/Menu'

import Home from './scenes/home/Home'

import PaymentStatus from './scenes/orders/paymentStatus/PaymentStatus'
import PaymentStatusItem from './scenes/orders/paymentStatus/PaymentStatusItem'

import ShippingMark from './scenes/orders/shippingMark/ShippingMark'
import ShippingMarkItem from './scenes/orders/shippingMark/ShippingMarkItem'

import { WishItem } from './scenes/orders/wish/WishItem'
import Wish from './scenes/orders/wish/Wish'
import WishAll from './scenes/orders/wish/WishAll'
import WishFinished from './scenes/orders/wish/WishFinished'

import WishStatus from './scenes/orders/wishStatus/WishStatus'
import WishStatusItem from './scenes/orders/wishStatus/WishStatusItem'

import SubWish from './scenes/orders/subWish/SubWish'
import SubWishItem from './scenes/orders/subWish/SubWishItem'
import CustomerItem from './scenes/stakeholder/customer/CustomerItem'

import Report from './scenes/report/report/Report'
import ReportItem from './scenes/report/report/ReportItem'

import ReportSend from './scenes/report/reportSend/ReportSend'
import ReportSendItem from './scenes/report/reportSend/ReportSendItem'

import Agent from './scenes/stakeholder/agent/Agent'
import AgentItem from './scenes/stakeholder/agent/AgentItem'
import Customer from './scenes/stakeholder/customer/Customer'
import CustomsBroker from './scenes/stakeholder/customsBroker/CustomsBroker'
import CustomsBrokerItem from './scenes/stakeholder/customsBroker/CustomsBrokerItem'
import Forwarder from './scenes/stakeholder/forwarder/Forwarder'
import ForwarderItem from './scenes/stakeholder/forwarder/ForwarderItem'
import OtherStakeholder from './scenes/stakeholder/otherStakeholder/OtherStakeholder'
import OtherStakeholderItem from './scenes/stakeholder/otherStakeholder/OtherStakeholderItem'

import Supplier from './scenes/stakeholder/supplier/Supplier'
import SupplierItem from './scenes/stakeholder/supplier/SupplierItem'
import HeaderComponent from './components/header/Header'
import Shipper from './scenes/stakeholder/shipper/Shipper'
import ShipperItem from './scenes/stakeholder/shipper/ShipperItem'

import Ship from './scenes/general/ship/Ship'
import ShipItem from './scenes/general/ship/ShipItem'
import TypeOfPresentation from './scenes/general/typeOfPresentation/TypeOfPresentation'
import TypeOfPresentationItem from './scenes/general/typeOfPresentation/TypeOfPresentationItem'
import Country from './scenes/general/country/Country'
import Container from './scenes/general/container/Container'
import ContainerItem from './scenes/general/container/ContainerItem'
import CountryItem from './scenes/general/country/CountryItem'
import Color from './scenes/general/color/Color'
import ColorItem from './scenes/general/color/ColorItem'
import Bank from './scenes/general/bank/Bank'
import BankItem from './scenes/general/bank/BankItem'
import AvailableLocale from './scenes/general/availableLocale/AvailableLocale'
import AvailableLocaleItem from './scenes/general/availableLocale/AvailableLocaleItem'
import Currency from './scenes/general/currency/Currency'
import CurrencyItem from './scenes/general/currency/CurrencyItem'
import PaymentTerm from './scenes/general/paymentTerm/PaymentTerm'
import PaymentTermItem from './scenes/general/paymentTerm/PaymentTermItem'
import PriceCondition from './scenes/general/priceCondition/PriceCondition'
import PriceConditionItem from './scenes/general/priceCondition/PriceConditionItem'
import Family from './scenes/product/family/Family'
import FamilyItem from './scenes/product/family/FamilyItem'
import ItemUnit from './scenes/product/itemUnit/ItemUnit'
import ItemUnitItem from './scenes/product/itemUnit/ItemUnitItem'
import Port from './scenes/general/port/Port'
import PortItem from './scenes/general/port/PortItem'
import Courier from './scenes/general/courier/Courier'
import CourierItem from './scenes/general/courier/CourierItem'
import CourierRecord from './scenes/general/courierRecord/CourierRecord'
import CourierRecordPending from './scenes/general/courierRecord/CourierRecordPending'
import DocumentsCourierRecordItem from './scenes/general/courierRecord/DocumentsCourierRecordItem'
import SpecialCourierRecordItem from './scenes/general/courierRecord/SpecialCourierRecordItem'
import Contact from './scenes/stakeholder/contact/Contact'
import ContactItems from './scenes/stakeholder/contact/ContactItems'
import TypeOfFreightItem from './scenes/general/typeOfFreight/TypeOfFreightItem'
import TypeOfFreight from './scenes/general/typeOfFreight/TypeOfFreight'

import LetterOfGuarantee from './scenes/letter/LetterOfGuarantee'
import LetterOfGuaranteeItem from './scenes/letter/LetterOfGuaranteeItem'

import CriterionValueItem from './scenes/orders/criterionValue/CriterionValueItem'
import CriterionValue from './scenes/orders/criterionValue/CriterionValue'

import DocumentTypeItem from './scenes/orders/documentType/DocumentTypeItem'
import DocumentType from './scenes/orders/documentType/DocumentType'

import { Route } from 'react-router-dom'

import logo from './logo.png'
import background from './background.jpg'
import authLogo from './auth-logo.png'
import './App.css'
import 'typeface-roboto'
import Button from '@material-ui/core/Button'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles'

import { useTranslation } from 'react-i18next'
import Product from './scenes/product/product/Product'
import ProductItem from './scenes/product/product/ProductItem'
import { Amplify } from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import InputField from './components/inputField/InputField'
import PositionArancel from './scenes/product/positionArancel/PositionArancel'
import PositionArancelItem from './scenes/product/positionArancel/PositionArancelItem'
import { Simi } from './scenes/orders/simi/Simi'
import { SimiItem } from './scenes/orders/simi/SimiItem'
import { PreOrder } from './scenes/preorder/PreOrder'
import { PreOrderItem } from './scenes/preorder/PreOrderItem'
import { PreOrderCost } from './scenes/preorder/PreOrderCost'
import ProductType from './scenes/general/productType/ProductType'
import ProductTypeItem from './scenes/general/productType/ProductTypeItem'
import PortTerminal from './scenes/product/portTerminal/PortTerminal'
import PortTerminalItem from './scenes/product/portTerminal/PortTerminalItem'
import { hasAccess } from './lib/hasAccess'
import { PreOrderCostInput } from './scenes/preorder/PreOrderCostInput'
import MaritimeLine from './scenes/maritimeLine/MaritimeLine'
import MaritimeLineItem from './scenes/maritimeLine/MaritimeLineItem'
import { PreOrderCostInputV2 } from './scenes/preorder/PreOrderCostInputV2'

Amplify.Logger.LOG_LEVEL = 'INFO'

Amplify.configure({
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_mgEyfs3vn',
    userPoolWebClientId: '214md9rdo1eikksvekf2ugpvji',
    oauth: {
      domain: 'https://gruppoy-com-ar.auth.us-east-1.amazoncognito.com',
      scope: ['phone', 'email', 'profile', 'openid'],
      redirectSignIn: 'http://localhost',
      redirectSignOut: 'http://localhost',
      responseType: 'code',
    },
  },
  Analytics: {
    disabled: true,
  },
})

function App() {
  const [t] = useTranslation('common')
  const [authenticated, setAuthenticated] = useState(false)

  const [authData, setAuthData] = useState({})
  const [username, setUsername] = useState(undefined)
  const [loginError, setLoginError] = useState(false)

  const handleAuthDataChange = async e => {
    const newAuthData = { ...authData }
    newAuthData[e.target.name] = e.target.value
    setAuthData(newAuthData)
  }

  const logIn = async () => {
    setLoginError(false)
    try {
      await Auth.signIn(authData.username.toLowerCase(), authData.password)
      setAuthenticated(true)
      setUsername(authData.username)
    } catch (e) {
      setLoginError(true)
      setUsername(undefined)
    }
  }

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(async user => {
        setAuthenticated(true)
        setUsername(user.username)
      })
      .catch(e => {
        console.error(JSON.stringify(e))
        setAuthenticated(false)
      })
  }, [])

  const theme = createTheme({
    palette: {
      primary: {
        main: '#182F5B',
      },
      secondary: {
        main: '#871818',
      },
      text: {
        primary: '#000000',
        error: '#9D1643',
      },
    },
  })

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    authInput: {
      color: 'white',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    divlogo: {
      flexGrow: 1,
    },
    logo: {
      height: '20px',
      width: 'auto',
    },
  }))
  const classes = useStyles()

  if (!authenticated) {
    return (
      <div
        className="App"
        style={{
          height: '100%',
          background: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Box style={{ height: '100%' }} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <img src={authLogo} style={{ display: 'flex', width: '30vh', marginBottom: '32px' }} alt={'logo'} />
          <InputField
            InputLabelProps={{ className: classes.authInput }}
            InputProps={{ className: classes.authInput }}
            className={classes.authInput}
            error={loginError}
            style={{ marginBottom: '8px' }}
            name="username"
            label="Username"
            value={authData.username}
            type="text"
            onChange={handleAuthDataChange}
          />
          <InputField
            InputLabelProps={{ className: classes.authInput }}
            InputProps={{ className: classes.authInput }}
            className={classes.authInput}
            error={loginError}
            style={{ marginBottom: '16px' }}
            name="password"
            label="Password"
            type="password"
            value={authData.password}
            onChange={handleAuthDataChange}
          />
          <Button style={{ marginBottom: '32px' }} variant="contained" color="primary" onClick={logIn}>
            Log in
          </Button>

          <Typography style={{ color: 'white', marginBottom: '8px' }} variant="body1">
            Holmberg 2350 PB "H", Buenos Aires, Argentina, C1430DOR
          </Typography>
          <Typography style={{ color: 'white', marginBottom: '8px' }} variant="body1">
            +54 (11) 4543-8967 / 4546-3516
          </Typography>
          <Typography variant="body1">
            <a style={{ color: 'white' }} href="mailto:info@gruppoy.com.ar">
              info@gruppoy.com.ar
            </a>
          </Typography>
        </Box>
      </div>
    )
  }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <header className="App-header">
          <AppBar position="static" id="appbar-top">
            <Toolbar>
              <Menu username={username} classes={classes} />

              <div className={classes.divlogo}>
                <img src={logo} alt="logo" className={classes.logo} />
              </div>
              <HeaderComponent />
              <Button
                color="inherit"
                onClick={async () => {
                  await Auth.signOut()
                  setAuthenticated(false)
                }}
              >
                {' '}
                {t('button.out')}
              </Button>
            </Toolbar>
          </AppBar>
        </header>
        <Route exact path="/" component={Home} />
        <Route exact path="/criterionValue" component={CriterionValue} />
        <Route exact path="/paymentStatus" component={PaymentStatus} />
        <Route exact path="/shippingMark" component={ShippingMark} />
        <Route exact path="/wishStatus" component={WishStatus} />
        <Route exact path="/report" component={Report} />

        <Route exact path="/wish" component={Wish} />
        <Route exact path="/wish/all" component={WishAll} />
        <Route exact path="/wish/finished" component={WishFinished} />

        <Route exact path="/customer" component={Customer} />
        <Route exact path="/supplier" component={Supplier} />
        {hasAccess(username, 'forwarder') && <Route exact path="/forwarder" component={Forwarder} />}
        <Route exact path="/shipper" component={Shipper} />

        <Route exact path="/ship" component={Ship} />
        <Route exact path="/port" component={Port} />
        <Route exact path="/courier" component={Courier} />
        <Route exact path="/courierRecord" component={CourierRecord} />
        <Route exact path="/courierRecords/pending" component={CourierRecordPending} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/letterOfGuarantee" component={LetterOfGuarantee} />
        <Route exact path="/country" component={Country} />
        <Route exact path="/container" component={Container} />
        <Route exact path="/currency" component={Currency} />
        <Route exact path="/productType" component={ProductType} />
        <Route exact path="/product" component={Product} />
        <Route exact path="/positionArancel" component={PositionArancel} />
        <Route exact path="/family" component={Family} />
        <Route exact path="/portTerminal" component={PortTerminal} />
        <Route exact path="/itemUnit" component={ItemUnit} />
        <Route exact path="/typeOfPresentation" component={TypeOfPresentation} />
        <Route exact path="/color" component={Color} />
        <Route exact path="/bank" component={Bank} />
        <Route exact path="/availableLocale" component={AvailableLocale} />
        <Route exact path="/documentType" component={DocumentType} />
        <Route exact path="/paymentTerm" component={PaymentTerm} />
        <Route exact path="/priceCondition" component={PriceCondition} />
        <Route exact path="/typeOfFreight" component={TypeOfFreight} />
        <Route exact path="/reportSendConfiguration" component={ReportSend} />

        <Route exact path="/agent" component={Agent} />
        <Route exact path="/customsBroker" component={CustomsBroker} />
        <Route exact path="/otherStakeholder" component={OtherStakeholder} />

        <Route exact path="/report/edit/:objectId" component={ReportItem} />
        <Route exact path="/report/create" component={ReportItem} />

        <Route exact path="/wish/edit/:objectId/:search" component={WishItem} />
        <Route exact path="/wish/edit/:objectId" component={WishItem} />
        <Route exact path="/wish/create" component={WishItem} />
        <Route exact path="/wish/:objectId/subWish" component={SubWish} />
        <Route exact path="/wish/:wishId/subWish/create" component={SubWishItem} />
        <Route exact path="/wish/:wishId/subWish/edit/:objectId" component={SubWishItem} />

        <Route exact path="/customer/edit/:objectId" component={CustomerItem} />
        <Route exact path="/customer/create" component={CustomerItem} />

        <Route exact path="/supplier/edit/:objectId" component={SupplierItem} />
        <Route exact path="/supplier/create" component={SupplierItem} />

        <Route exact path="/agent/edit/:objectId" component={AgentItem} />
        <Route exact path="/agent/create" component={AgentItem} />

        {hasAccess(username, 'forwarder') && <Route exact path="/forwarder/edit/:objectId" component={ForwarderItem} />}
        {hasAccess(username, 'forwarder') && <Route exact path="/forwarder/create" component={ForwarderItem} />}

        <Route exact path="/otherStakeholder/edit/:objectId" component={OtherStakeholderItem} />
        <Route exact path="/otherStakeholder/create" component={OtherStakeholderItem} />

        <Route exact path="/customsBroker/edit/:objectId" component={CustomsBrokerItem} />
        <Route exact path="/customsBroker/create" component={CustomsBrokerItem} />

        <Route exact path="/shipper/edit/:objectId" component={ShipperItem} />
        <Route exact path="/shipper/create" component={ShipperItem} />

        <Route exact path="/ship/edit/:objectId" component={ShipItem} />
        <Route exact path="/ship/create" component={ShipItem} />

        <Route exact path="/typeOfPresentation/edit/:objectId" component={TypeOfPresentationItem} />
        <Route exact path="/typeOfPresentation/create" component={TypeOfPresentationItem} />

        <Route exact path="/port/edit/:objectId" component={PortItem} />
        <Route exact path="/port/create" component={PortItem} />

        <Route exact path="/courier/edit/:objectId" component={CourierItem} />
        <Route exact path="/courier/create" component={CourierItem} />

        <Route exact path="/contact/edit/:objectId" component={ContactItems} />
        <Route exact path="/contact/create" component={ContactItems} />

        <Route exact path="/maritimeLine/edit/:objectId" component={MaritimeLineItem} />
        <Route exact path="/maritimeLine/create" component={MaritimeLineItem} />
        <Route exact path="/maritimeLine" component={MaritimeLine} />

        <Route exact path="/documentsCourierRecord/edit/:objectId" component={DocumentsCourierRecordItem} />
        <Route exact path="/documentsCourierRecord/create" component={DocumentsCourierRecordItem} />

        <Route exact path="/specialCourierRecord/edit/:objectId" component={SpecialCourierRecordItem} />
        <Route exact path="/specialCourierRecord/create" component={SpecialCourierRecordItem} />

        <Route exact path="/letterOfGuarantee/edit/:objectId" component={LetterOfGuaranteeItem} />
        <Route exact path="/letterOfGuarantee/create" component={LetterOfGuaranteeItem} />

        <Route exact path="/country/create" component={CountryItem} />
        <Route exact path="/country/edit/:objectId" component={CountryItem} />

        <Route exact path="/container/create" component={ContainerItem} />
        <Route exact path="/container/edit/:objectId" component={ContainerItem} />

        <Route exact path="/color/create" component={ColorItem} />
        <Route exact path="/color/edit/:objectId" component={ColorItem} />

        <Route exact path="/bank/create" component={BankItem} />
        <Route exact path="/bank/edit/:objectId" component={BankItem} />

        <Route exact path="/availableLocale/create" component={AvailableLocaleItem} />
        <Route exact path="/availableLocale/edit/:objectId" component={AvailableLocaleItem} />

        <Route exact path="/criterionValue/create" component={CriterionValueItem} />
        <Route exact path="/criterionValue/edit/:objectId" component={CriterionValueItem} />

        <Route exact path="/currency/create" component={CurrencyItem} />
        <Route exact path="/currency/edit/:objectId" component={CurrencyItem} />

        <Route exact path="/productType/create" component={ProductTypeItem} />
        <Route exact path="/productType/edit/:objectId" component={ProductTypeItem} />

        <Route exact path="/documentType/create" component={DocumentTypeItem} />
        <Route exact path="/documentType/edit/:objectId" component={DocumentTypeItem} />

        <Route exact path="/paymentStatus/create" component={PaymentStatusItem} />
        <Route exact path="/paymentStatus/edit/:objectId" component={PaymentStatusItem} />

        <Route exact path="/paymentTerm/create" component={PaymentTermItem} />
        <Route exact path="/paymentTerm/edit/:objectId" component={PaymentTermItem} />

        <Route exact path="/priceCondition/create" component={PriceConditionItem} />
        <Route exact path="/priceCondition/edit/:objectId" component={PriceConditionItem} />

        <Route exact path="/product/create" component={ProductItem} />
        <Route exact path="/product/edit/:objectId" component={ProductItem} />

        <Route exact path="/positionArancel/create" component={PositionArancelItem} />
        <Route exact path="/positionArancel/edit/:objectId" component={PositionArancelItem} />

        <Route exact path="/typeOfFreight/create" component={TypeOfFreightItem} />
        <Route exact path="/typeOfFreight/edit/:objectId" component={TypeOfFreightItem} />

        <Route exact path="/shippingMark/create" component={ShippingMarkItem} />
        <Route exact path="/shippingMark/edit/:objectId" component={ShippingMarkItem} />

        <Route exact path="/wishStatus/create" component={WishStatusItem} />
        <Route exact path="/wishStatus/edit/:objectId" component={WishStatusItem} />

        <Route exact path="/family/create" component={FamilyItem} />
        <Route exact path="/family/edit/:objectId" component={FamilyItem} />

        <Route exact path="/portTerminal/create" component={PortTerminalItem} />
        <Route exact path="/portTerminal/edit/:objectId" component={PortTerminalItem} />

        <Route exact path="/itemUnit/create" component={ItemUnitItem} />
        <Route exact path="/itemUnit/edit/:objectId" component={ItemUnitItem} />

        <Route exact path="/reportSendConfiguration/create" component={ReportSendItem} />
        <Route exact path="/reportSendConfiguration/edit/:objectId" component={ReportSendItem} />

        <Route exact path="/simi" component={Simi} />
        <Route exact path="/simi/create" component={SimiItem} />
        <Route exact path="/simi/edit/:objectId" component={SimiItem} />

        <Route exact path="/preOrder" component={PreOrder} />
        <Route exact path="/preOrder/create" component={PreOrderItem} />
        <Route exact path="/preOrder/edit/:objectId" component={PreOrderItem} />

        <Route exact path="/preOrder/cost/:objectId" component={PreOrderCost} />
        <Route exact path="/preOrder/costInput/:objectId/:forwarderId/:portId" component={PreOrderCostInput} />
        <Route exact path="/preOrder/costInputV2/:objectId/:forwarderId/:portId" component={PreOrderCostInputV2} />
      </ThemeProvider>
    </div>
  )
}

export default App
