import React, {useEffect, useState} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import {Box, Button, Paper, TextField} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import {useTableSearch} from "../../../components/search/UseTableSearch";
import Grid from '@material-ui/core/Grid';
import {Link} from "react-router-dom";
import {fetchSecure} from "../../../modules/fetchSecure";
import TableComponentCourierRecord from "../../../components/table/TableComponentCourierRecord";
import {format as formatDate} from "date-fns";
import {useAuthorization} from "../../../lib/useAuthorization";
import {DeleteModal} from "../../orders/wish/DeleteModal";
import {SnackbarMessage} from "../../../components/atoms/SnackbarMessage";
import LoadingScreen from '../../../components/loading/LoadingScreen';

function CourierRecord() {

    const [courierRecordList, setCourierRecordList] = useState([]);
    const [loading, setLoading] = useState(false);


    const [t] = useTranslation('common');
    const [searchVal, setSearchVal] = useState(null);

    const {filteredData} = useTableSearch({
        searchVal,
        retrieve: courierRecordList
    });

    const createToDocuments = {
        pathname: "/documentsCourierRecord/create",
    };
    const createToSpecial = {
        pathname: "/specialCourierRecord/create",
    };

    const columns = [
        {id: 'id', label: 'ID', minWidth: 20, maxWidth: 30},
        {id: 'courier.name', label: 'Courier', minWidth: 20, maxWidth: 30},
        {id: 'status', label: 'Estado', minWidth: 20, maxWidth: 30},
        {
            id: 'departureDate', label: 'Fecha de salida', minWidth: 20, maxWidth: 30, type: 'date', format: (date) => {
                return !date ? '' : formatDate(new Date(date), 'dd/MM/yyyy')
            }
        },
        {
            id: 'arrivalDate', label: 'Fecha llegada', minWidth: 20, maxWidth: 30, type: 'date', format: (date) => {
                return !date ? '' : formatDate(new Date(date), 'dd/MM/yyyy')
            }
        },
        {id: 'order', label: 'Orden', minWidth: 20, maxWidth: 30},
        {id: 'issuer.name', label: 'Emisor', minWidth: 20, maxWidth: 30},
        {id: 'observations', label: 'Detalle', minWidth: 20, maxWidth: 30},
        {id: 'destination', label: 'Receptores', minWidth: 20, maxWidth: 30},
        {id: 'trackingNumber', label: 'Orden', minWidth: 20, maxWidth: 30}
    ];

    // TODO refactor this
    const [deleteModal, setDeleteModal] = useState({open: false, row: 0})
    const [deleteStatus, setDeleteStatus] = useState({error: false, row: 0})
    const [isInError, setIsInError] = useState({open: false, message: ''})

    const handlerDeleteModal = (id) => {
        setDeleteModal({open: true, row: id})
    }
    const onHandlerDeleteElement = async () => {
        const response = await fetchSecure(window.REACT_APP_BASE_API_URL + '/courierRecord/' + deleteModal.row, {
            method: 'DELETE',
        })
        if (!response.ok) {
            setIsInError({open: true, message: 'Ocurrió un error al eliminar el objeto'})
            setDeleteStatus({error: true, row: 0})
        } else {
            setDeleteStatus({error: false, row: deleteModal.row})
        }
        setDeleteModal({open: false, row: 0})
    }

    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {
            maxHeight: 440,
        },
    });

    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const {loadingUser, canDelete} = useAuthorization()

    useEffect(() => {
        setLoading(true)
        fetchSecure(window.REACT_APP_BASE_API_URL + '/courierRecords')
            .then(response => response.json())
            .then(data => {
                setCourierRecordList(data)
            })
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    }, [page, deleteStatus.row])
    if (loading || loadingUser) {
        return <Box display="flex" justifyContent="center" alignItems="center" style={{height: '100vh'}}><LoadingScreen
            backgroundColor={'white'}/></Box>
    } else {
        return <div>
            <Paper className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <Box m={2}>
                            <h1>CourierRecord - Todos </h1>
                            <TextField
                                onChange={e => setSearchVal(e.target.value)}
                                placeholder="Search"
                                enterButton
                                style={{position: "sticky", top: "0", left: "0"}}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={3} alignItems="left">

                        <Box m={3}>
                            <Link to={createToDocuments}>
                                <Button variant="contained"
                                        color="primary">{t('button.createDocumentsCourier')}</Button>
                            </Link>
                            <Link to={createToSpecial}>
                                <Button variant="contained" color="primary">{t('button.createSpecialCourier')}</Button>
                            </Link>
                            <Link to={{
                                pathname: '/courierRecords/pending',
                                state: {prevPath: '/courierRecords/pending'}
                            }}>
                                <Button variant="contained" color="primary">{t('button.pending')}</Button>
                            </Link>
                        </Box>

                    </Grid>
                </Grid>

                <TableComponentCourierRecord
                    columns={columns}
                    filteredData={filteredData}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    count={courierRecordList.length}
                    urlEdit={'change'}
                    canDelete={canDelete}
                    handlerDeleteModal={handlerDeleteModal}
                />
            </Paper>

            <DeleteModal deleteModal={deleteModal}
                         setDeleteModal={setDeleteModal}
                         onHandlerDeleteElement={onHandlerDeleteElement}/>
            <SnackbarMessage open={isInError.open} handleClose={() => {
                setIsInError({open: false, message: ''})
            }} severity={'error'} message={isInError.message ?? 'Ocurrió un error al borrar el pedido'}/>
        </div>
    }

}

export default CourierRecord;
