import React, { Fragment, useState } from 'react';
import { FormGroup } from '@material-ui/core';
import InputField from '../inputField/InputField.js';
import InputTextAreaField from "../inputField/InputTextAreaField";
import {useTranslation} from "react-i18next";


function SingleElement(props) {
  const [t] = useTranslation('common');
  return <Fragment>
    {props.elementToRender.elements.map((it) =>
      (it.type === "textField" && <InputField  key={it.id} disabled={it.disabled} name={it.id} id={it.id} label={it.label} type={it.typeTextField} className="text" onChange={props.onChange(props.index)} value={t(props.element[it.id])} />)
      || ( it.type === 'inputTextAreaField' && <InputTextAreaField fullWidth={true} key={it.id} name={it.id} label={it.label} disabled={it.disabled} width={500} className="textarea" onChange={props.onChange(props.index)} value={props.element[it.id]} /> )
    )}
  </Fragment>
}


function ListJustification({ onChangeList, elementToRender, elementToUpload, listName, errorsList, disabled, isShowTotal }) {

  const [setTotal] = useState(0);
  let totalCounter = 0;
  const handleChange = index => e => {
    let newListComponent = [...elementToUpload];
    newListComponent[index][e.target.name] = e.target.value;
    onChangeList(listName, newListComponent)
    if (isShowTotal !== undefined) {
      newListComponent.map((it) => totalCounter = totalCounter + ((it.unitPrice === undefined ? 0 : it.unitPrice) * (it.quantity === undefined ? 0 : it.quantity)))
      setTotal(totalCounter)
    }

  }

  const handleChangeObject = index => (objectName, objectValue) => {
    let newListComponent = [...elementToUpload];
    newListComponent[index][objectName] = objectValue;
    onChangeList(listName, newListComponent)
  }


  return <Fragment>
    <div className="container">
      <div hidden>
        {typeof (elementToUpload) !== 'undefined' ? elementToUpload.map((it) => totalCounter = totalCounter + ((it.unitPrice === undefined ? 0 : it.unitPrice) * (it.quantity === undefined ? 0 : it.quantity))) : 0}
      </div>
      {typeof (elementToUpload) !== 'undefined' ? elementToUpload.map((it, index) =>
        <Fragment>
          <FormGroup key={it.key} row={true}>
            <SingleElement errorsList={errorsList} elementToRender={elementToRender} element={it} index={index} onChange={handleChange} onChangeElement={handleChangeObject}/>
          </FormGroup>
        </Fragment>
      ) : []}
    </div>
  </Fragment>
}

export default ListJustification
