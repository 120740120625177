import React from 'react';
import {Box, TextareaAutosize} from '@material-ui/core';

import {useTranslation} from "react-i18next";

function InputTextAreaField({
                                name,
                                value,
                                onChange,
                                type,
                                className,
                                error,
                                disabled,
                                width = 250,
                                label,
                                style,
                                fieldLabel = null
                            }) {

    const [t] = useTranslation('common');

    let currentValue = "";
    if (value != null) {
        currentValue = value;
    }

    return <Box style={{display: 'flex', flexDirection: 'column', marginBottom: '16px', marginRight: '8px'}}>
        <label
            style={{color: '#9E9E9E', fontSize: '13px', ...style}}>{fieldLabel ?? t(label)}</label>
        <TextareaAutosize value={currentValue}
                          error={error}
                          rowsMin={3}
                          onChange={onChange}
                          name={name}
                          id={name}
                          disabled={disabled}
                          placeholder={t(label)}
                          maxRows={4}
                          label={t(name)}
                          type={type}
                          className={className}
                          style={{width: width, ...style}}
                          InputLabelProps={{shrink: true}}/>
    </Box>
}

export default InputTextAreaField
