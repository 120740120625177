import DateFnsUtils from '@date-io/date-fns'
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider
} from '@material-ui/pickers'


export const NewDateField = ({name, label, value, setFieldValue, error,disabled, style}) => {

	let currentValue = null;
	if(value != null) {
		currentValue = value;
	}

  return <MuiPickersUtilsProvider utils={DateFnsUtils} >

  	<KeyboardDatePicker inputVariant="outlined" style={style} disabled={disabled} error={error} format="dd/MM/yyyy" InputLabelProps={{shrink: true}}
  	label={label} name={name} id={name} value={currentValue} onChange={newValue => {
		setFieldValue(name, new Date(newValue.toDateString()));
	}} clearable={true}  />
  	</MuiPickersUtilsProvider>

}

