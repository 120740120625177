
import {FormPage} from "../../../components/templates/FormPage"
import {useTranslation} from "react-i18next";

export const SimiItem = (props) => {
    const initialState = {simiNumber: '', officializationDate: new Date(new Date().toDateString()), version: 0, wishId: null}
    const objectId = props.match.params.objectId
    const baseSaveUrl = window.REACT_APP_BASE_API_URL + '/simi'

    const [t] = useTranslation('common')

    const fields = [
        {name: 'simiNumber', type: 'TextField',label: t('simi.simiNumber')},
        {name: 'officializationDate', type: 'DateField', label: t('simi.officializationDate')},
        {name: 'wishId', type: 'SimpleSelectField', url: window.REACT_APP_BASE_API_URL + '/wish/pending?page=0&size=400', optionValue: 'id', optionShow: 'supplierOrder'},
        {name: 'approvedDate', type: 'DateField', label: t('simi.approvedDate')},
        {name: 'automaticApprovedDate', type: 'DateField', label: t('simi.automaticApprovedDate')},
        {name: 'observed', type: 'Checkbox',  label: t('simi.observed')},
        {name: 'extensionApproved', type: 'Checkbox',  label: t('simi.extensionApproved')},
        {name: 'categoryBcra', type: 'TextField',  label: t('simi.categoryBcra')},
        {name: 'accessMulc', type: 'DateField', label: t('simi.accessMulc')},

    ]

    return <FormPage initialState={initialState} objectId={objectId} baseSaveUrl={baseSaveUrl} fields={fields}/>

}


