export const formatNumber = (number, decimals = 0) => {
    if (number === null || number === undefined) {
        return null;
    }

    const options = {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    };

    return new Intl.NumberFormat(undefined, options).format(number);
}
