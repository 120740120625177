import React from "react";
import { Box, CircularProgress } from "@mui/material";

const LoadingScreen = ({ backgroundColor = "#F5F5F5" }) => (
  <Box
    style={{ backgroundColor }}
    flex={1}
    display="flex"
    flexDirection="column"
    justifyContent="center"
  >
    <CircularProgress style={{ alignSelf: "center" }} />
  </Box>
);

export default LoadingScreen;