import {TextField} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {fetchSecure} from "../../modules/fetchSecure"
import Box from "@material-ui/core/Box"
import {Formik} from "formik"
import Grid from "@material-ui/core/Grid"
import {SimpleSelectField} from "../selectField/SimpleSelectField"
import {SnackbarMessage} from "./SnackbarMessage"
import {NewDateField} from "../dateField/NewDateField"
import {NewCheckboxField} from "./NewCheckboxField";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Button from "./Button"
import LoadingScreen from "../loading/LoadingScreen"


export const InternalFormPage = ({initialState, objectId, baseSaveUrl, fields, onSaveSuccess}) => {

    const [loadedObject, setLoadedObject] = useState(initialState)

    const [submitDisabled, setSubmitDisabled] = useState(false)

    const [loading, setLoading] = useState(true)
    const [isInErrorState, setIsInErrorState] = useState(false)
    const [wasSavedSuccessfully, setWasSavedSuccessfully] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const [t] = useTranslation('common')

    const onSubmit = async (values) => {
        setSubmitDisabled(true)
        const method = objectId ? 'PUT' : 'POST'
        const url = objectId ? baseSaveUrl + '/' + objectId : baseSaveUrl

        const requestOptions = {
            method: method,
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(values)
        }

        try {
            const response = await fetchSecure(url, requestOptions)
            const responseJson = await response.json()
            if (response.ok) {
                setLoadedObject(responseJson)
                setWasSavedSuccessfully(true)
                setTimeout(() => {
                    setWasSavedSuccessfully(false)
                }, 3000)
                await onSaveSuccess()

            } else {
                const responseErrorMessage = (responseJson && responseJson.message) || response.status
                setErrorMessage(responseErrorMessage)
                setIsInErrorState(true)
                setTimeout(() => {
                    setIsInErrorState(false)
                }, 3000)
            }
        } catch (error) {
            setErrorMessage(error)
            setIsInErrorState(true)
            setTimeout(() => {
                setIsInErrorState(false)
            }, 3000)
        }
        setSubmitDisabled(false)
    }


    useEffect(() => {
        if (objectId) {
            fetchSecure(baseSaveUrl + '/' + objectId)
                .then(response => response.json())
                .then(data => {
                    setLoadedObject(data)
                    setLoading(false)
                })
        } else {
            setLoading(false)
        }

    }, [objectId, baseSaveUrl])


    if (loading) {
        return <Box style={{display: 'flex', height: '100vh', flexDirection: 'column'}}><LoadingScreen/></Box>
    }

    return (
        <Box padding={2}>
            <Formik enableReinitialize={true} initialValues={loadedObject} onSubmit={onSubmit}>
                {formik => (
                    <Grid container spacing={2}>

                        {fields.map((field) => (
                            <Grid item xs={12} sm={6}>
                                {
                                    field.type === 'TextField' &&
                                    <TextField style={{width: '100%'}}
                                               error={!!formik.errors[field.name]}
                                               helperText={formik.errors[field.name]} value={formik.values[field.name]}
                                               onChange={formik.handleChange} variant="outlined"
                                               label={(field.label ?? t(field.name)) + (field.optional ? ` (${t('optional')})` : '')}
                                               placeholder={t(field.name)}
                                               name={field.name}/>
                                }
                                {
                                    field.type === 'DateField' &&
                                    <NewDateField style={{width: '100%'}}
                                                  label={(field.label ?? t(field.name)) + (field.optional ? ` (${t('optional')})` : '')}
                                                  error={!!formik.errors[field.name]} name={field.name}
                                                  value={formik.values[field.name]}
                                                  setFieldValue={formik.setFieldValue}/>

                                }
                                {
                                    field.type === 'SimpleSelectField' &&
                                    <SimpleSelectField style={{width: '100%'}} error={!!formik.errors[field.name]}
                                                       name={field.name} value={formik.values[field.name]}
                                                       onChange={(e, newValue) => {
                                                           formik.setFieldValue(field.name, newValue)
                                                       }} url={field.url ?? field.dynamicUrl(formik.values)}
                                                       optionValue={field.optionValue} optionShow={field.optionShow}/>
                                }
                                {
                                    field.type === 'DecimalField' &&
                                    <TextField style={{width: '100%'}} error={!!formik.errors[field.name]}
                                               helperText={formik.errors[field.name]} value={formik.values[field.name]}
                                               onChange={formik.handleChange} variant="outlined"
                                               label={(field.label ?? t(field.name)) + (field.optional ? ` (${t('optional')})` : '')}
                                               placeholder={t(field.name)}
                                               name={field.name} type="number"/>

                                }
                                {
                                    field.type === 'Checkbox' &&
                                    <NewCheckboxField style={{width: '100%'}} name={field.name}
                                                      value={formik.values[field.name]} onChange={formik.handleChange}
                                                      label={field.label}/>

                                }
                                {
                                    field.type === 'File' &&
                                    <FileField style={{width: '100%'}} name={field.name}
                                               value={formik.values[field.name]} onChange={(newValue) => {
                                        formik.setFieldValue(field.name, newValue)
                                    }} label={field.label ?? t(field.name)}
                                               setSubmitDisabled={setSubmitDisabled}/>
                                }

                            </Grid>
                        ))}
                        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                            <Button disabled={submitDisabled} variant="outlined" color="primary"
                                    onClick={formik.handleSubmit}>Guardar</Button>
                        </Grid>
                    </Grid>
                )}
            </Formik>


            <SnackbarMessage open={isInErrorState} handleClose={() => {
                setIsInErrorState(false)
            }} severity={'error'} message={'Ha ocurrido un error inesperado al intentar grabar. ' + errorMessage}/>

            <SnackbarMessage open={wasSavedSuccessfully} handleClose={() => {
                setWasSavedSuccessfully(false)
            }} severity={'success'} message={'El objeto se ha grabado con éxito'}/>


        </Box>
    )
}

const FileField = ({value, onChange, style, setSubmitDisabled}) => {
    const [preview, setPreview] = useState()
    const [selectedFile, setSelectedFile] = useState(undefined)
    const [existingFile, setExistingFile] = useState(undefined)
    const [existingFileName, setExistingFileName] = useState(undefined)

    const fetchInitialFile = async () => {
        const getFileResponse = await fetchSecure(window.REACT_APP_TOKENIZATION_URL + '/file/' + value, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        })
        const getFileResponseBody = await getFileResponse.json()

        const downloadFileResponse = await fetch(getFileResponseBody.downloadUrl, {method: 'GET'})
        if (downloadFileResponse.ok) {
            const fileBlob = await downloadFileResponse.blob()
            const reader = new FileReader();
            reader.readAsDataURL(fileBlob);
            reader.onloadend = () => {
                const base64data = reader.result
                setExistingFile(base64data)
                setExistingFileName("image")
            }
        }
    }

    useEffect(() => {
        if (value) {
            fetchInitialFile().then(() => {
            })
        }
    })

    const onFileChange = async (newSelectedFile) => {
        setSubmitDisabled(true)
        if (newSelectedFile) {
            const putFileResponse = await fetchSecure(window.REACT_APP_TOKENIZATION_URL + '/file', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({fileName: newSelectedFile.name})
            })
            const putFileResponseBody = await putFileResponse.json()

            const uploadFileResponse = await fetch(putFileResponseBody.uploadUrl, {
                method: 'PUT',
                body: newSelectedFile,
                headers: {
                    'Content-Type': 'application/json',
                    'Content-Disposition': `attachment; filename="${newSelectedFile.name}"`
                }
            })
            if (!uploadFileResponse.ok) {
                return
            }
            onChange(putFileResponseBody.token)
        } else {
            onChange(null)
        }
        setSubmitDisabled(false)
    }

    useEffect(() => {
        if (!selectedFile) {
            setPreview(existingFile)
            return
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile, existingFile])

    return <Box display={'flex'} style={style}><Box mr={1}><Button variant="contained" component="label"
                                                                   color="primary">
        Seleccionar archivo
        <input type="file" hidden onChange={async (event) => {
            const newSelectedFile = event.target?.files?.[0]
            setSelectedFile(newSelectedFile)
            await onFileChange(newSelectedFile)
        }}/>

    </Button>

    </Box>
        {preview && <Box display={'flex'}><img src={preview} alt="preview" height={'128px'}/>
            <Box display={'flex'}><Button onClick={() => {
                const downloadLink = document.createElement("a")
                downloadLink.href = selectedFile ? preview : existingFile
                downloadLink.download = selectedFile ? selectedFile.name : existingFileName
                downloadLink.click()


            }}><CloudDownloadIcon/></Button></Box>
        </Box>}
    </Box>
}
