import React, { useState, useEffect } from 'react'
import { Box, Button, FormGroup, Icon, TextField } from '@material-ui/core'
import shortid from 'shortid'
import { green } from '@material-ui/core/colors'
import DeleteIcon from '@material-ui/icons/Delete'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import DateField from '../dateField/DateField.js'
import CheckboxField from '../checkboxField/CheckboxField.js'
import SelectField from '../selectField/SelectField.js'
import InputField from '../inputField/InputField.js'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import InputTextAreaField from '../inputField/InputTextAreaField'
import Upload from '../uploadField/Upload'
import ListEditModal from './ListEditModal.js'
import { useTranslation } from 'react-i18next'
import { fetchSecure } from '../../modules/fetchSecure.js'

function handleError(errorsList, name) {
  if (errorsList !== undefined) {
    return Object.keys(errorsList).filter(key => key === name).length > 0
  }
  return false
}

function SingleElement(props) {
  return (
    <>
      {props.elementToRender.elements.map(
        (it, e) =>
          (it.type === 'textField' && (
            <InputField
              style={{ margin: 8 }}
              error={handleError(props.errorsList, it.id)}
              key={it.id}
              disabled={it.disabled}
              name={it.id}
              id={it.id}
              fieldLabel={it.fieldLabel}
              type={it.typeTextField}
              className="text"
              onChange={props.onChange(props.index)}
              value={props.element[it.id]}
            />
          )) ||
          (it.type === 'dateField' && (
            <DateField
              style={{ margin: 8 }}
              error={handleError(props.errorsList, it.id)}
              key={it.id}
              disabled={it.disabled}
              name={it.id}
              label={it.label}
              className="text"
              onChange={props.onChangeElement(props.index)}
              value={props.element[it.id]}
            />
          )) ||
          (it.type === 'checkBox' && (
            <CheckboxField
              style={{ margin: 8 }}
              key={it.id}
              name={it.id}
              label={it.label}
              disabled={it.disabled}
              className="text"
              onChange={props.onChangeElement(props.index)}
              value={props.element[it.id]}
            />
          )) ||
          (it.type === 'inputTextAreaField' && (
            <InputTextAreaField
              style={{ margin: 8 }}
              fullWidth={true}
              key={it.id}
              name={it.id}
              label={it.label}
              disabled={it.disabled}
              className="text"
              onChange={props.onChange(props.index)}
              value={props.element[it.id]}
            />
          )) ||
          (it.type === 'upload' && <Upload onChange={props.onChangeFile(props.index)} />) ||
          (it.type === 'select' && (
            <SelectField
              error={handleError(props.errorsList, it.id)}
              disabled={it.disabled}
              key={it.id}
              name={it.id}
              fieldLabel={it.fieldLabel}
              className="text"
              changeHandler={props.onChangeElement(props.index)}
              value={props.element[it.id]}
              url={it.url}
              optionValue={it.optionValue}
              optionShow={it.optionShow}
            />
          ))
      )}
    </>
  )
}

function ListComponent({ onChangeList, elementToRender, elementToUpload, listName, errorsList, disabled, isShowTotal }) {
  const [t] = useTranslation('common')
  const [listEditModalOpen, setListEditModalOpen] = useState(false)
  const [elementToEdit, setElementToEdit] = useState(null)
  const [options, setOptions] = useState({
    colorOptions: [],
    productTypeOptions: [],
    itemUnitOptions: [],
  })
  const openListEditModal = index => {
    setElementToEdit(elementToUpload[index])
    setListEditModalOpen(true)
  }

  const closeModal = () => {
    setListEditModalOpen(false)
    setElementToEdit(null)
  }

  let totalCounter = 0
  const handleChange = index => e => {
    if (e.target.name !== 'key') {
      let newListComponent = [...elementToUpload]
      newListComponent[index][e.target.name] = e.target.value
      onChangeList(listName, newListComponent)
      if (isShowTotal !== undefined) {
        newListComponent.map(
          it => (totalCounter = totalCounter + (it.unitPrice === undefined ? 0 : it.unitPrice) * (it.quantity === undefined ? 0 : it.quantity))
        )
      }
    }
  }

  const handleChangeFile = index => bytes => {
    let newListComponent = [...elementToUpload]
    newListComponent[index]['file'] = bytes
    onChangeList(listName, newListComponent)
    if (isShowTotal !== undefined) {
      newListComponent.map(
        it => (totalCounter = totalCounter + (it.unitPrice === undefined ? 0 : it.unitPrice) * (it.quantity === undefined ? 0 : it.quantity))
      )
    }
  }

  const handleChangeObject = index => (objectName, objectValue) => {
    if (objectName !== 'key') {
      let newListComponent = [...elementToUpload]
      newListComponent[index][objectName] = objectValue
      onChangeList(listName, newListComponent)
    }
  }

  const addElement = event => {
    if (elementToUpload === undefined) {
      elementToUpload = [{ key: shortid.generate() }]
    } else {
      elementToUpload.push({ key: shortid.generate() })
    }
    onChangeList(listName, elementToUpload)
    event.preventDefault()
  }

  const remove = index => {
    let newWish = { ...elementToUpload }
    newWish = elementToUpload.filter((_, i) => i !== index)
    onChangeList(listName, newWish)
    if (isShowTotal !== undefined) {
      newWish.map(
        it => (totalCounter = totalCounter + (it.unitPrice === undefined ? 0 : it.unitPrice) * (it.quantity === undefined ? 0 : it.quantity))
      )
    }
  }

  const fetchOptions = async () => {
    try {
      const editBaseSaveUrl = window.REACT_APP_BASE_API_URL
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
      const colorResponse = await fetchSecure(`${editBaseSaveUrl}/color`, requestOptions)
      const productTypeResponse = await fetchSecure(`${editBaseSaveUrl}/productType`, requestOptions)
      const itemUnitResponse = await fetchSecure(`${editBaseSaveUrl}/itemUnit`, requestOptions)
      const colorData = await colorResponse.json()
      const productTypeData = await productTypeResponse.json()
      const itemUnitData = await itemUnitResponse.json()
      setOptions({
        colorOptions: colorData,
        productTypeOptions: productTypeData,
        itemUnitOptions: itemUnitData,
      })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchOptions()
  }, [])

  return (
    <div>
      <div hidden>
        {typeof elementToUpload !== 'undefined'
          ? elementToUpload.map(
              it => (totalCounter = totalCounter + (it.unitPrice === undefined ? 0 : it.unitPrice) * (it.quantity === undefined ? 0 : it.quantity))
            )
          : 0}
      </div>
      {typeof elementToUpload !== 'undefined'
        ? elementToUpload.map((it, index) => (
            <React.Fragment key={index}>
              <FormGroup key={it.key} row={true}>
                <SingleElement
                  errorsList={errorsList}
                  elementToRender={elementToRender}
                  element={it}
                  index={index}
                  onChange={handleChange}
                  onChangeElement={handleChangeObject}
                  onChangeFile={handleChangeFile}
                />
                {disabled === false && (
                  <Box style={{ display: 'flex', alignItems: 'center', gap: '8px', margin: '8px' }}>
                    <Button variant="contained" color="default" onClick={() => openListEditModal(index)} size="small" startIcon={<ModeEditIcon />}>
                      {t('editItem')}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => remove(index)} size="small" startIcon={<DeleteIcon />}>
                      {t('deleteItem')}
                    </Button>
                  </Box>
                )}
              </FormGroup>
            </React.Fragment>
          ))
        : []}
      <Divider light />
      <FormGroup row={true}>
        <Box height="25%" m={1}>
          {disabled === false && (
            <Button
              color="primary"
              onClick={addElement}
              size="small"
              startIcon={<Icon className="fa fa-plus-circle" style={{ color: green[500] }} />}
            >
              Agregar
            </Button>
          )}
        </Box>
        {isShowTotal !== undefined && (
          <>
            <Grid item xs={7}></Grid>
            <Grid item xs={3}>
              <TextField
                key={1}
                disabled={true}
                name={'total'}
                id="total"
                label={'Total'}
                className="number"
                value={totalCounter?.toFixed(2)}
                variant="filled"
              />
            </Grid>
          </>
        )}
      </FormGroup>
      <ListEditModal open={listEditModalOpen} handleClose={closeModal} elementToEdit={elementToEdit} options={options} />
    </div>
  )
}

export default ListComponent
