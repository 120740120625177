import InputField from '../../../components/inputField/InputField.js';
import SelectField from '../../../components/selectField/SelectField.js';
import DateField from '../../../components/dateField/DateField.js';
import CheckboxField from '../../../components/checkboxField/CheckboxField.js';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import {Button, Fab, FormGroup, useTheme} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import ListComponent from '../../../components/listComponent/ListComponent.js';
import ListJustification from '../../../components/listComponent/ListJustification.js';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import {useTranslation} from "react-i18next";
import Snackbar from '@material-ui/core/Snackbar';
import List from '@material-ui/core/List';
import shortid from "shortid";
import InputTextAreaField from "../../../components/inputField/InputTextAreaField";
import {Auth} from "@aws-amplify/auth";
import {fetchSecure} from "../../../modules/fetchSecure";
import {format as formatDate, subDays} from "date-fns";
import {InternalListComponent} from "../../../components/atoms/InternalListComponent";

export const WishItem = (props) => {


    const [wish, setWish] = useState({});
    const [subWishListSize, setSubWishListSize] = useState([]);

    const [loaded, setLoaded] = useState(false);

    const [balanceToBeCollected, setBalanceToBeCollected] = useState(0.0)

    const [errors, setErrors] = useState({})
    const [t] = useTranslation('common');
    const [open, setOpen] = useState(false);

    const [showConfidential, setShowConfidential] = useState(false)
    const [showClientNote, setShowClientNote] = useState(false)

    const history = useHistory()

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(async (user) => {
                setShowConfidential(['ignacio', 'carlos', 'lucas', 'sebastian', 'nacho', 'ivarela'].includes(user.username))
                setShowClientNote(['ignacio', 'carlos', 'lucas', 'sebastian', 'nacho', 'mimi', 'ivarela', 'june', 'ayelen'].includes(user.username))
            })
            .catch((e) => {
                console.error(JSON.stringify(e))
            })
    }, []);

    const goToPreviousPath = () => {
        history.goBack()
    }

    history.listen((location, action) => {
        if (action === 'POP') {
            history.replace(location.pathname, {search: props.match.params.search ? props.match.params.search : ''});
        }
    })

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const [savedStatus, setSavedStatus] = useState({saved: false, error: false});

    const handleChange = e => {
        const newWish = {...wish}
        newWish[e.target.name] = e.target.value;
        setWish(newWish);
    }

    const handleListChange = (listName, listValue) => {
        const newWish = {...wish}
        if (listName === 'deliveryDate' && !newWish.paymentLimitDate && listValue) {
            newWish.paymentLimitDate = subDays(listValue, 10)
        }

        newWish[listName] = listValue;
        setWish(newWish);
    }

    const handleCustomerChange = async (listName, listValue) => {
        const newWish = {...wish}
        newWish[listName] = listValue

        if (!wish.customerOpNumber) {
            const customerId = listValue.id
            const url = window.REACT_APP_BASE_API_URL + "/wish/getNextCustomerOpNumber/" + customerId
            const nextCustomerOpNumberJson = await fetchSecure(url, {method: 'GET'})
            const nextCustomerOpNumber = await nextCustomerOpNumberJson.json()
            newWish['customerOpNumber'] = nextCustomerOpNumber.nextCustomerOpNumber
        }
        setWish(newWish)
    }


    const [show, setShow] = useState(props.match.params.objectId === null)

    const handleShow = e => {
        setShow(e.target.checked)
    }

    const handleSave = () => {

        let method = 'POST';
        let url = window.REACT_APP_BASE_API_URL + '/wish';
        if (props.match.params.objectId != null) {
            method = 'PUT';
            url = url + "/" + wish.id
        }
        wish?.firstStageRequiredDocuments?.forEach((element) => {
            delete element['key']
            if (element['version']) {
                element['version'] = 0
            }
            element['type'] = 'firstStageDocument'
        })
        wish?.secondStageRequiredDocuments?.forEach((element) => {
            delete element['key']
            if (element['version']) {
                element['version'] = 0
            }
            element['type'] = 'secondStageDocument'
        })
        wish['supplierOrder'] = wish?.supplierOrder?.trim()

        const wishInJson = JSON.stringify(wish);
        const requestOptions = {
            method: method,
            headers: {'Content-Type': 'application/json'},
            body: wishInJson
        };

        fetchSecure(url, requestOptions)
            .then(async response => {

                    const data = await response.json();
                    if (!response.ok) {
                        setErrors(data.fields)
                        const errorMessage = (data && data.message) || response.status;
                        setOpen(true);
                        return Promise.reject(errorMessage);
                    } else {
                        getAndUpdateLocalWish().then(() => {
                        })
                        setErrors({})
                        props.match.params.objectId = data.id
                        setSavedStatus({saved: true, error: false})
                        setOpen(true);
                    }
                }
            ).catch(errorMessage => {
            setSavedStatus({saved: false, error: true, errorMessage: errorMessage})
        });
    }

    useEffect(() => {
        if (loaded && !wish.wishDate) {
            const newWish = {...wish}
            newWish.wishDate = new Date()
            setWish(newWish)
        }
    }, [loaded, wish])

    const getAndUpdateLocalWish = useCallback(async () => {
        const wishResponse = await fetchSecure(window.REACT_APP_BASE_API_URL + '/wish/' + props.match.params.objectId)
        const wishResponseJson = await wishResponse.json()
        setWish(wishResponseJson)
        setLoaded(true)

        const subWishesResponse = await fetchSecure(window.REACT_APP_BASE_API_URL + '/wish/' + props.match.params.objectId + '/subWish')
        const subWishesResponseJson = await subWishesResponse.json()
        setSubWishListSize(subWishesResponseJson.length)

    }, [props.match.params.objectId])

    useEffect(() => {
        if (Object.keys(errors).length === 0 && !loaded) {
            if (props.match.params.objectId != null) {
                getAndUpdateLocalWish().then(() => {
                })
            } else {
                setLoaded(true)
            }
        }
    }, [loaded, props.match.params.objectId, savedStatus, errors, getAndUpdateLocalWish])

    useEffect(() => {
        if (loaded && !wish.opNumber) {
            fetchSecure(window.REACT_APP_BASE_API_URL + '/wish/getNextOpNumber')
                .then(response => response.json())
                .then(data => {
                    const newWish = {...wish}
                    newWish.opNumber = data.nextOpNumber
                    setWish(newWish)
                })
        }
    }, [loaded, wish])


    const useStyles = makeStyles(theme => ({
            root: {
                width: '100%',
            },
            container: {},
            fab: {
                position: 'fixed',
                bottom: theme.spacing(2),
                right: theme.spacing(2),
            }
        }
    ));


    const classes = useStyles();

    const notesfields =
        {
            "elements": [
                {
                    "type": "inputTextAreaField",
                    "typeTextField": "text",
                    "id": "text",
                    "label": "Texto",
                    "disabled": show
                },
                {"type": "dateField", "id": "dateCreated", "label": "Fecha", "disabled": show}

            ]
        }

    const firstStageRequiredDocuments =
        {
            "elements": [
                {"type": "textField", "typeTextField": "text", "id": "fileName", "label": "Monto", "disabled": show},
                {"type": "dateField", "id": "received", "label": "Fecha", "disabled": show},
                {
                    "type": "select",
                    "typeTextField": "select",
                    "id": "courierRecord",
                    "label": "Registro courier",
                    "optionValue": "id",
                    "optionShow": "trackingNumber",
                    "url": window.REACT_APP_BASE_API_URL + '/documentsCourierRecords',
                    "disabled": show
                },
                {
                    "type": "select",
                    "typeTextField": "select",
                    "id": "documentType",
                    "label": "Tipo de documento",
                    "optionValue": "id",
                    "optionShow": "name",
                    "url": window.REACT_APP_BASE_API_URL + '/documentType',
                    "disabled": show
                },
                {
                    "type": "dateField",
                    "id": "deliveredToCustomsBrokerDate",
                    "label": "deliveredToCustomsBrokerDate",
                    "disabled": show
                },
                {
                    "type": "dateField",
                    "id": "deliveredToLawyerDate",
                    "label": "deliveredToLawyerDate",
                    "disabled": show
                },
                {
                    "type": "dateField",
                    "id": "deliveredToCustomerDate",
                    "label": "deliveredToCustomerDate",
                    "disabled": show
                },
                {"type": "upload", "id": "file", "label": "File", "disabled": show},
            ]
        }

    const secondStageRequiredDocuments =
        {
            "elements": [
                {"type": "textField", "typeTextField": "text", "id": "fileName", "label": "Monto", "disabled": show},
                {"type": "dateField", "id": "received", "label": "Fecha", "disabled": show},
                {
                    "type": "select",
                    "typeTextField": "select",
                    "id": "courierRecord",
                    "label": "Registro courier",
                    "optionValue": "id",
                    "optionShow": "trackingNumber",
                    "url": window.REACT_APP_BASE_API_URL + '/documentsCourierRecords',
                    "disabled": show
                },
                {
                    "type": "select",
                    "typeTextField": "select",
                    "id": "documentType",
                    "label": "Tipo de documento",
                    "optionValue": "id",
                    "optionShow": "name",
                    "url": window.REACT_APP_BASE_API_URL + '/documentType',
                    "disabled": show
                },
                {
                    "type": "dateField",
                    "id": "deliveredToCustomsBrokerDate",
                    "label": "deliveredToCustomsBrokerDate",
                    "disabled": show
                },
                {
                    "type": "dateField",
                    "id": "deliveredToLawyerDate",
                    "label": "deliveredToLawyerDate",
                    "disabled": show
                },
                {
                    "type": "dateField",
                    "id": "deliveredToCustomerDate",
                    "label": "deliveredToCustomerDate",
                    "disabled": show
                },
                {"type": "upload", "id": "file", "label": "File", "disabled": show},
            ]
        }

    const customsNotes =
        {
            "elements": [
                {
                    "type": "inputTextAreaField",
                    "typeTextField": "text",
                    "id": "text",
                    "label": "Texto",
                    "disabled": show
                },
                {
                    "type": "textField",
                    "typeTextField": "number",
                    "id": "balanzeTicket",
                    "label": "balanzeTicket",
                    "disabled": show
                }
            ]
        }

    const containersFields =
        {
            "elements": [
                {
                    "type": "select",
                    "typeTextField": "select",
                    "id": "typeOfFreight",
                    "label": "Tipo de contenedor",
                    "optionValue": "id",
                    "optionShow": "name",
                    "url": window.REACT_APP_BASE_API_URL + '/typeOfFreight',
                    "disabled": show
                },
                {
                    "type": "textField",
                    "typeTextField": "number",
                    "id": "quantity",
                    "label": "Cantidad",
                    "disabled": show
                },
            ]
        }

    const justificationList =
        {
            "elements": [
                {
                    "type": "inputTextAreaField",
                    "typeTextField": "text",
                    "id": "justification",
                    "label": "Justificacion",
                    "disabled": show
                },
                {"type": "textField", "typeTextField": "text", "id": "field", "label": "Campo", "disabled": true},
                {
                    "type": "textField",
                    "typeTextField": "text",
                    "id": "oldValue",
                    "label": "Valor viejo",
                    "disabled": true
                }

            ]
        }

    const handleJustification = (field, oldValue) => {
        if (props.match.params.objectId != null) {
            if (wish.justificationList === undefined) {
                wish.justificationList = [
                    {"key": shortid.generate()}
                ]
            } else {
                if (oldValue && oldValue !== 'Invalid Date') {
                    let justification = wish.justificationList.find(element => element.field === field && element.id === undefined)
                    if (justification === undefined) {
                        wish.justificationList.push({
                            key: shortid.generate(),
                            field: field,
                            oldValue: oldValue.split('T')[0]
                        })
                    }
                }
            }
            handleListChange('justificationList', wish.justificationList)
        }
    }

    const newSubWishTo = {
        pathname: "/wish/" + wish.id + "/subWish",
        wishOpNumber: wish.opNumber,
        wishCustomerName: wish.customer !== undefined ? wish.customer.name : "",
        wishSupplierOrder: wish.supplierOrder?.trim(),
        wishVersion: wish.version
    };

    const pricesToRender =
        {
            "elements": [
                {"type": "textField", "typeTextField": "number", "id": "price", "label": "Precio", "disabled": show},
                {
                    "type": "dateField",
                    "typeTextField": "dateField",
                    "id": "datePrice",
                    "label": "Fecha",
                    "disabled": show
                },
                {
                    "type": "inputTextAreaField",
                    "typeTextField": "inputTextAreaField",
                    "id": "comment",
                    "label": "Comentario",
                    "disabled": show
                },

            ]
        }

    const getPaymentDate = (balance, prices) => {
        if (balance < 1) {
            return prices?.sort((a, b) => b.date - a.date)[prices.length - 1]?.datePrice
        }
        return null
    }

    const getCalculate = (prices) => {
        let sum = 0
        prices?.forEach(p => {
            if (p.hasOwnProperty('price')) {
                sum = sum + Number(p.price)
            }
        })
        return sum
    }

    useEffect(() => {
        const calculatedBalanceToBeCollected = parseFloat(wish.fee ?? 0) + parseFloat(wish.extras ?? 0) + parseFloat(wish.visa ?? 0) - parseFloat(getCalculate(wish.prices) ?? 0)
        setBalanceToBeCollected(calculatedBalanceToBeCollected.toFixed(2))
    }, [wish.fee, wish.extras, wish.visa, wish.totalAmountCollected, wish.prices])

    return (
        <Paper className={classes.root}>
            <List>
                <Box m={2}>
                    <Button variant="contained" color="primary" onClick={goToPreviousPath}>Listado</Button>
                    {
                        wish.id !== undefined && <Link to={newSubWishTo}>
                            <Button variant="contained"
                                    color="primary">SubPedidos {'(' + subWishListSize + ')'}</Button>
                        </Link>
                    }
                </Box>

                <Box m={2}>

                    {savedStatus.error &&
                    <Snackbar open={open} onClose={handleClose}>
                        <Alert onClose={handleClose} variant="filled" severity="error">
                            Ha ocurrido un error inesperado al intentar grabar el pedido
                            {Object.keys(errors).map((it) =>
                                <Fragment>
                                    <li>
                                        {errors[it]}
                                    </li>
                                </Fragment>
                            )}
                        </Alert>
                    </Snackbar>
                    }

                    {savedStatus.saved &&
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} variant="filled" severity="success">El pedido se ha grabado con
                            éxito</Alert>
                    </Snackbar>
                    }

                    {props.match.params.objectId != null &&
                    <Box m={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={9}>
                            </Grid>
                            <Grid item xs={2}>
                                {t('button.show')} <Switch defaultChecked={show} color="primary" onClick={handleShow}/>
                            </Grid>
                        </Grid>
                    </Box>
                    }


                    <form className={classes.container} noValidate>

                        <Card className={classes.root} variant="outlined">
                            <CardContent>

                                <Typography variant="h6">Datos generales</Typography>
                                <FormGroup row={true}>
                                    <SelectField disabled={show} error={!!errors.supplier} name="supplier"
                                                 label="supplier" value={wish.supplier} changeHandler={handleListChange}
                                                 className={classes.textField}
                                                 url={window.REACT_APP_BASE_API_URL + '/supplier'} optionValue="id"
                                                 optionShow="name"/>
                                    <SelectField disabled={show} error={!!errors.customer} name="customer"
                                                 label="customer" value={wish.customer}
                                                 changeHandler={handleCustomerChange} className={classes.textField}
                                                 url={window.REACT_APP_BASE_API_URL + '/customer'} optionValue="id"
                                                 optionShow="name"/>
                                    <SelectField disabled={show} error={!!errors.customsBroker} name="customsBroker"
                                                 label="customsBroker" value={wish.customsBroker}
                                                 changeHandler={handleListChange} className={classes.textField}
                                                 url={window.REACT_APP_BASE_API_URL + '/customsBroker'} optionValue="id"
                                                 optionShow="name"/>
                                    <SelectField disabled={show} error={!!errors.forwarder} name="forwarder"
                                                 label="forwarder" value={wish.forwarder}
                                                 changeHandler={handleListChange} className={classes.textField}
                                                 url={window.REACT_APP_BASE_API_URL + '/forwarder'} optionValue="id"
                                                 optionShow="name"/>
                                    <SelectField disabled={show} error={!!errors.shipper} name="shipper" label="shipper"
                                                 value={wish.shipper} changeHandler={handleListChange}
                                                 className={classes.textField}
                                                 url={window.REACT_APP_BASE_API_URL + '/shipper'} optionValue="id"
                                                 optionShow="name"/>
                                    <SelectField disabled={show} error={!!errors.agent} name="agent" label="agent"
                                                 value={wish.agent} changeHandler={handleListChange}
                                                 className={classes.textField}
                                                 url={window.REACT_APP_BASE_API_URL + '/agent'} optionValue="id"
                                                 optionShow="name"/>
                                    <InputField disabled={show} error={!!errors.customsBrokerRefNumber}
                                                name="customsBrokerRefNumber" label="Nro ref despachante"
                                                value={wish.customsBrokerRefNumber} type="text" onChange={handleChange}
                                                className={classes.textField}/>
                                    <InputField disabled={show} error={!!errors.forwarderRefNumber}
                                                name="forwarderRefNumber" label="Nro ref forwarder"
                                                value={wish.forwarderRefNumber} type="text" onChange={handleChange}
                                                className={classes.textField}/>
                                </FormGroup>
                            </CardContent>
                        </Card>
                        <Card className={classes.root} variant="outlined">
                            <CardContent>
                                <Typography variant="h6">Estado de la carga</Typography>
                                <FormGroup row={true}>
                                    <CheckboxField disabled={show} name="hasNewProduct" label="¿Producto nuevo?"
                                                   value={wish.hasNewProduct} onChange={handleListChange}
                                                   className={classes.textField}/>
                                    <SelectField disabled={show} error={!!errors.forwarderRefNumber} name="wishStatus"
                                                 label="wishStatus" value={wish.wishStatus}
                                                 changeHandler={handleListChange} className={classes.textField}
                                                 url={window.REACT_APP_BASE_API_URL + '/wishStatus'} optionValue="id"
                                                 optionShow="name"/>
                                    <InputTextAreaField disabled={show} fullWidth={true} name="productDescription"
                                                        label="Descripción producto" value={wish.productDescription}
                                                        type="text" onChange={handleChange}/>
                                    <CheckboxField disabled={show} name="picturesOfPrintingBoxesNotReceive"
                                                   label="No se recibirán fotos de impresión de cajas y carga terminada"
                                                   value={wish.picturesOfPrintingBoxesNotReceive}
                                                   onChange={handleListChange} className={classes.textField}/>
                                    <CheckboxField disabled={show} name="picturesOfLoadingContainerNotReceive"
                                                   label="No se recibirán fotos del container cargado"
                                                   value={wish.picturesOfLoadingContainerNotReceive}
                                                   onChange={handleListChange} className={classes.textField}/>
                                    <CheckboxField disabled={show} name="productionConfirmed"
                                                   value={wish.productionConfirmed} onChange={handleListChange}
                                                   className={classes.textField}/>
                                    <CheckboxField disabled={show} name="specialPackage" value={wish.specialPackage}
                                                   onChange={handleListChange} className={classes.textField}/>
                                    <CheckboxField disabled={show} name="openProforma" value={wish.openProforma}
                                                   onChange={handleListChange} className={classes.textField}/>

                                    <CheckboxField disabled={show} name="palletizeDepFiscal"
                                                   value={wish.palletizeDepFiscal} onChange={handleListChange}
                                                   className={classes.textField}/>
                                    <CheckboxField disabled={show} name="transportDepFiscal"
                                                   value={wish.transportDepFiscal} onChange={handleListChange}
                                                   className={classes.textField}/>

                                </FormGroup>
                            </CardContent>
                        </Card>

                        <Card className={classes.root} variant="outlined">
                            <CardContent>
                                <Typography variant="h6">Referencias</Typography>
                                <FormGroup row={true}>
                                    <InputField disabled={show} error={!!errors.opNumber} name="opNumber"
                                                label="Número de op" value={wish.opNumber} type="number"
                                                onChange={handleChange} className={classes.textField}/>
                                    <InputField disabled={show} error={!!errors.customerOpNumber}
                                                name="customerOpNumber" label="Número de op del cliente"
                                                value={wish.customerOpNumber} type="number" onChange={handleChange}
                                                className={classes.textField}/>
                                    <InputField disabled={show} error={!!errors.supplierOrder} name="supplierOrder"
                                                label="Orden" value={wish.supplierOrder} type="text"
                                                onChange={handleChange} className={classes.textField}/>
                                </FormGroup>
                            </CardContent>
                        </Card>

                        <Card className={classes.root} variant="outlined">
                            <CardContent>
                                <Typography variant="h6">Fechas</Typography>
                                <FormGroup row={true}>
                                    <DateField disabled={show} error={!!errors.wishDate} name="wishDate"
                                               label="Fecha de pedido" value={wish.wishDate} onChange={handleListChange}
                                               className={classes.textField}/>
                                    <DateField disabled={show} error={!!errors.proformaDate} name="proformaDate"
                                               value={wish.proformaDate} onChange={handleListChange}
                                               className={classes.textField}/>

                                    <DateField disabled={show} error={!!errors.cartonPrintingInfoSentDate}
                                               name="cartonPrintingInfoSentDate" label="Fecha de envío de diseños"
                                               value={wish.cartonPrintingInfoSentDate} onChange={handleListChange}
                                               className={classes.textField}/>
                                    <DateField disabled={show} error={!!errors.informationFileSentDate}
                                               name="informationFileSentDate" value={wish.informationFileSentDate}
                                               onChange={handleListChange} className={classes.textField}/>
                                    {wish.justificationList && wish.justificationList.find((element) =>
                                        element.field === "Fecha de finalización de la producción" || element.field === "deliveryDate"
                                    ) ?
                                        <DateField style={{backgroundColor: "#F7FADC"}}
                                                   onChangeField={() => handleJustification('deliveryDate',wish.deliveryDate)} disabled={show}
                                                   error={!!errors.deliveryDate} name="deliveryDate"
                                                   label="Fecha de finalización de la producción"
                                                   value={wish.deliveryDate}
                                                   onChange={handleListChange} handleListChange={classes.textField}/>
                                        : <DateField onChangeField={() => handleJustification('deliveryDate',wish.deliveryDate)} disabled={show}
                                                     error={!!errors.deliveryDate} name="deliveryDate"
                                                     label="Fecha de finalización de la producción"
                                                     value={wish.deliveryDate} onChange={handleListChange}
                                                     handleListChange={classes.textField}/>
                                    }
                                    <DateField disabled={show} error={!!errors.paymentLimitDate} name="paymentLimitDate"
                                               value={wish.paymentLimitDate} onChange={handleListChange}
                                               className={classes.textField}/>

                                    <CheckboxField disabled={show} name="deliveryDateConfirmed"
                                                   label="¿Fecha de finalización de la producción confirmada?"
                                                   value={wish.deliveryDateConfirmed} onChange={handleListChange}
                                                   className={classes.textField}/>
                                    {wish.justificationList && wish.justificationList.find((element) =>
                                        element.field === "Fecha estimada de salida del buque" || element.field === "estimatedTimeOfDeparture"
                                    ) ?
                                        <DateField style={{background: "#F7FADC"}} onChangeField={() => handleJustification('estimatedTimeOfDeparture',wish.estimatedTimeOfDeparture)}
                                                   disabled={show}
                                                   error={!!errors.estimatedTimeOfDeparture}
                                                   name="estimatedTimeOfDeparture"
                                                   label="Fecha estimada de salida del buque"
                                                   value={wish.estimatedTimeOfDeparture} onChange={handleListChange}
                                                   className={classes.textField}/>
                                        : <DateField onChangeField={() => handleJustification('estimatedTimeOfDeparture',wish.estimatedTimeOfDeparture)} disabled={show}
                                                     error={!!errors.estimatedTimeOfDeparture}
                                                     name="estimatedTimeOfDeparture"
                                                     label="Fecha estimada de salida del buque"
                                                     value={wish.estimatedTimeOfDeparture} onChange={handleListChange}
                                                     className={classes.textField}/>

                                    }
                                    <CheckboxField disabled={show} name="departed" label="¿Salió?" value={wish.departed}
                                                   onChange={handleListChange} className={classes.textField}/>
                                    {wish.justificationList && wish.justificationList.find((element) =>
                                        element.field === "Fecha estimada de arribo del buque" || element.field === "estimatedTimeOfArrival"
                                    ) ?
                                        <DateField style={{background: "#F7FADC"}} onChangeField={() => handleJustification('estimatedTimeOfArrival',wish.estimatedTimeOfArrival)}
                                                   disabled={show}
                                                   error={!!errors.estimatedTimeOfArrival}
                                                   name="estimatedTimeOfArrival"
                                                   label="Fecha estimada de arribo del buque"
                                                   value={wish.estimatedTimeOfArrival} onChange={handleListChange}
                                                   className={classes.textField}/>
                                        : <DateField onChangeField={() => handleJustification('estimatedTimeOfArrival',wish.estimatedTimeOfArrival)} disabled={show}
                                                     error={!!errors.estimatedTimeOfArrival}
                                                     name="estimatedTimeOfArrival"
                                                     label="Fecha estimada de arribo del buque"
                                                     value={wish.estimatedTimeOfArrival} onChange={handleListChange}
                                                     className={classes.textField}/>
                                    }
                                    <CheckboxField disabled={show} name="arrived" label="¿Arribó?" value={wish.arrived}
                                                   onChange={handleListChange} className={classes.textField}/>
                                    <DateField disabled={show} error={!!errors.containerWithdrawalDate}
                                               name="containerWithdrawalDate" value={wish.containerWithdrawalDate}
                                               onChange={handleListChange} className={classes.textField}/>
                                    <DateField disabled={show} error={!!errors.truckLoadingDate} name="truckLoadingDate"
                                               value={wish.truckLoadingDate} onChange={handleListChange}
                                               className={classes.textField}/>
                                </FormGroup>
                            </CardContent>
                        </Card>

                        <Card className={classes.root} variant="outlined">
                            <CardContent>
                                <Typography variant="h6">Información pagos</Typography>
                                <FormGroup row={true}>
                                    <SelectField disabled={show} error={!!errors.priceCondition} name="priceCondition"
                                                 label="priceCondition" value={wish.priceCondition}
                                                 changeHandler={handleListChange} className={classes.textField}
                                                 url={window.REACT_APP_BASE_API_URL + '/priceCondition'}
                                                 optionValue="id" optionShow="name"/>
                                    <SelectField disabled={show} error={!!errors.currency} name="currency"
                                                 label="currency" value={wish.currency} changeHandler={handleListChange}
                                                 className={classes.textField}
                                                 url={window.REACT_APP_BASE_API_URL + '/currency'} optionValue="id"
                                                 optionShow="name"/>
                                    <InputField disabled={show} error={!!errors.currencyFob} name="currencyFob"
                                                label="Total FOB" value={wish.currencyFob} type="number"
                                                onChange={handleChange} className={classes.textField}/>
                                    <SelectField disabled={show} error={!!errors.paymentStatus} name="paymentStatus"
                                                 label="paymentStatus" value={wish.paymentStatus}
                                                 changeHandler={handleListChange} className={classes.textField}
                                                 url={window.REACT_APP_BASE_API_URL + '/paymentStatus'} optionValue="id"
                                                 optionShow="name"/>
                                    <SelectField disabled={show} error={!!errors.paymentTerm} name="paymentTerm"
                                                 label="paymentTerm" value={wish.paymentTerm}
                                                 changeHandler={handleListChange} className={classes.textField}
                                                 url={window.REACT_APP_BASE_API_URL + '/paymentTerm'} optionValue="id"
                                                 optionShow="name"/>

                                </FormGroup>
                                <Divider/>

                            </CardContent>
                        </Card>

                        {wish.id && <WishPaymentList wishId={wish.id} currencyFob={wish.currencyFob}/>}

                        <Card className={classes.root} variant="outlined">
                            <CardContent>
                                <Typography variant="h6">Documentos primarios</Typography>
                                <FormGroup row={true}>
                                    <ListComponent disabled={show} errorsList={errors} onChangeList={handleListChange}
                                                   elementToRender={firstStageRequiredDocuments}
                                                   elementToUpload={wish.firstStageRequiredDocuments}
                                                   listName={"firstStageRequiredDocuments"}/>
                                </FormGroup>
                            </CardContent>
                        </Card>

                        <Card className={classes.root} variant="outlined">
                            <CardContent>
                                <Typography variant="h6">Documentos secundarios</Typography>
                                <FormGroup row={true}>
                                    <ListComponent disabled={show} errorsList={errors} onChangeList={handleListChange}
                                                   elementToRender={secondStageRequiredDocuments}
                                                   elementToUpload={wish.secondStageRequiredDocuments}
                                                   listName={"secondStageRequiredDocuments"}/>
                                </FormGroup>
                            </CardContent>
                        </Card>

                        <Card className={classes.root} variant="outlined">
                            <CardContent>
                                <Typography variant="h6">SIMI asociada</Typography>
                                <FormGroup row={true}>
                                    <SelectField disabled={show} error={!!errors.selectedSimi} name="selectedSimi"
                                                 value={wish.selectedSimi} changeHandler={handleListChange}
                                                 className={classes.textField}
                                                 url={wish.id ? window.REACT_APP_BASE_API_URL + '/wish/' + wish.id + '/simiList' : null}
                                                 optionValue="id" optionShow="simiNumber"/>
                                    <DateField disabled={show} error={false} name="expectedSimiExtendedExpirationDate"
                                               label="Fecha de vencimiento con prorroga aprobada"
                                               value={wish.selectedSimi?.expectedSimiExtendedExpirationDate}
                                               onChange={() => {
                                               }} className={classes.textField}/>
                                </FormGroup>
                            </CardContent>
                        </Card>

                        <Card className={classes.root} variant="outlined">
                            <CardContent>
                                <Typography variant="h6">Posición arancelaria</Typography>
                                <FormGroup row={true}>
                                    <InputField disabled={show} error={!!errors.hsCodeToBeWritten}
                                                name="hsCodeToBeWritten" label="Posición arancelaria a declarar en BL"
                                                value={wish.hsCodeToBeWritten} type="text" onChange={handleChange}
                                                className={classes.textField}/>
                                    <SelectField disabled={show} error={!!errors.criterionValue} name="criterionValue"
                                                 label="criterionValue" value={wish.criterionValue}
                                                 changeHandler={handleListChange} className={classes.textField}
                                                 url={window.REACT_APP_BASE_API_URL + '/criterionValue'}
                                                 optionValue="id" optionShow="name"/>
                                    <InputField disabled={show} error={!!errors.visaChargePayment}
                                                name="visaChargePayment" label="Importe del pago de visados"
                                                value={wish.visaChargePayment} type="number" onChange={handleChange}
                                                className={classes.textField}/>
                                    <CheckboxField disabled={show} name="visaSecondStage"
                                                   label="¿Se envió a visar la documentación (segunda etapa)?"
                                                   value={wish.visaSecondStage} onChange={handleListChange}
                                                   className={classes.textField}/>

                                </FormGroup>
                            </CardContent>
                        </Card>

                        <Card className={classes.root} variant="outlined">
                            <CardContent>
                                <Typography variant="h6">Drafts</Typography>
                                <FormGroup row={true}>
                                    <DateField disabled={show} error={!!errors.docDraftApproved} name="docDraftApproved"
                                               label="Draft aprobado" value={wish.docDraftApproved}
                                               onChange={handleListChange} className={classes.textField}/>
                                    <CheckboxField disabled={show} name="docDraftNotReceive"
                                                   label="No se recibirán drafts" value={wish.docDraftNotReceive}
                                                   onChange={handleListChange} className={classes.textField}/>

                                </FormGroup>
                            </CardContent>
                        </Card>

                        <Card className={classes.root} variant="outlined">
                            <CardContent>
                                <Typography variant="h6">Transporte</Typography>
                                <FormGroup row={true}>

                                    <SelectField disabled={show} error={!!errors.sourceCountry} name="sourceCountry"
                                                 label="sourceCountry" value={wish.sourceCountry}
                                                 changeHandler={handleListChange} className={classes.textField}
                                                 url={window.REACT_APP_BASE_API_URL + '/country'} optionValue="id"
                                                 optionShow="name"/>
                                    <SelectField disabled={show} error={!!errors.port} name="port" label="port"
                                                 value={wish.port} changeHandler={handleListChange}
                                                 className={classes.textField}
                                                 url={window.REACT_APP_BASE_API_URL + '/port'} optionValue="id"
                                                 optionShow="name"/>
                                    <SelectField disabled={show} error={!!errors.ship} name="ship" label="ship"
                                                 value={wish.ship} changeHandler={handleListChange}
                                                 className={classes.textField}
                                                 url={window.REACT_APP_BASE_API_URL + '/ship'} optionValue="id"
                                                 optionShow="name"/>

                                    <CheckboxField disabled={show} name="hasFeeder" label="¿Tiene feeder?"
                                                   value={wish.hasFeeder} onChange={handleListChange}
                                                   className={classes.textField}/>

                                    <InputField disabled={show} error={!!errors.blNumber} name="blNumber"
                                                label="Número de BL" value={wish.blNumber} type="text"
                                                onChange={handleChange} className={classes.textField}/>

                                    <CheckboxField disabled={show} name="blCopyReceived"
                                                   label="¿Copia de BL recibida del forwarder?"
                                                   value={wish.blCopyReceived} onChange={handleListChange}
                                                   className={classes.textField}/>
                                    <InputField disabled={show} error={!!errors.freightQuote} name="freightQuote"
                                                label="Cotización flete" value={wish.freightQuote} type="number"
                                                onChange={handleChange} className={classes.textField}/>
                                    <InputField disabled={show} error={!!errors.priceCertificateFreight}
                                                name="priceCertificateFreight" label="Cotización flete certificado"
                                                value={wish.priceCertificateFreight} type="number"
                                                onChange={handleChange} className={classes.textField}/>

                                    <InputField disabled={show} error={!!errors.loadSecuredPercent}
                                                name="loadSecuredPercent" label="% Seg de la carga"
                                                value={wish.loadSecuredPercent} type="number" onChange={handleChange}
                                                className={classes.textField}/>
                                    <InputField disabled={show} error={!!errors.vendorTicket} name="vendorTicket"
                                                label="Ticket balanza" value={wish.vendorTicket} type="number"
                                                onChange={handleChange} className={classes.textField}/>
                                    <InputField disabled={show} error={!!errors.portTerminal} name="portTerminal"
                                                value={wish.portTerminal} type="text" onChange={handleChange}
                                                className={classes.textField}/>
                                    <InputTextAreaField disabled={show} name="forwarderContract"
                                                        label="Contrato forwarder" value={wish.forwarderContract}
                                                        type="text" onChange={handleChange}/>
                                    <InputField disabled={show} error={!!errors.containerNumber} name="containerNumber"
                                                value={wish.containerNumber} type="text"
                                                onChange={handleChange} className={classes.textField}/>

                                    <SelectField disabled={show} error={!!errors.maritimeLine} name="maritimeLine"
                                                 value={wish.maritimeLine}
                                                 changeHandler={handleListChange} className={classes.textField}
                                                 url={window.REACT_APP_BASE_API_URL + '/maritimeLine'} optionValue="id"
                                                 optionShow="name"/>
                                </FormGroup>
                            </CardContent>
                        </Card>

                        <Card className={classes.root} variant="outlined">
                            <CardContent>
                                <Typography variant="h6">Información final</Typography>
                                <FormGroup row={true}>
                                    <InputField disabled={show} error={!!errors.bill} name="bill" label="FC GPY"
                                                value={wish.bill} type="text" onChange={handleChange}
                                                className={classes.textField}/>
                                    <DateField disabled={show} error={!!errors.billDate} name="billDate"
                                               label="Fecha de factura Cayas" value={wish.billDate}
                                               onChange={handleListChange} className={classes.textField}/>
                                    <DateField disabled={show} error={!!errors.taxRegistryNumberAndCuitVerification}
                                               name="taxRegistryNumberAndCuitVerification"
                                               label="Verificar Tax Registry en Cl y CUIT en BL"
                                               value={wish.taxRegistryNumberAndCuitVerification}
                                               onChange={handleListChange} className={classes.textField}/>
                                    <InputField disabled={show} error={!!errors.dispatchNumber} name="dispatchNumber"
                                                label="Despacho Número" value={wish.dispatchNumber} type="text"
                                                onChange={handleChange} className={classes.textField}/>
                                    <DateField disabled={show} error={!!errors.finishDate} name="finishDate"
                                               label="Fecha de finalización" value={wish.finishDate}
                                               onChange={handleListChange} className={classes.textField}/>
                                    {showClientNote &&
                                    <InputTextAreaField disabled={show} error={!!errors.clientNote} name="clientNote"
                                                        label="Nota para el cliente" value={wish.clientNote} type="text"
                                                        onChange={handleChange} className={classes.textField}/>}
                                    <DateField disabled={show} error={!!errors.officializationDate}
                                               name="officializationDate" label="Fecha de oficialización del despacho"
                                               value={wish.officializationDate} onChange={handleListChange}
                                               handleListChange={classes.textField}/>
                                    <InputField disabled={show} error={!!errors.exchangeRate} name="exchangeRate"
                                                label="Tipo de cambio" value={wish.exchangeRate} type="number"
                                                onChange={handleChange} className={classes.textField}/>
                                </FormGroup>
                            </CardContent>
                        </Card>

                        {showConfidential && <Card className={classes.root} variant="outlined">
                            <CardContent>
                                <Typography variant="h6">Información confidencial</Typography>
                                <FormGroup row={true}>

                                    <InputField disabled={show} error={!!errors.fee} name="fee" label="Fee"
                                                value={wish.fee}
                                                type="number" onChange={handleChange} className={classes.textField}/>
                                    <InputField disabled={show} error={!!errors.shipperFee} name="shipperFee"
                                                value={wish.shipperFee}
                                                type="number" onChange={handleChange} className={classes.textField}/>
                                    <InputField disabled={show} error={!!errors.totalAmountCollected}
                                                name="totalAmountCollected" value={getCalculate(wish.prices)}
                                                type="number" onChange={handleChange} className={classes.textField}/>
                                    <InputField disabled={show} error={!!errors.extras} name="extras" label="extras"
                                                value={wish.extras} type="number" onChange={handleChange}
                                                className={classes.textField}/>
                                    <InputField disabled={show} error={!!errors.visa} name="visa" label="visa"
                                                value={wish.visa} type="number" onChange={handleChange}
                                                className={classes.textField}/>
                                    <CheckboxField disabled={show} name="confirmed" label="¿confirmed?"
                                                   value={wish.confirmed} onChange={handleListChange}
                                                   className={classes.textField}/>
                                    <CheckboxField disabled={show} name="toBeConfirmed" label="¿toBeConfirmed?"
                                                   value={wish.toBeConfirmed} onChange={handleListChange}
                                                   className={classes.textField}/>
                                    <SelectField disabled={show} error={!!errors.supplierContact} name="supplierContact"
                                                 label="supplierContact" value={wish.supplierContact}
                                                 changeHandler={handleListChange} className={classes.textField}
                                                 url={wish.supplier !== undefined ? window.REACT_APP_BASE_API_URL + '/stakeholders/' + wish.supplier.id + '/contacts' : undefined}
                                                 optionValue="id" optionShow="name"/>

                                    <InputField disabled={true} error={!!errors.balanceToBeCollected}
                                                name="balanceToBeCollected" value={balanceToBeCollected} type="number"
                                                onChange={handleChange} className={classes.textField}/>

                                    <DateField disabled={show} error={!!errors.paymentDate} name="paymentDate"
                                               label="Fecha de cobro"
                                               value={getPaymentDate(balanceToBeCollected, wish.prices)}
                                               onChange={handleListChange} handleListChange={classes.textField}/>

                                    <InputField disabled={show} error={!!errors.eWay} name="eWay" value={wish.eWay}
                                                type="number" onChange={handleChange} className={classes.textField}/>

                                    <InputTextAreaField disabled={show} fullWidth={true} name="comments"
                                                        label="Comentarios" value={wish.comments} type="text"
                                                        onChange={handleChange}/>

                                    <InputField disabled={show} error={!!errors.prova} name="prova"
                                                value={wish.prova} type="number" onChange={handleChange}
                                                className={classes.textField}/>
                                </FormGroup>
                                <FormGroup row={true}>
                                    <CardContent>
                                        <Typography variant="h6">Pagos Realizados</Typography>
                                        <FormGroup row={true}>
                                            <ListComponent disabled={show}
                                                           errorsList={errors}
                                                           onChangeList={handleListChange}
                                                           elementToRender={pricesToRender}
                                                           elementToUpload={wish.prices} listName={"prices"}/>
                                        </FormGroup>
                                    </CardContent>
                                </FormGroup>

                            </CardContent>
                        </Card>
                        }

                        <Card className={classes.root} variant="outlined">
                            <CardContent>
                                <Typography variant="h6">Observaciones aduana</Typography>
                                <FormGroup row={true}>
                                    <ListComponent disabled={show} errorsList={errors} onChangeList={handleListChange}
                                                   elementToRender={customsNotes} elementToUpload={wish.customsNotes}
                                                   listName={"customsNotes"}/>
                                </FormGroup>
                            </CardContent>
                        </Card>

                        <Card className={classes.root} variant="outlined">
                            <CardContent>

                                <Typography variant="h6">Notas</Typography>
                                <FormGroup row={true}>
                                    <ListComponent disabled={show} errorsList={errors} onChangeList={handleListChange}
                                                   elementToRender={notesfields} elementToUpload={wish.notes}
                                                   listName={"notes"}/>
                                </FormGroup>
                            </CardContent>
                        </Card>

                        <Card className={classes.root} variant="outlined">
                            <CardContent>
                                <Typography variant="h6">Contenedores</Typography>
                                <FormGroup row={true}>
                                    <ListComponent disabled={show} errorsList={errors} onChangeList={handleListChange}
                                                   elementToRender={containersFields}
                                                   elementToUpload={wish.containerWishes} listName={"containerWishes"}/>
                                </FormGroup>
                            </CardContent>
                        </Card>

                        <Card className={classes.root} variant="outlined">
                            <CardContent>

                                <Typography variant="h6">Justificaciones</Typography>
                                <FormGroup row={true}>
                                    <ListJustification disabled={show} errorsList={errors}
                                                       onChangeList={handleListChange}
                                                       elementToRender={justificationList}
                                                       elementToUpload={wish.justificationList}
                                                       listName={"justificationList"}/>
                                </FormGroup>
                            </CardContent>
                        </Card>

                        {show === false &&
                        <Fab variant="extended" color="primary" onClick={handleSave}
                             className={classes.fab}>Guardar</Fab>
                        }
                    </form>
                </Box>
            </List>

        </Paper>

    )

}

const WishPaymentList = ({wishId, currencyFob}) => {

    const theme = useTheme()

    const [totalPayment, setTotalPayment] = useState(0)
    const [balance, setBalance] = useState(0)

    const functionOnResult = async (payments) => {
        let calculatedTotalPayment = 0
        for (let i = 0; i < payments.length; i++) {
            calculatedTotalPayment = calculatedTotalPayment + (payments[i]?.amount ?? 0)
        }
        setTotalPayment(calculatedTotalPayment)
    }

    useEffect(() => {
        if (currencyFob && currencyFob > 0) {
            setBalance(currencyFob - totalPayment)

        }
    }, [currencyFob, totalPayment])

    const columns = [
        {id: 'amount', minWidth: 100, label: 'Monto'},
        {
            id: 'date', minWidth: 100, label: 'Fecha', format: (date) => {
                return formatDate(new Date(date), 'dd/MM/yyyy')
            }
        },
        {
            id: 'swiftReceivedDate', minWidth: 100, label: 'Fecha de recepción de swifft', format: (date) => {
                return formatDate(new Date(date), 'dd/MM/yyyy')
            }
        },
        {
            id: 'swiftSentDate', minWidth: 100, label: 'Fecha de envío de swifft', format: (date) => {
                return formatDate(new Date(date), 'dd/MM/yyyy')
            }
        },
        {id: 'bank.description', minWidth: 100, label: 'Banco'},
        {id: 'paymentMethod.name', minWidth: 100, label: 'Forma de pago'},
    ]

    const editInitialState = {
        amount: 0, date: new Date(new Date().toDateString()), swiftReceivedDate: new Date(new Date().toDateString()),
        swiftSentDate: new Date(new Date().toDateString()), bankId: -1, wishId
    }

    const editBaseSaveUrl = window.REACT_APP_BASE_API_URL + '/wishPayment'

    const editFields = [
        {name: 'amount', type: 'DecimalField'},
        {name: 'date', type: 'DateField'},
        {name: 'swiftReceivedDate', type: 'DateField'},
        {name: 'swiftSentDate', type: 'DateField'},
        {
            name: 'bankId',
            type: 'SimpleSelectField',
            url: window.REACT_APP_BASE_API_URL + '/bank',
            optionValue: 'id',
            optionShow: 'description'
        },
        {
            name: 'paymentMethodId',
            type: 'SimpleSelectField',
            url: window.REACT_APP_BASE_API_URL + '/paymentMethod',
            optionValue: 'id',
            optionShow: 'name'
        },
        {name: 'swiftFileToken', type: 'File'}
    ]

    const fetchUrl = window.REACT_APP_BASE_API_URL + '/wish/' + wishId + '/wishPaymentList'

    return <Card style={{margin: theme.spacing(1, 0, 1, 0), padding: theme.spacing(2, 2, 2, 2)}}>
        <Typography style={{marginBottom: theme.spacing(2)}} variant={'h6'}>Listado de pagos</Typography>

        <InternalListComponent columns={columns} fetchUrl={fetchUrl} editInitialState={editInitialState}
                               editBaseSaveUrl={editBaseSaveUrl} editFields={editFields}
                               functionOnResult={functionOnResult}/>

        <InputField style={{marginTop: theme.spacing(1)}} disabled={true} name="amountOperation" label="Saldo operacion"
                    value={balance.toFixed(2)} type="number"/>

    </Card>


}
