import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom'
import i18n from './modules/i18n';
import {I18nextProvider} from "react-i18next";

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<I18nextProvider i18n={i18n}>
				<App />
			</I18nextProvider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
)


serviceWorker.unregister();
