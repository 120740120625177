import React from 'react';
import { Checkbox, Box,FormGroup, FormControlLabel} from '@material-ui/core';

import {useTranslation} from "react-i18next";

function CheckboxField({name, value, onChange, className,disabled}){

	const [t] = useTranslation('common');

	const handleChange = e => {
    	onChange(name, e.target.checked)
  	}

	let currentValue = false;
	if(value != null) {
		currentValue = value;
	}

  return <Box height={1/8} m={2} >  <FormGroup >
  	    <FormControlLabel
          control={<Checkbox
				disabled={disabled}
		  		checked={currentValue}
		  		onChange={handleChange}
		  		name={name} id={name}
		  		className={className}
				/>
          }
          label={<span style={{ fontSize: '0.8rem' }}>{t(name)}</span>}
          labelPlacement="start"
        />
        </FormGroup></Box>
}

export default CheckboxField
