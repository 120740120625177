import {Auth} from "@aws-amplify/auth";


export const fetchSecure = async (input, init = {}) => {
    const session = await Auth.currentSession()
    const token = session.getIdToken().getJwtToken()
    const headers = {...init?.headers, 'Authorization': 'Bearer '+token}
    const newInit = {...init,headers}
    return fetch(input, newInit)

}
