import {ListPage} from "../../../components/templates/ListPage"
import {format as formatDate} from "date-fns"
import {useHistory} from "react-router-dom"

export const Simi = () => {
	const history = useHistory()

	const columns = [
		{ id: 'wish.customer.name', label: 'Cliente', minWidth: 100 },
		{ id: 'wish.supplierOrder', label: 'Nro de orden', minWidth: 100, onClick: async (element) => {history.push('/wish/edit/'+element.wish.id+'/null')} },
		{ id: 'simiNumber', label: 'Nro de SIMI', minWidth: 100 },
		{ id: 'approved', type: 'boolean', label: 'Aprobada', minWidth: 100, calculateFunction: (simi) => {return !!simi.automaticAndNonAutomaticSimiApprovedDate} },
		{ id: 'officializationDate', label: 'Fecha de oficialización', minWidth: 100, type: 'date', format: (date) => {return formatDate(new Date(date), 'dd/MM/yyyy')} },
		{ id: 'associatedSimiCount', label: 'Oficializadas', minWidth: 170 }
	]



	return <ListPage createToPath={"/simi/create"} columns={columns} fetchUrl={window.REACT_APP_BASE_API_URL + '/simi/active'} editPath={"/simi/edit/"} />
}
