// imports the React Javascript Library
import React from "react";
//Card
import Card from "@material-ui/core/Card";

import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";

//Tabs
import { withStyles } from "@material-ui/core/styles";


const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        marginLeft: 8,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end"
    },
    icon: {
        margin: theme.spacing.unit * 2
    },
    iconHover: {
        margin: theme.spacing.unit * 2,
        "&:hover": {
            color: red[800]
        }
    },
    cardHeader: {
        textalign: "center",
        align: "center",
        backgroundColor: "white"
    },
    title: {
        color: blue[800],
        fontWeight: "bold",
        fontFamily: "Montserrat",
        align: "center"
    },
    button: {
        color: blue[900],
        margin: 10
    },
    secondaryButton: {
        color: "gray",
        margin: 10
    },
    typography: {
        margin: theme.spacing.unit * 2,
        backgroundColor: "default"
    },

    searchRoot: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 400
    },
    searchInput: {
        marginLeft: 8,
        flex: 1
    },
    searchIconButton: {
        padding: 10
    },
    searchDivider: {
        width: 1,
        height: 28,
        margin: 4
    }
});

class Upload extends React.Component {
    state = {
        mainState: "initial",
        imageUploaded: 0,
        selectedFile: null
    };

    handleUploadClick = event => {
        var reader = new FileReader();
        var fileByteArray = [];
        reader.readAsArrayBuffer(event.target.files[0]);
        reader.onloadend = function (evt) {
            if (evt.target.readyState === FileReader.DONE) {
                var arrayBuffer = evt.target.result,
                    array = new Uint8Array(arrayBuffer);
                for (var i = 0; i < array.length; i++) {
                    fileByteArray.push(array[i]);
                }
            }
        }
        this.props.onChange(fileByteArray)
        this.setState({
            selectedFile: event.target.files[0],
            imageUploaded: 1
        });
    };


    renderInitialState() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                        <input
                            accept="*"
                            className={classes.input}
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={this.handleUploadClick}
                        />
            </React.Fragment>
        );
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <div className={classes.root}>
                    <Card className={this.props.cardName}>
                        {(this.renderInitialState())}
                    </Card>
                </div>
            </React.Fragment>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Upload);
