import { Button, Fab, FormGroup } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'
import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ListComponent from '../../../components/listComponent/ListComponent.js'
import SelectField from '../../../components/selectField/SelectField.js'
import InputField from '../../../components/inputField/InputField.js'
import { useTranslation } from 'react-i18next'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import { fetchSecure } from '../../../modules/fetchSecure'
import LoadingScreen from '../../../components/loading/LoadingScreen.js'
import { Box } from '@mui/material'

function SubWishItem(props) {
  const [subWish, setSubWish] = useState(null)
  const [wish, setWish] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [valueItem, setValueItem] = useState(0)

  const [savedStatus, setSavedStatus] = useState({ saved: false, error: false })

  const [errors, setErrors] = useState({})
  const [t] = useTranslation('common')

  const [open, setOpen] = React.useState(false)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const handleListChange = (listName, listValue) => {
    let newSubWish = { ...subWish }
    newSubWish[listName] = listValue
    if (newSubWish.subWishItemList) {
      let totalCounter = 0
      newSubWish.subWishItemList.forEach(it => {
        const subtotal = (it.unitPrice === undefined ? 0 : it.unitPrice) * (it.quantity === undefined ? 0 : it.quantity)
        it.subtotal = subtotal.toFixed(2)
        if (!it.codePerCustomer) {
          const wishCustomerId = wish?.customer?.id
          it.codePerCustomer = it?.product?.codePerCustomerList?.filter(
            codePerCustomerItem => codePerCustomerItem.customer.id === wishCustomerId
          )?.[0]
        }

        it.productDescription = it?.product?.descriptionSP ?? it?.codePerCustomer?.product?.descriptionSP
        totalCounter = totalCounter + subtotal
      })
      setValueItem(totalCounter)
    }
    setSubWish(newSubWish)
  }
  const [show, setShow] = useState(props.match.params.objectId != null)

  const handleShow = e => {
    setShow(e.target.checked)
  }

  const handleSave = () => {
    let method = 'POST'
    let url = window.REACT_APP_BASE_API_URL + '/subWish'
    if (props.match.params.objectId != null) {
      method = 'PUT'
      url = url + '/' + subWish.id
      subWish.wish = {
        id: subWish.wish.id === undefined ? subWish.wish : subWish.wish.id,
        version: wish.version,
      }
    } else {
      subWish.wishId = props.match.params.wishId
    }
    subWish.subWishItemList.forEach(it => delete it['price'])
    let subWishInJson = JSON.stringify(subWish)
    const requestOptions = {
      method: method,
      headers: { 'Content-Type': 'application/json' },
      body: subWishInJson,
    }

    fetchSecure(url, requestOptions)
      .then(async response => {
        const data = await response.json()
        if (!response.ok) {
          setErrors(data.fields == null ? {} : data.fields)
          const errorMessage = (data && data.message) || response.status
          return Promise.reject(errorMessage)
        } else {
          setErrors({})
          props.match.params.objectId = data.id
          setSavedStatus({ saved: true, error: false })
        }
        setOpen(true)
      })
      .catch(errorMessage => {
        setSavedStatus({ saved: false, error: true, errorMessage: errorMessage })
      })
  }

  useEffect(() => {
    const fetchData = async () => {
      if (Object.keys(errors).length === 0) {
        if (props.match.params.objectId != null) {
          const response = await fetchSecure(window.REACT_APP_BASE_API_URL + '/subWish/' + props.match.params.objectId)
          const data = await response.json()
          let totalCounter = 0
          data.subWishItemList.forEach(it => {
            const subtotal = (it.unitPrice === undefined ? 0 : it.unitPrice) * (it.quantity === undefined ? 0 : it.quantity)
            it.subtotal = subtotal.toFixed(2)
            if (!it.codePerCustomer) {
              const wishCustomerId = wish?.customer?.id
              it.codePerCustomer = it?.product?.codePerCustomerList?.filter(
                codePerCustomerItem => codePerCustomerItem.customer.id === wishCustomerId
              )?.[0]
            }
            it.productDescription = it?.product?.descriptionSP ?? it?.codePerCustomer?.product?.descriptionSP
            totalCounter = totalCounter + subtotal
          })
          setValueItem(totalCounter)
          setSubWish(data)
        } else {
          setSubWish({})
        }
        const responseWish = await fetchSecure(window.REACT_APP_BASE_API_URL + '/wish/' + props.match.params.wishId)
        const dataWish = await responseWish.json()
        setWish(dataWish)
      }
    }

    fetchData().then(_ => setLoaded(true))
  }, [errors, loaded, props.match.params.objectId, props.match.params.wishId, savedStatus])

  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
    },
    container: {},
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }))

  const fieldsToRenderPayment = {
    elements: [
      { type: 'textField', typeTextField: 'number', id: 'amount', label: 'Monto', disabled: show },
      { type: 'dateField', id: 'date', label: 'Fecha', disabled: show },
      {
        type: 'select',
        typeTextField: 'select',
        id: 'currency',
        label: 'Moneda',
        optionValue: 'id',
        optionShow: 'name',
        url: window.REACT_APP_BASE_API_URL + '/currency',
        disabled: show,
      },
    ],
  }

  const productsUrl =
    wish?.customer?.id && subWish?.supplier?.id
      ? `${window.REACT_APP_BASE_API_URL}/codePerCustomer/${wish.customer.id}/${subWish.supplier.id}`
      : undefined

  const fieldsToRender = {
    elements: [
      {
        type: 'textField',
        typeTextField: 'number',
        id: 'quantity',
        label: 'Cantidad',
        disabled: show,
      },
      {
        type: 'textField',
        typeTextField: 'number',
        id: 'unitPrice',
        fieldLabel: 'Precio Unitario',
        disabled: show,
      },
      {
        type: 'select',
        id: 'codePerCustomer',
        fieldLabel: 'Producto',
        optionValue: 'id',
        optionShow: 'code',
        url: productsUrl,
        disabled: show,
      },
      {
        type: 'textField',
        typeTextField: 'number',
        id: 'subtotal',
        fieldLabel: 'Fob total unitario',
        disabled: true,
      },
      {
        type: 'textField',
        typeTextField: 'text',
        id: 'productDescription',
        fieldLabel: 'Descripción',
        disabled: true,
      },
    ],
  }

  const listSubWishTo = {
    pathname: '/wish/' + props.match.params.wishId + '/subWish',
  }

  const classes = useStyles()

  if (!loaded) {
    return (
      <Box style={{ display: 'flex', height: '20vh', flexDirection: 'column' }}>
        <LoadingScreen />
      </Box>
    )
  }

  return (
    <Paper className={classes.root}>
      <Box m={2}>
        <FormGroup row={true}>
          <Link to={listSubWishTo}>
            <Button variant="contained" color="primary">
              {t('listSubOrder')}
            </Button>
          </Link>
        </FormGroup>
      </Box>

      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography variant="h6">{t('orderInformation')}</Typography>
          <FormGroup row={true}>
            <InputField name="opNumber" value={wish?.opNumber} type="number" />
            <InputField name="customer" value={wish?.customer?.name} type="text" />
            <InputField name="supplierOrder" value={wish?.supplierOrder} type="text" />
            <InputField style={{ backgroundColor: '#F7FADC' }} disabled={true} name="valueItem" value={valueItem?.toFixed(2)} type="number" />
          </FormGroup>
        </CardContent>
      </Card>

      <Box m={2}>
        {savedStatus.error && (
          <Snackbar open={open} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              Ha ocurrido un error inesperado al intentar grabar el sub pedido
              {Object.keys(errors).map(it => (
                <Fragment>
                  <li>{errors[it]}</li>
                </Fragment>
              ))}
            </Alert>
          </Snackbar>
        )}

        {savedStatus.saved && (
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              El sub pedido se ha grabado con éxito
            </Alert>
          </Snackbar>
        )}

        {props.match.params.objectId != null && (
          <Box m={2}>
            <Grid container spacing={3}>
              <Grid item xs={9}></Grid>
              <Grid item xs={2}>
                {t('button.show')} <Switch defaultChecked={show} color="primary" onClick={handleShow} />
              </Grid>
            </Grid>
          </Box>
        )}
        <form className={classes.container} noValidate>
          <Card className={classes.root}>
            <CardContent>
              <Typography variant="h6">{t('dataGeneral')}</Typography>
              <FormGroup row={true}>
                <SelectField
                  disabled={show}
                  error={!!errors.supplier}
                  name="supplier"
                  label="supplier"
                  value={subWish.supplier}
                  changeHandler={handleListChange}
                  className={classes.textField}
                  url={window.REACT_APP_BASE_API_URL + '/supplier'}
                  optionValue="id"
                  optionShow="name"
                />
                <SelectField
                  disabled={show}
                  error={!!errors.wishStatus}
                  name="wishStatus"
                  label="wishStatus"
                  value={subWish.wishStatus}
                  changeHandler={handleListChange}
                  className={classes.textField}
                  url={window.REACT_APP_BASE_API_URL + '/wishStatus'}
                  optionValue="id"
                  optionShow="name"
                />
              </FormGroup>
            </CardContent>
          </Card>

          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {t('items')}
              </Typography>
              <FormGroup row={true}>
                <ListComponent
                  disabled={show}
                  errorsList={errors}
                  onChangeList={handleListChange}
                  elementToRender={fieldsToRender}
                  elementToUpload={subWish.subWishItemList}
                  listName={'subWishItemList'}
                  isShowTotal={true}
                />
              </FormGroup>
            </CardContent>
          </Card>

          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h6">{t('paymentsMade')}</Typography>
              <FormGroup row={true}>
                <ListComponent
                  disabled={show}
                  errorsList={errors}
                  onChangeList={handleListChange}
                  elementToRender={fieldsToRenderPayment}
                  elementToUpload={subWish.subWishPaymentList}
                  listName={'subWishPaymentList'}
                />
              </FormGroup>
            </CardContent>
          </Card>

          {show === false && (
            <Fab variant="extended" color="primary" onClick={handleSave} className={classes.fab}>
              {t('save')}
            </Fab>
          )}
        </form>
      </Box>
    </Paper>
  )
}

export default SubWishItem
