
import {ListPage} from "../../../components/templates/ListPage"

function PortTerminal() {
	const columns = [
		{ id: 'id', label: 'ID', minWidth: 100 },
		{ id: 'name', label: 'Nombre', minWidth: 100 },
	]


	return <ListPage createToPath={"/portTerminal/create"} columns={columns} fetchUrl={window.REACT_APP_BASE_API_URL + '/portTerminal'} editPath={"/portTerminal/edit/"} />
}

export default PortTerminal;
