import { Button, TableCell, TableRow } from "@material-ui/core"
import { fetchSecure } from "../../../modules/fetchSecure"
import React from "react"
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

export function ReportLinkRow ({linkName, linkUrl}) {
    return <TableRow hover role="checkbox" tabIndex={-1} key={'wishPayment'}>
    <TableCell/>
    <TableCell>{linkName}</TableCell>
    <TableCell/>
    <TableCell><Button onClick={async () => {
        fetchSecure(linkUrl).then(response => response.blob())
            .then(blob => {
                const file = window.URL.createObjectURL(blob)
                window.location.assign(file)
            })
    }
    }>
        <CloudDownloadIcon />
    </Button></TableCell>
    <TableCell/>
    <TableCell/>
</TableRow>
}