import {fetchSecureJson} from "../modules/fetchSecureJson";


export class PreOrderService {

    async getPreOrder(id) {
        return await fetchSecureJson(window.REACT_APP_BASE_API_URL + '/preOrder/' + id)

    }

    async getPreOrderItems(id) {
        const newPreOrderItemList = await fetchSecureJson(window.REACT_APP_BASE_API_URL + '/preOrder/' + id + '/preOrderItemList')
        const calculatedTotalGrossWeight = newPreOrderItemList.reduce((total, item) => {
            const productSupplier = item.product.productSupplierList.filter(ps => ps.supplier.id === item.supplierId)[0]
            return total + item.quantity * productSupplier?.grossWeightPerBox / productSupplier?.quantityArtByExternalBox
        }, 0)
        const calculatedTotalVolume = newPreOrderItemList.reduce((total, item) => {
            const productSupplier = item.product.productSupplierList.filter(ps => ps.supplier.id === item.supplierId)[0]
            return total + item.quantity * productSupplier?.height * productSupplier?.width * productSupplier?.length / 1000000000 / productSupplier?.quantityArtByExternalBox
        }, 0)

        const calculatedAmount = newPreOrderItemList.reduce((total, item) => {
            return total + item.quantity * item.price
        }, 0)

        return {
            items: newPreOrderItemList, calculatedTotalGrossWeight, calculatedTotalVolume, calculatedAmount
        }
    }
}
