import {Box, Button} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import SelectField from '../../components/selectField/SelectField.js'
import {makeStyles} from "@material-ui/core/styles"
import {fetchSecure} from "../../modules/fetchSecure"
import {Alert, Snackbar, Typography} from "@mui/material";
import {CostOptimizationService} from "../../services/CostOptimizationService";
import {formatNumber} from "../../lib/formatNumber";
import {Link} from "react-router-dom";
import {PreOrderService} from "../../services/PreOrderService";
import DeleteIcon from "@material-ui/icons/Delete";

const preOrderService = new PreOrderService()
const costOptimizationService = new CostOptimizationService()

export const PreOrderCost = (props) => {
    const objectId = props.match.params.objectId

    const [preOrder, setPreOrder] = useState({})
    const [loadingResult, setLoadingResult] = useState(false)
    const [preOrderItemList, setPreOrderItemList] = useState([])

    const [optionsSelected, setOptionsSelected] = useState([])

    const [forwarderList, setForwarderList] = useState([])
    const [portList, setPortList] = useState([])

    const [optimizationResult, setOptimizationResult] = useState(null)

    const [totalVolume, setTotalVolume] = useState(0)
    const [totalGrossWeight, setTotalGrossWeight] = useState(0)

    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')


    const optimize = async () => {
        setLoadingResult(true)
        setOptimizationResult(null)
        const newOptimizationResult = []

        try {
        for (const optionSelected of optionsSelected) {
            const forwarder = forwarderList.find(forwarder => forwarder.id === optionSelected.forwarder.id)
            const port = portList.find(port => port.id === optionSelected.port.id)

            const optimizationResult = await costOptimizationService.optimize(preOrderItemList, forwarder, port)

            newOptimizationResult.push({
                forwarder,
                port,
                'result': optimizationResult.result,
                'containers': optimizationResult.containers
            })
        }
        const sortedOptimizationResult = newOptimizationResult.sort((resultItem1, resultItem2) => resultItem1.result.cost - resultItem2.result.cost)
        setLoadingResult(false)
        setOptimizationResult(sortedOptimizationResult)
        } catch (e) {
            setErrorMessage("Ha ocurrido un error al optimizar")
            setShowErrorMessage(true)
        }
    }

    const bestOption = optimizationResult?.[0]


    useEffect(() => {
        preOrderService.getPreOrder(objectId).then(data => {
            setPreOrder(data)
        })
        preOrderService.getPreOrderItems(objectId).then(data => {
            setTotalGrossWeight(data.calculatedTotalGrossWeight)
            setTotalVolume(data.calculatedTotalVolume)
            setPreOrderItemList(data.items)
        })
    }, [objectId])

    useEffect(() => {

        fetchSecure(window.REACT_APP_BASE_API_URL + '/forwarder')
            .then(response => response.json())
            .then(data => setForwarderList(data.filter(forwarder => forwarder.containers?.length === 4)))
        fetchSecure(window.REACT_APP_BASE_API_URL + '/portTerminal')
            .then(response => response.json())
            .then(data => setPortList(data.filter(port => port.containers?.length === 4)))

    }, [])


    const onChange = (position) => (name, value) => {
        let newOptionsSelected = [...optionsSelected]
        newOptionsSelected[position][name] = value
        setOptionsSelected(newOptionsSelected)
    }


    return <Box display={'row'} flexDirection={'row'}>
        <Box display={'flex'} flexDirection={'column'}>
            {optionsSelected.map((option, index) => {
                return <ForwarderAndPortSelection
                    key={index}
                    onChange={onChange(index)}
                    forwarder={option.forwarder}
                    port={option.port}
                    forwarderList={forwarderList}
                    portList={portList}
                    deleteFunction={() => {
                        setOptionsSelected(optionsSelected.filter((option, optionIndex) => optionIndex !== index))
                    }}
                />
            })
            }
            <Box display={'flex'} mt={1} ml={1}>
                <Button variant="contained" color="primary" onClick={() => {
                    setOptionsSelected([...optionsSelected, {forwarder: null, port: null}])
                }
                }>Agregar</Button>
                <Box ml={1}><Button disabled={optionsSelected.length === 0} variant="contained" color="secondary"
                                    onClick={optimize}>Optimizar</Button></Box>

            </Box>
        </Box>
        <TotalWeightAndVolume totalGrossWeight={totalGrossWeight} totalVolume={totalVolume}/>
        {loadingResult && <Box ml={2} mt={2}>Calculando...</Box>}
        {optimizationResult &&
        <>
            <Box display={'flex'} flexDirection={'column'} ml={2} mt={1}>

                <OptimizationResult result={optimizationResult}/>
            </Box>
            <Box display={'flex'} ml={2} mt={1}>
                <Button component={Link}
                        variant={'contained'}
                        to={`/preOrder/costInput/${preOrder.id}/${bestOption.forwarder.id}/${bestOption.port.id}`}
                        color="primary">Asignación{' '}
                    {bestOption.forwarder.name} - {bestOption.port.name}</Button>

            </Box>
        </>
        }
            <Snackbar open={showErrorMessage} onClose={() => setShowErrorMessage(false) }>
                <Alert onClose={() => setShowErrorMessage(false)} variant="filled" severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
    </Box>


}

const TotalWeightAndVolume = ({totalGrossWeight, totalVolume}) => {
    return <Box display={'flex'} flexDirection={'row'} mt={2} ml={2}>
        <Box display={'flex'} flexDirection={'column'}>
            <Typography sx={{fontWeight: 900}}>Total peso bruto</Typography>
            <Typography sx={{fontWeight: 900}}>Total volumen</Typography>
        </Box>
        <Box display={'flex'} flexDirection={'column'} ml={4}>
            <Typography sx={{fontWeight: 900}}>{formatNumber(totalGrossWeight, 2)}</Typography>
            <Typography sx={{fontWeight: 900}}>{formatNumber(totalVolume, 2)}</Typography>
        </Box>
    </Box>
}

const OptimizationResult = ({result}) => {

    return <Box>
        <Typography variant={'h5'}>Costos</Typography>
        {result.map((resultItem) => {
            return <Box display={'flex'} flexDirection={'row'} mt={2}
                        key={resultItem.forwarder?.id + '-' + resultItem.port?.id}>
                <Box display={'flex'} flexDirection={'column'}>
                    <Typography>Forwarder</Typography>
                    <Typography>Puerto</Typography>
                    <Typography>Costo</Typography>
                    {Object.keys(resultItem.containers).map((containerName) => {
                        const container = resultItem.containers[containerName]
                        const quantityOfContainer = resultItem.result.variables[containerName]
                        if (quantityOfContainer === 0) {
                            return null
                        }
                        return <>
                            <Typography key={container.id}>Cantidad {containerName}</Typography>
                            <Typography key={container.id}>Volumen disponible {containerName}</Typography>
                            <Typography key={container.id}>Peso disponible {containerName}</Typography>
                        </>
                    })}
                </Box>
                <Box display={'flex'} flexDirection={'column'} ml={4}>
                    <Typography>{resultItem.forwarder.name}</Typography>
                    <Typography>{resultItem.port.name}</Typography>
                    <Typography>{resultItem.result.cost.toFixed(0)}</Typography>
                    {Object.keys(resultItem.containers).map((containerName) => {
                        const container = resultItem.containers[containerName]
                        const quantityOfContainer = resultItem.result.variables[containerName]
                        if (quantityOfContainer === 0) {
                            return null
                        }
                        const availableVolume = resultItem.result.slack[containerName + '_volume']
                        const availableWeight = resultItem.result.slack[containerName + '_weight']

                        return <React.Fragment key={container.id}>
                            <Typography>{quantityOfContainer}</Typography>
                            <Typography>{formatNumber(availableVolume.toFixed(0))}</Typography>
                            <Typography>{formatNumber(availableWeight.toFixed(0))}</Typography>
                        </React.Fragment>
                    })}
                </Box>
            </Box>
        })}
    </Box>
}

const ForwarderAndPortSelection = ({forwarder, port, forwarderList, portList, onChange, deleteFunction}) => {

    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {},
    });
    const classes = useStyles();

    return <Box display={'flex'} flexDirection={'row'} ml={1} mr={1} mt={2} alignItems={'center'}>
        <SelectField name="forwarder" label="forwarder"
                     value={forwarder} changeHandler={onChange} className={classes.textField}
                     data={forwarderList} optionValue="id" optionShow="name"/>
        <SelectField name="port" label="port"
                     value={port} changeHandler={onChange} className={classes.textField}
                     data={portList} optionValue="id" optionShow="name"/>
        <Button onClick={deleteFunction}> <DeleteIcon/></Button>

    </Box>


}
