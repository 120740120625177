import React, {Fragment} from 'react'
import EditIcon from '@mui/icons-material/Edit'

import {Link} from "react-router-dom";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableHead,
    TableRow,
    Button,
    makeStyles, Typography
} from '@material-ui/core'
import {useTranslation} from "react-i18next"
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';

function TableComponent({columns, filteredData, urlEdit, handleChangePage, handleChangeRowsPerPage, page, rowsPerPage, count, courierRecord = false, wish = false, usingPagination = false, handlerDeleteModal, shouldShowIconToDelete}) {

    const fieldByString = function (o, s) {
        s = s.replace(/\[(\w+)]/g, '.$1')
        s = s.replace(/^\./, '')
        const a = s.split('.')
        for (let i = 0, n = a.length; i < n; ++i) {
            let k = a[i]
            if (k in o) {
                o = o[k]
            } else {
                return
            }
        }
        return o
    }


    const useStyles = makeStyles({
        container: {
            maxHeight: 800,
        },
    });

    const [t] = useTranslation('common');


    const classes = useStyles();

    const data = usingPagination ? filteredData: filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    return <Fragment>
        <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                onClick={column.onHeaderClick?() => {column.onHeaderClick(data)}:() => {}}
                                key={column.id}
                                align={column.align}
                                style={{minWidth: column.minWidth, maxWidth: column.maxWidth, cursor: column.onHeaderClick?'pointer':'default'}}
                            >
                                {column.label??t(column.id.split(".")[0])}
                            </TableCell>
                        ))}
                        <TableCell>
                            {t('button.edit')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => {
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                {columns.map((column) => {
                                    try {
                                        const value = column.calculateFunction?column.calculateFunction(row):fieldByString(row, column.id)

                                        return (
                                            <TableCell style={{cursor: column.onClick?'pointer': null}} key={column.id} align={column.align} onClick={column.onClick? () => column.onClick(row): () => {}}>
                                                <Typography color={column.onClick?'secondary':'textPrimary'}>{column.type === 'boolean'? t(value): column.format ? column.format(value) : value} </Typography>
                                            </TableCell>
                                        );
                                    } catch (e) {
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                            </TableCell>
                                        )
                                    }

                                })}
                                {

                                    urlEdit !== undefined && wish === true && <TableCell>
                                        <Link to={urlEdit.pathname + row.id + '/'+ urlEdit.search}>
                                            <Button variant="outlined" color="primary"><EditIcon /></Button>
                                        </Link>
                                    </TableCell>
                                }
                                {

                                    urlEdit !== undefined && courierRecord === false && wish === false && <TableCell>
                                        <Link to={urlEdit + row.id}>
                                            <Button variant="outlined" color="primary"><EditIcon /></Button>
                                        </Link>
                                    </TableCell>

                                }
                                {

                                    urlEdit !== undefined && wish === false && courierRecord === true && fieldByString(row, 'type') === 'DOCUMENT_COURIER' ?
                                    <TableCell>
                                        <Link to={'/documentsCourierRecord/edit/'+ row.id}>
                                            <Button variant="outlined" color="primary"><EditIcon /></Button>
                                        </Link>
                                    </TableCell> : urlEdit !== undefined && courierRecord === true && wish === false ?
                                        <TableCell>
                                            <Link to={'/specialCourierRecord/edit/'+ row.id}>
                                                <Button variant="outlined" color="primary"><EditIcon /></Button>
                                            </Link>
                                        </TableCell> : <></>

                                }
                                { shouldShowIconToDelete && shouldShowIconToDelete(row) && <TableCell>
                                        <Button variant="outlined" color="primary" onClick={() => handlerDeleteModal(row.id)}><DeleteOutlineRoundedIcon /></Button>
                                </TableCell>
                                }
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </Fragment>

}

export default TableComponent;
